import {
    GET_HAZARD_VALUES,
    GET_SINGLE_USER_HAZARD,
    GET_USER_HAZARD,
    ADD_HAZARD,
    UPDATE_HAZARD,
    GENERATE_HAZARD,
    UPDATE_HAZARD_ACTION,
    DELETE_HAZARD
} from "../../types";

const initialState = {
    list: [],
    single: null,
    userHazard: {},
    loading: true,
    error: null
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_HAZARD_VALUES:
            return { ...state, list: payload };
        case GET_USER_HAZARD:
            return {
                ...state,
                userHazard: payload.data || state.userHazard,
                loading: payload.loading,
                error: payload.error
            };
        case GET_SINGLE_USER_HAZARD:
            return { ...state, single: payload };
        case ADD_HAZARD:
            return { ...state, userHazard: [...state.userHazard, payload] };
        case UPDATE_HAZARD:
            return {
                ...state,
                userHazard: state.userHazard.map(hazard =>
                    hazard.id === payload.id ? payload : hazard
                )
            };
        case GENERATE_HAZARD:
            return {
                ...state,
                userHazard: state.userHazard.map(hazard =>
                    hazard.id === payload.id ? payload : hazard
                )
            };
        case UPDATE_HAZARD_ACTION:
            return {
                ...state,
                userHazard: state.userHazard.map(hazard =>
                    hazard.id === payload.hazardId
                        ? {
                            ...hazard,
                            actions: hazard.actions.map(action =>
                                action.id === payload.updatedAction.id
                                    ? payload.updatedAction
                                    : action
                            )
                        }
                        : hazard
                )
            };
        default:
            return state;
    }
};

export default reducer;