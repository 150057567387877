import {
  GET_USER_PERMIT_WORK,
  GET_PERMIT_WORK_VALUES,
  ADD_PERMIT_WORK,
  UPDATE_PERMIT_WORK,
  GET_PERMIT_WORK,
  GENERATE_PERMIT_TO_WORK,
  SUBMIT_REVIEW_COMMENT,
  SUBMIT_APPROVE_REJECT,
  DELETE_PERMIT_TO_WORK,
  GET_ISSUER_PERMITS,
  GET_REVIEWER_PERMITS, GET_PERMIT_TO_WORK_COUNT
} from "../../types";

const initialState = { list: [], single: null, userPermitWork: {}, values: [], issuerPermits: [], reviewerPermits: [],permitCounts:[] };

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GENERATE_PERMIT_TO_WORK:
      return {
        ...state,
        userPermitWork:payload,
      };
    case SUBMIT_REVIEW_COMMENT:
      return {
        ...state,
        userPermitWork: state.userPermitWork.map((data) =>
          data.id === payload.id ? payload : data,
        ),
      };
    case SUBMIT_APPROVE_REJECT:
      return {
        ...state,
        userPermitWork: state.userPermitWork.map((data) =>
          data.id === payload.id ? payload : data,
        ),
      };
    case GET_PERMIT_WORK_VALUES:
      return { ...state, values: payload };
    case GET_PERMIT_WORK:
      return { ...state, single: payload };
    case GET_USER_PERMIT_WORK:
      return { ...state, userPermitWork: payload };
    case ADD_PERMIT_WORK:
      return { ...state, userPermitWork: payload };
    case UPDATE_PERMIT_WORK:
      return {
        ...state,
        userPermitWork: payload,
      };
    case DELETE_PERMIT_TO_WORK:
      return {
        ...state,
        userPermitWork: state.userPermitWork.user.filter(object => object.id !== payload)
      };
    case GET_ISSUER_PERMITS:
      return { ...state, issuerPermits: payload };
    case GET_REVIEWER_PERMITS:
      return { ...state, reviewerPermits: payload };

    case GET_PERMIT_TO_WORK_COUNT:
      return { ...state, permitCounts: payload };



    default:
      return state;
  }
};

export default reducer;
