import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
  Card,
  CardBody,
  Row,
  Col, Alert
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from 'classnames';

// i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import withRouter from "../withRouter";
import {
  getUserLinks,
  getUserModules,
  getUserProfile,
  giveUserFeedback,
  uploadProfilePicture,
  updateUserPassword
} from "../../../store/actions/user";
import QuickLinks from "../../../Pages/QuickLinks";
import QuickRoutes from "../../../Pages/QuickRoutes";
import { User, Mail, Briefcase, Calendar, Shield, CheckCircle, Pencil, MessageSquare,Lock} from 'lucide-react';
import GlobalQuickRoutes from '../../../Pages/System-admin/GlobalQuickRoutes/index';
import { getNavigation } from "../../../store/actions/navigation";
import {showSuccessDialog} from "../../../utils/dialogs";


const ChangePasswordModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const resetForm = () => {
    setNewPassword('');
    setConfirmPassword('');
    setError('');
  };

  const handleCancel = () => {
    resetForm();
    toggle();
  };

  const validatePasswords = () => {
    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long');
      return false;
    }
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (validatePasswords()) {
      try {
        // Here you would dispatch your change password action
        const object={
          password: newPassword,
        }
        const passwordResult=await dispatch(updateUserPassword(object));

        if(passwordResult.success){
          showSuccessDialog("Password Updated Successfully", "success");
        }

        resetForm();
        toggle();
      } catch (err) {
        setError('Failed to change password. Please try again.');
      }
    }
  };

  return (
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          Change Password
        </ModalHeader>

        <Form onSubmit={handleSubmit}>
          <ModalBody>
            {error && (
                <Alert color="danger" className="mb-3">
                  {error}
                </Alert>
            )}

            <FormGroup>
              <Label for="newPassword">
                New Password
              </Label>
              <Input
                  id="newPassword"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter new password"
                  required
                  minLength={8}
              />
            </FormGroup>

            <FormGroup>
              <Label for="confirmPassword">
                Confirm Password
              </Label>
              <Input
                  id="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm new password"
                  required
                  minLength={8}
              />
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            <Button color="light" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Change Password
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
  );
};

const ProfileModalContent = ({ userProfile }) => {
  if(userProfile === null || userProfile === undefined) {
    window.location.reload();
  }

  const dispatch = useDispatch();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 1024 * 1024 && file.type.startsWith('image/')) {
      const formData = new FormData();
      formData.append('profileImage', file);
      try {
        const result = await dispatch(uploadProfilePicture(formData));
        if(result.success) {
          dispatch(getUserProfile());
        }
      } catch (error) {
        alert('Failed to upload image. Please try again.');
      }
    } else {
      alert('Please select an image file less than 1 MB.');
    }
  };

  return (
      <Card>
        <CardBody>
          <div className="text-center mb-4">
            <div className="d-flex flex-column align-items-center">
              <div className="avatar-xl rounded-circle bg-light d-flex justify-content-center align-items-center mb-3 position-relative">
                {userProfile.fullPath ? (
                    <img
                        src={userProfile.fullPath}
                        alt="Profile"
                        className="rounded-circle"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                ) : (
                    <User className="text-primary" size={40} />
                )}
                <label htmlFor="profileImageInput" className="position-absolute bottom-0 end-0 bg-white rounded-circle p-1 cursor-pointer">
                  <Pencil size={16} className="text-primary" />
                </label>
                <input
                    id="profileImageInput"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="d-none"
                />
              </div>
              <div>
                <h5 className="font-size-16 mb-1">{userProfile.name}</h5>
                <p className="text-muted mb-2">{userProfile.userType}</p>
                <p className="text-muted mb-0">{userProfile.organization}</p>
              </div>
            </div>
          </div>

          <h5 className="font-size-15 mb-3">Profile Details</h5>

          <Row>
            <Col md={6}>
              <div className="mb-3">
                <div className="d-flex align-items-center mb-2">
                  <User className="text-primary me-2" size={18} />
                  <strong>Full Name</strong>
                </div>
                <p className="text-muted">{`${userProfile.name}`}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <div className="d-flex align-items-center mb-2">
                  <Mail className="text-primary me-2" size={18} />
                  <strong>Email</strong>
                </div>
                <p className="text-muted">{userProfile.email}</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="mb-3">
                <div className="d-flex align-items-center mb-2">
                  <Briefcase className="text-primary me-2" size={18} />
                  <strong>Organization</strong>
                </div>
                <p className="text-muted">{userProfile.organization}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <div className="d-flex align-items-center mb-2">
                  <Shield className="text-primary me-2" size={18} />
                  <strong>User Type</strong>
                </div>
                <p className="text-muted">{userProfile.userType}</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="mb-3">
                <div className="d-flex align-items-center mb-2">
                  <Calendar className="text-primary me-2" size={18} />
                  <strong>Joined At</strong>
                </div>
                <p className="text-muted">{new Date(userProfile.createdAt).toLocaleDateString()}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <div className="d-flex align-items-center mb-2">
                  <CheckCircle className="text-primary me-2" size={18} />
                  <strong>Verification Status</strong>
                </div>
                <p className="text-muted">{userProfile.isVerified ? 'Verified' : 'Not Verified'}</p>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
  );
};

const FeedbackModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle feedback submission here
    console.log('Feedback submitted:', {title, description});

    const feedbackResponse = await dispatch(giveUserFeedback({title, description}));
    console.log("FEEDBACK RESPONSE")
    console.log(feedbackResponse)

    if(feedbackResponse.success) {
      showSuccessDialog("Successfully sent feedback","Success");
      setTitle('');
      setDescription('');
      toggle();
    }
  };

  return (
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          Provide Feedback
        </ModalHeader>
        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="feedbackTitle">
                Title
              </Label>
              <Input
                  id="feedbackTitle"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter a title for your feedback..."
                  required
              />
            </FormGroup>
            <FormGroup>
              <Label for="feedbackDescription">
                Description
              </Label>
              <Input
                  id="feedbackDescription"
                  type="textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Please share your feedback here..."
                  rows="5"
                  required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={toggle}>
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Submit Feedback
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
  );
};

const ProfileMenu = props => {
  const dispatch = useDispatch();
  const userLinks = useSelector((state) => state.users.userLinks)
  const userModules = useSelector((state) => state.users.userModules)
  const userProfile = useSelector((state) => state.users.userProfile)
  const [profileName, setProfileName] = useState("User")

  const navigation = useSelector((state) => state.navigation.list);

  const [menu, setMenu] = useState(false);
  const [username, setUsername] = useState("Admin");
  const [quickLinksModal, setQuickLinksModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [hasQuickModule, setQuickModule] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal);
  };

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setUsername(obj.displayName);
      } else if (
          process.env.REACT_APP_DEFAULTAUTH === "fake" ||
          process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setUsername(obj.username);
      }
    }
  }, [props.success]);

  const toggleQuickLinksModal = () => setQuickLinksModal(!quickLinksModal);
  const toggleProfileModal = () => setProfileModal(!profileModal);
  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(getUserLinks())
    dispatch(getUserModules())
    dispatch(getUserProfile())
    dispatch(getNavigation())
  }, [dispatch]);

  useEffect(() => {
    if (userProfile != null) {
      setProfileName(userProfile.name)
    }
  }, [userProfile]);

  const hasPermission = (data) => data.some(item => item.module_route === "quick/module");

  return (
      <React.Fragment>
        <Dropdown
            isOpen={menu}
            toggle={() => setMenu(!menu)}
            className="d-inline-block"
        >
          <DropdownToggle
              className="btn header-item"
              id="page-header-user-dropdown"
              tag="button"
          >
            <i className="ri-user-line header-profile-user" />
            <span className="d-none d-xl-inline-block ms-2 me-2">{profileName ?? "Refresh once"}</span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={toggleProfileModal}>
              <i className="ri-user-line align-middle me-2"/>
              {props.t("Profile")}
            </DropdownItem>
            <DropdownItem onClick={toggleModal}>
              <Lock className="align-middle me-2" size={14}/>
              <span>{props.t("Change Password")}</span>
            </DropdownItem>
            <div className="dropdown-divider"/>
            <DropdownItem onClick={toggleQuickLinksModal}>
              <i className="ri-link align-middle me-2"/>
              Quick Links
            </DropdownItem>
            <div className="dropdown-divider"/>
            <DropdownItem onClick={toggleFeedbackModal}>
              <MessageSquare className="align-middle me-2" size={14}/>
              <span>{props.t("Feedback")}</span>
            </DropdownItem>
            <div className="dropdown-divider"/>
            <Link to="/logout" className="dropdown-item">
              <i className="ri-shut-down-line align-middle me-2 text-danger"/>
              <span>{props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>

        <Modal isOpen={quickLinksModal} toggle={toggleQuickLinksModal} size="xl">
          <ModalHeader toggle={toggleQuickLinksModal}></ModalHeader>
          <ModalBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggleTab('1'); }}
                >
                  My Forms
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggleTab('2'); }}
                >
                  External Links
                </NavLink>
              </NavItem>
              {hasPermission(navigation ?? []) && (
                  <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { toggleTab('3'); }}
                    >
                      Global Commonly Used Forms
                    </NavLink>
                  </NavItem>
              )}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <QuickRoutes />
              </TabPane>
              <TabPane tabId="2">
                <QuickLinks />
              </TabPane>
              <TabPane tabId="3">
                <GlobalQuickRoutes />
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal>

        <Modal isOpen={profileModal} toggle={toggleProfileModal} size="lg">
          <ModalHeader toggle={toggleProfileModal}>Profile</ModalHeader>
          <ModalBody>
            <ProfileModalContent userProfile={userProfile} />
          </ModalBody>
        </Modal>

        <ChangePasswordModal isOpen={isModalOpen} toggle={toggleModal} />

        <FeedbackModal
            isOpen={showFeedbackModal}
            toggle={toggleFeedbackModal}
        />
      </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);