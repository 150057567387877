import axios from "../../axios";
import {
    ADD_NEWS,
    DELETE_NEWS,
    GET_ALL_NEWS,
    GET_LATEST_NEWS, GET_NEWS_VALUES,
    GET_SINGLE_NEWS,
    GET_USER_NEWS,
    UPDATE_NEWS
} from "../types";
import {message} from "antd";
import {setAlert} from "./alert";

export const publishNews=(id)=>async (dispatch) => {
    try {
        const res = await axios.get(`/news/publish/${id}`);
        if (res.data.code === 1) {
            message.success("BULLETIN PUBLISHED")
            return {success: true, data: res.data.data};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getAllNews = () => async (dispatch) => {
    try {
        const res = await axios.get("/news/all");
        
        if (res.data.code === 1) {
            dispatch({type: GET_ALL_NEWS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};


export const getNewsValues = () => async (dispatch) => {
    try {
        const res = await axios.get("/news/values");

        if (res.data.code === 1) {
            dispatch({type: GET_NEWS_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getLatestNews = () => async (dispatch) => {
    try {
        const res = await axios.get("/news/latest");
        if (res.data.code === 1) {
            dispatch({type: GET_LATEST_NEWS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getUserNews = () => async (dispatch) => {
    try {
        const res = await axios.get("/news/all");
        
        if (res.data.code === 1) {
            dispatch({type: GET_USER_NEWS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const addNews = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/news/add", formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: ADD_NEWS, payload: res.data.data});
            message.success("BULLETIN ADDED")
            // message.success(res.data.message);
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getSingleNews = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/news/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_SINGLE_NEWS, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};

export const updateNews = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/news/update`, formData);
        ;
        if (res.data.code === 1) {
            dispatch({type: UPDATE_NEWS, payload: res.data.data});
            message.success("BULLETIN UPDATED")
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteNews = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/news/delete/${id}`);
        if (res.data.code === 1) {
            message.success("BULLETIN DELETED")
            dispatch({type: DELETE_NEWS, payload: res.data.data});
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
