import React from 'react';
import { Container, Row, Col } from 'reactstrap'; // Keep for layout
import { Settings, Clock } from 'lucide-react';

// Assume logodark points to the M-SPHERE logo image
import logodark from "../assets/images/logo-dark.png";

// --- Design Tokens (Adjusted for Target Image) ---
const designTokens = {
    colors: {
        primary: '#ffffff', // White
        accent: '#0d6efd', // Bootstrap Blue (Matches image)
        accentDark: '#0a58ca', // Darker blue (unused here but good practice)
        text: '#212529', // Standard Dark Grey/Black
        textSecondary: '#6c757d', // Standard Medium Grey
        background: '#f8f9fa',
        surface: '#ffffff',
        infoBoxBackground: '#f8f9fa',
        border: '#dee2e6',
        divider: '#e9ecef',
    },
    shadows: {
        // Subtle shadow like the image
        md: '0 4px 15px rgba(0, 0, 0, 0.07)',
    },
    spacing: { // Use consistent spacing units
        xs: '4px', sm: '8px', md: '16px', lg: '24px', xl: '32px', xxl: '48px',
    },
    borderRadius: {
        // Noticeable rounded corners like the image
        lg: '16px', // For the main card
        md: '12px', // For the inner info box
    },
    typography: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        fontWeightNormal: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700, // For the main title
    }
};
// --- End Design Tokens ---


const MaintenancePage = () => {

    return (
        <div className="maintenance-page">
            <style>
                {`
          /* --- CSS Variables from Design Tokens --- */
          :root {
            --color-primary: ${designTokens.colors.primary};
            --color-accent: ${designTokens.colors.accent};
            --color-text: ${designTokens.colors.text};
            --color-text-secondary: ${designTokens.colors.textSecondary};
            --color-background: ${designTokens.colors.background};
            --color-surface: ${designTokens.colors.surface};
            --color-info-box-bg: ${designTokens.colors.infoBoxBackground};
            --color-divider: ${designTokens.colors.divider};

            --shadow-md: ${designTokens.shadows.md};

            --spacing-xs: ${designTokens.spacing.xs};
            --spacing-sm: ${designTokens.spacing.sm};
            --spacing-md: ${designTokens.spacing.md};
            --spacing-lg: ${designTokens.spacing.lg};
            --spacing-xl: ${designTokens.spacing.xl};
            --spacing-xxl: ${designTokens.spacing.xxl};

            --radius-lg: ${designTokens.borderRadius.lg};
            --radius-md: ${designTokens.borderRadius.md};

            --font-family-base: ${designTokens.typography.fontFamily};
            --font-weight-normal: ${designTokens.typography.fontWeightNormal};
            --font-weight-medium: ${designTokens.typography.fontWeightMedium};
            --font-weight-bold: ${designTokens.typography.fontWeightBold};
          }

          /* --- Base Page Styles --- */
          .maintenance-page {
            font-family: var(--font-family-base);
            background-color: var(--color-background); /* Simple light grey */
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            color: var(--color-text);
          }

          .maintenance-content {
            flex: 1 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: var(--spacing-xl); /* Generous padding */
          }

          /* --- Main Card --- */
          .maintenance-card {
            background-color: var(--color-surface); /* Solid white */
            border-radius: var(--radius-lg); /* Rounded corners */
            box-shadow: var(--shadow-md); /* Subtle shadow */
            width: 100%;
            max-width: 550px; /* Adjust width to match image proportion */
            overflow: hidden;
            display: flex;
            flex-direction: column;
            border: none; /* No border */
          }

          /* --- Logo Section --- */
          .logo-container {
            background-color: var(--color-surface); /* Solid white */
            padding: var(--spacing-lg) var(--spacing-xl); /* Adjust padding */
            text-align: center;
            /* No border needed between logo and blue header */
          }
          .logo-container img {
             height: 28px; /* Adjust based on M-SPHERE logo aspect ratio */
             width: auto;
          }

          /* --- Header Section (Blue Part) --- */
          .header-section {
            background-color: var(--color-accent); /* Solid Blue */
            padding: var(--spacing-xl); /* Generous padding */
            color: var(--color-primary); /* White text */
            text-align: center;
          }
          .header-icon { /* Static icon */
             width: 32px; /* Adjust size */
             height: 32px;
             color: var(--color-primary);
             margin-bottom: var(--spacing-md); /* Space below icon */
          }
          .header-section h1 {
            font-size: 1.5rem; /* Adjust size */
            font-weight: var(--font-weight-bold); /* Bold title */
            margin-top: 0;
            margin-bottom: var(--spacing-sm); /* Space below title */
            color: var(--color-primary);
          }
          .header-section .lead {
            font-size: 0.95rem; /* Adjust size */
            font-weight: var(--font-weight-normal);
            opacity: 0.9; /* Slightly less than full white */
            margin-bottom: 0;
            color: var(--color-primary);
            line-height: 1.5;
          }

          /* --- Card Body --- */
          .maintenance-card-body {
            background-color: var(--color-surface); /* Solid white */
            padding: var(--spacing-xl); /* Match header padding */
            flex-grow: 1;
            text-align: center; /* Center align content within body */
          }

          /* --- Info Box Styles --- */
          .info-card {
            background-color: var(--color-info-box-bg); /* Very light grey */
            border-radius: var(--radius-md); /* Slightly less rounded than card */
            padding: var(--spacing-lg);
            display: inline-flex; /* Allow centering within parent */
            flex-direction: column; /* Stack icon/text vertically */
            align-items: center; /* Center items horizontally */
            text-align: center;
            border: none;
            margin-bottom: var(--spacing-lg); /* Space below info box */
            max-width: 100%; /* Ensure it doesn't overflow */
          }
          .info-icon {
            width: 24px; /* Adjust size */
            height: 24px;
            color: var(--color-accent); /* Blue icon */
            margin-bottom: var(--spacing-md); /* Space below icon */
          }
          .info-card .info-title {
            font-size: 1rem; /* Adjust size */
            font-weight: var(--font-weight-medium); /* Medium weight */
            color: var(--color-text); /* Dark text */
            margin-bottom: var(--spacing-xs); /* Less space below title */
          }
          .info-card .info-text {
            color: var(--color-text-secondary); /* Medium grey text */
            margin-bottom: 0;
            font-size: 0.875rem; /* Smaller text */
            line-height: 1.4;
          }

          /* --- Final Message Text --- */
          .message-text {
             color: var(--color-text-secondary); /* Medium grey */
             font-size: 0.9rem; /* Adjust size */
             margin: 0; /* Remove default margins */
          }


          /* --- Responsive Adjustments --- */
           @media (max-width: 576px) {
             .maintenance-content { padding: var(--spacing-lg); }
             .maintenance-card { max-width: 95%; box-shadow: var(--shadow-md); } /* Allow slightly less shadow if needed */
             .header-section, .maintenance-card-body, .logo-container { padding: var(--spacing-lg); }
             .header-section h1 { font-size: 1.3rem; }
             .header-section .lead { font-size: 0.9rem; }
             .info-card { padding: var(--spacing-md); }
             .info-icon { width: 20px; height: 20px; }
             .info-card .info-title { font-size: 0.95rem;}
             .info-card .info-text { font-size: 0.8rem;}
           }
        `}
            </style>

            <div className="maintenance-content">
                <div className="maintenance-card">
                    {/* Logo Section */}
                    <div className="logo-container">
                        <img src={logodark} alt="M-Sphere Logo" />
                    </div>

                    {/* Header Section (Blue Part) */}
                    <div className="header-section">
                        {/* Static Icon */}
                        <Settings className="header-icon" />
                        <h1>Site Under Maintenance</h1>
                        <p className="lead">
                            We're making things better! We'll be back online shortly.
                        </p>
                    </div>

                    {/* Card Body */}
                    <div className="maintenance-card-body">
                        {/* Info Box */}
                        <div className="info-card">
                            <Clock className="info-icon" />
                            <h3 className="info-title">
                                Estimated Downtime
                            </h3>
                            <p className="info-text">
                                Maintenance is in progress. Expected duration: 1-2 hours.
                            </p>
                        </div>

                        {/* Final Message */}
                        <p className="message-text">
                            Thank you for your patience.
                        </p>
                    </div>
                </div> {/* End maintenance-card div */}
            </div>
        </div>
    );
};

export default MaintenancePage;