import axios from "../../../../axios";
import {
    ADD_COURSE,
    DELETE_COURSE,
    GET_COURSES,
    GET_COURSE,
    UPDATE_COURSE,
    GET_ASSIGNED_COURSES,
    ASSINGED_COURSE,
    GET_ENROLLED_COURSES,
    GET_TOPICS,
    COURSES_ENROLLED_BY_USER,
    MASTER_COURSE_USERS,
    GET_COURSES_VALUES, GET_COURSE_MASTER, GET_COURSE_ORGANIZATIONS
} from "../../../types";
import {setAlert} from "../../alert";
import {message} from "antd";

export const getCourses = () => async (dispatch) => {
    try {
        const res = await axios.get("/course/all");
        if (res.data.code === 1) {
            dispatch({type: GET_COURSES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getCoursesMaster = (object) => async (dispatch) => {
    try {
        const res = await axios.post("/course/user",object);
        if (res.data.code === 1) {
            dispatch({type: GET_COURSE_MASTER, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getCoursesValues = (object) => async (dispatch) => {
    try {
        const res = await axios.post("/course/values",object);
        if (res.data.code === 1) {
            dispatch({type: GET_COURSES_VALUES, payload: res.data.data});
            return {data: res.data.data};

        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const addCourse = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("/course/add", formData);
        if (res.data.code === 1) {
            dispatch({type: ADD_COURSE, payload: res.data.data});
            message.success(res.data.message);
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getCourse = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/course/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_COURSE, payload: res.data.data});
        }
    } catch (err) {
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const updateCourse = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/course/update`, formData);
        if (res.data.code === 1) {
            dispatch({type: UPDATE_COURSE, payload: res.data.data});
            message.success(res.data.message);
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        return {success: false, message: 'Contact to Admin'};
        // dispatch(setAlert(err.message, "danger"));
    }
};
export const deleteCourse = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/course/delete/${id}`);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: DELETE_COURSE, payload: res.data.data});
        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {success: false, message: 'Contact to Admin'};
    }
};
export const assignCourse = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/course/assign/add`,formData);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: ASSINGED_COURSE, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {success: false, message: 'Contact to Admin'};
    }
}
export const updateAssignCourse = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/course/assign/update`,formData);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: ASSINGED_COURSE, payload: res.data.data});
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {success: false, message: 'Contact to Admin'};
    }
}

export const deleteAssignCourse = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/course/assign/delete`,formData);
        if (res.data.code === 1) {
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {success: false, message: 'Contact to Admin'};
    }
}

export const generateCourse = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/course/assign/submit`,formData);
        if (res.data.code === 1) {
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {success: false, message: 'Contact to Admin'};
    }
}

// export const updateAssignCourse = (formData) => async (dispatch) => {
//     try {
//         const res = await axios.post(`/course/assign/update`,formData);
//         if (res.data.code === 1) {
//             dispatch({type: ASSINGED_COURSE, payload: res.data.data});
//         } else {
//             message.warning(res.data.message);
//         }
//     } catch (err) {
//         dispatch(setAlert(err.message, "danger"));
//         return {success: false, message: 'Contact to Admin'};
//     }
// }
export const getAllAssignedCourses=(object)=>async (dispatch) => {
    try {
        const res = await axios.post(`/course/assign/user`,object);
        if (res.data.code === 1) {
            dispatch({type: GET_ASSIGNED_COURSES, payload: res.data.data});

        } else {
            message.warning(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {success: false, message: 'Contact to Admin'};
    }

}
export const manageUserStatus=(object)=>async (dispatch) => {
    try {
        const res = await axios.post(`/course/assign/user/disable`,object);
        if (res.data.code === 1) {
            return {success: true, message: res.data.message};
        } else {
            message.warning(res.data.message);
            return {success: false, message: res.data.message};

        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {success: false, message: 'Contact to Admin'};
    }

}
export const getUserEnrolledCourses = ()=>async (dispatch) => {
    try {
        const res = await axios.get("/course/enrolled/user");
        if (res.data.code === 1) {
            console.log(res.data.data)
            dispatch({type: GET_ENROLLED_COURSES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const updateCourseHistoryTopic=(object)=>async (dispatch) => {
    try {

        const res = await axios.post(`/course/history/save`,object);
        console.log(res)
        if (res.data.code === 1) {
            dispatch({type: GET_TOPICS, payload: res.data.data});
            return {success: true, message: res.data.message};
        }
        else {
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {success: false, message: "Contact to Admin"};
    }
}
export const completeCourse=(object)=>async (dispatch) => {
    try {

        const res = await axios.post(`/course/history/complete`,object);
        
        if (res.data.code === 1) {
            dispatch({type: GET_TOPICS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getListofCoursesUserEnrolled=()=>async (dispatch) => {
    try {
        const res = await axios.get("/course/enrolled");
        if (res.data.code === 1) {
            dispatch({type: COURSES_ENROLLED_BY_USER, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getTableValuesofEnrolledUsers = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/course/enrolled/user/${id}`);
        if (res.data.code === 1) {
            dispatch({ type: MASTER_COURSE_USERS, payload: res.data.data });
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getCourseOrganizationValues=()=>async (dispatch) => {
    try {
        const res = await axios.get("/course/organization/values");
        if (res.data.code === 1) {
            dispatch({type: GET_COURSE_ORGANIZATIONS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}