import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import GlobalComponent from "../GlobalComponents";
import {
    FaHome, FaUserShield, FaExclamationTriangle, FaCog, FaArrowLeft,
    FaFileAlt, FaKey, FaUsers, FaClipboardCheck, FaLightbulb, FaComments,
    FaCogs, FaUserLock, FaEye, FaChalkboardTeacher, FaToolbox, FaCalendarAlt,
    FaList, FaGlobeAmericas, FaCity, FaBuilding, FaProjectDiagram, FaUserTie,
    FaMapMarkerAlt, FaGraduationCap, FaQuestionCircle, FaNewspaper, FaBookOpen,
    FaInfoCircle, FaUser, FaTachometerAlt, FaArchive, FaFileSignature, FaClipboard,
    FaExclamationCircle, FaChartBar, FaLeaf, FaListAlt, FaSitemap, FaGlobe,
    FaCheckSquare, FaUserCog
} from 'react-icons/fa';
import styled, { css } from "styled-components";
import { useNavigation } from "../../Navigation";
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';

// Design tokens from the modern UI design
const designTokens = {
    colors: {
        primary: '#ffffff',
        accent: '#0d6efd',
        accentDark: '#0b5ed7',
        accentLight: '#cfe2ff',
        text: '#212529',
        textSecondary: '#6c757d',
        background: '#f8f9fa',
        surface: '#ffffff',
        border: '#dee2e6',
        divider: '#e9ecef',
        success: '#198754',
        error: '#dc3545',
        warning: '#ffc107',
        info: '#0dcaf0',
    },
    shadows: {
        sm: '0 1px 3px rgba(0, 0, 0, 0.05)',
        md: '0 3px 8px rgba(0, 0, 0, 0.06)',
        lg: '0 5px 12px rgba(0, 0, 0, 0.07)',
        none: 'none',
    },
    transitions: {
        short: 'all 150ms ease-in-out',
        medium: 'all 250ms ease-in-out',
    },
    spacing: {
        xs: '0.25rem',
        sm: '0.5rem',
        md: '1rem',
        lg: '1.5rem',
        xl: '2rem',
        xxl: '3rem',
    },
    borderRadius: {
        sm: '0.25rem',
        md: '0.75rem',
        lg: '1rem',
        full: '50rem',
    },
    typography: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        fontSizeBase: '1rem',
        fontSizeSm: '0.875rem',
        fontSizeLg: '1.25rem',
        fontWeightNormal: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    }
};

const componentMap = {
    'hazard': { icon: FaExclamationTriangle },
    'incident/report': { icon: FaFileAlt },
    'systemroles/access': { icon: FaUserShield },
    'permission/access': { icon: FaKey },
    'systemroles': { icon: FaUsers },
    'nonconformity': { icon: FaClipboardCheck },
    'improvement-opportunity': { icon: FaLightbulb },
    'complaint': { icon: FaComments },
    'manage': { icon: FaCogs },
    'user/access': { icon: FaUserLock },
    'gsp': { icon: FaEye },
    'audit': { icon: FaClipboardCheck },
    'tbt': { icon: FaChalkboardTeacher },
    'rhpw': { icon: FaCalendarAlt },
    'rpmw': { icon: FaCalendarAlt },
    'ldo': { icon: FaFileAlt },
    'list': { icon: FaList },
    'status': { icon: FaExclamationCircle },
    'country': { icon: FaGlobeAmericas },
    'state': { icon: FaGlobeAmericas },
    'city': { icon: FaCity },
    'business/group': { icon: FaBuilding },
    'business/unit': { icon: FaBuilding },
    'project': { icon: FaProjectDiagram },
    'project/client': { icon: FaUserTie },
    'site': { icon: FaMapMarkerAlt },
    'course': { icon: FaGraduationCap },
    'question': { icon: FaQuestionCircle },
    'news/my': { icon: FaNewspaper },
    'assign': { icon: FaBookOpen },
    'enrolled': { icon: FaGraduationCap },
    'inform/list': { icon: FaInfoCircle },
    'inform/status': { icon: FaInfoCircle },
    'user': { icon: FaUser },
    'dashboard': { icon: FaTachometerAlt },
    'cabinet': { icon: FaArchive },
    'permit': { icon: FaFileSignature },
    'templates': { icon: FaClipboard },
    'register': { icon: FaExclamationTriangle },
    'irc': { icon: FaFileAlt },
    'cfr': { icon: FaChartBar },
    'cir': { icon: FaUserTie },
    'is': { icon: FaClipboardCheck },
    'io': { icon: FaClipboardCheck },
    'pdr': { icon: FaExclamationTriangle },
    'csr': { icon: FaChartBar },
    'ier': { icon: FaLeaf },
    'iar': { icon: FaListAlt },
    'organization': { icon: FaSitemap },
    'dashboard/global': { icon: FaGlobe },
    'dashboard/country': { icon: FaGlobeAmericas },
    'user/review': { icon: FaCheckSquare },
    'user/issue': { icon: FaFileSignature },
    'inform/qc': { icon: FaClipboardCheck },
    'dashboard/my': { icon: FaUserCog },
    'ncr': { icon: FaExclamationCircle },
    'incr': { icon: FaExclamationTriangle }
};

// New enhanced sidebar
const StyledSidebar = styled(Sidebar)`
    left: 0;
    bottom: 0;
    background: ${designTokens.colors.surface};
    border-right: 1px solid ${designTokens.colors.divider};
    height: calc(100vh - 70px) !important; /* Assuming 70px header height */
    margin-top: 70px; /* Align below header */
    margin-left: -25px;
    position: fixed; /* Keep sidebar fixed */
    transition: ${designTokens.transitions.medium};
    box-shadow: ${designTokens.shadows.md};
    z-index: 1000; /* Ensure sidebar is above main content */
    
    /* Enhanced hover effect when collapsed */
    ${props => props.collapsed ? css`
        &:hover {
            width: 280px !important; /* Explicit width on hover */
            min-width: 280px !important;
            box-shadow: ${designTokens.shadows.lg}; /* Stronger shadow on hover */
        }
    ` : ''}

    .ps-sidebar-root {
        border: none; /* Remove default border */
        height: 100%; /* Ensure root takes full height */
    }

    /* Menu Item Styling */
    .ps-menu-button {
        padding: ${designTokens.spacing.sm} ${designTokens.spacing.lg};
        font-size: ${designTokens.typography.fontSizeSm};
        color: ${designTokens.colors.textSecondary};
        font-weight: ${designTokens.typography.fontWeightMedium};
        border-radius: ${designTokens.borderRadius.md};
        margin: ${designTokens.spacing.xs} ${designTokens.spacing.sm}; /* Slightly more horizontal margin */
        transition: ${designTokens.transitions.short};
        position: relative; /* Needed for ::before pseudo-element */
        overflow: hidden; /* Clip hover effects */

        /* Subtle hover effect */
        &:hover {
            background: ${designTokens.colors.accentLight};
            color: ${designTokens.colors.accentDark};
            transform: translateX(3px); /* Subtle shift */
            /* Optional: Add a subtle border or glow */
            /* box-shadow: inset 0 0 0 1px ${designTokens.colors.accentLight}; */
        }
    }

    /* Submenu Content */
    .ps-submenu-content {
        background: ${designTokens.colors.surface}; /* Ensure consistent background */
        padding-left: ${designTokens.spacing.sm}; /* Indent submenu items slightly */
        padding-top: ${designTokens.spacing.sm};
        padding-bottom: ${designTokens.spacing.xs};
    }

    /* Icon Styling */
    .ps-menu-icon {
        color: ${designTokens.colors.textSecondary};
        margin-right: ${designTokens.spacing.sm};
        font-size: 1.1rem;
        transition: ${designTokens.transitions.medium};
        width: 20px; /* Ensure consistent icon spacing */
        text-align: center;
    }

    /* Active Menu Item Styling */
    .ps-active > .ps-menu-button, /* Target the button within the active item */
    .ps-active > .ps-menu-button:hover /* Maintain active style on hover */ {
        background: ${designTokens.colors.accentLight};
        color: ${designTokens.colors.accentDark};
        font-weight: ${designTokens.typography.fontWeightBold};
        box-shadow: ${designTokens.shadows.sm}; /* Subtle shadow for active item */
        
        /* More prominent active indicator */
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%); /* Center vertically */
            height: 80%; /* Make indicator slightly taller */
            width: 4px; /* Slightly thicker */
            background-color: ${designTokens.colors.accent};
            border-radius: 0 ${designTokens.borderRadius.sm} ${designTokens.borderRadius.sm} 0; /* Rounded corners */
        }

        /* Active Icon Color */
        .ps-menu-icon {
            color: ${designTokens.colors.accent}; /* Use accent color for active icon */
        }
    }
    
    /* Submenu Arrow Icon */
    .ps-submenu-expand-icon {
        transition: ${designTokens.transitions.short};
    }
    
    /* Rotate arrow when submenu is open */
    .ps-submenu-root.ps-open > .ps-menu-button > .ps-submenu-expand-icon {
        transform: rotate(90deg);
    }

    /* Style for the 'Menu' Submenu for separation */
    .menu-separator {
      margin-top: ${designTokens.spacing.lg}; /* Add space above */
      position: relative;
      /* Optional: Add a subtle line */
       &::before {
         content: '';
         position: absolute;
         top: -${designTokens.spacing.md}; /* Position line above */
         left: ${designTokens.spacing.md};
         right: ${designTokens.spacing.md};
         height: 1px;
         background: ${designTokens.colors.divider};
       }
    }
`;

const MainContent = styled.main`
  flex: 1;
  margin-left: ${props => props.collapsed ? '10px' : '10px'};
  margin-top: 70px;
  padding: ${designTokens.spacing.sm};
  transition: all 0.3s ease-in-out;
  background: linear-gradient(to bottom right, ${designTokens.colors.background}, #f0f4f8);
  min-height: calc(100vh - 70px);
  overflow-x: hidden;
  position: relative;
  
  /* Enhanced breadcrumbs */
    .breadcrumb-container {
        display: flex;
        align-items: center;
        background: ${designTokens.colors.surface};
        padding: ${designTokens.spacing.sm} ${designTokens.spacing.lg}; /* Adjusted padding */
        border-radius: ${designTokens.borderRadius.md}; /* Slightly less rounded */
        margin-bottom: ${designTokens.spacing.lg};
        box-shadow: ${designTokens.shadows.sm};
        border-left: 4px solid ${designTokens.colors.accent};
        transition: ${designTokens.transitions.short}; /* Faster transition */

        &:hover {
            box-shadow: ${designTokens.shadows.md};
            /* transform: translateY(-2px); */ /* Removed subtle lift, can be distracting */
        }
    }

    /* Breadcrumb list itself */
    .breadcrumb {
        margin-bottom: 0;
        background: transparent;
        padding: 0;
        display: flex;
        flex-wrap: wrap; /* Allow wrapping */
        align-items: center; /* Align items vertically */
        gap: ${designTokens.spacing.xs}; /* Gap between items */
        font-size: ${designTokens.typography.fontSizeSm};
        flex: 1; /* Take available space */
    }

    /* Individual breadcrumb items */
    .breadcrumb-item {
        display: flex;
        align-items: center;
        position: relative;

        /* Styling for links within breadcrumbs */
        a {
            color: ${designTokens.colors.textSecondary};
            text-decoration: none;
            font-weight: ${designTokens.typography.fontWeightMedium};
            transition: ${designTokens.transitions.short};
            padding: ${designTokens.spacing.xs} ${designTokens.spacing.sm};
            border-radius: ${designTokens.borderRadius.sm};
            display: flex; /* Align icon and text */
            align-items: center;
            gap: ${designTokens.spacing.xs}; /* Space between icon and text */

            &:hover {
                color: ${designTokens.colors.accent};
                background: ${designTokens.colors.accentLight};
            }
        }

        /* Styling for the active (last) breadcrumb item */
        &.active {
            color: ${designTokens.colors.text}; /* Use primary text color for active */
            font-weight: ${designTokens.typography.fontWeightBold};
            /* Use subtle background or just bold text */
            background: transparent;
            /* background: rgba(13, 110, 253, 0.08); */ /* Optional subtle background */
            padding: ${designTokens.spacing.xs} ${designTokens.spacing.sm};
            border-radius: ${designTokens.borderRadius.sm};
            /* box-shadow: inset 0 0 0 1px rgba(13, 110, 253, 0.1); */ /* Optional subtle inset */
        }

        /* Separator between items */
        & + .breadcrumb-item::before {
            color: ${designTokens.colors.textSecondary};
            content: "›"; /* Standard separator */
            font-size: 1.1rem; /* Slightly smaller */
            line-height: 1;
            padding: 0 ${designTokens.spacing.sm};
            opacity: 0.6; /* Make separator less prominent */
        }
    }

  /* Enhanced white card */
  .white-card {
    background: ${designTokens.colors.surface};
    border-radius: ${designTokens.borderRadius.lg};
    padding: ${designTokens.spacing.sm};
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    width: 100%;
    transition: ${designTokens.transitions.medium};
    border: 1px solid ${designTokens.colors.divider};
    position: relative;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(to right, ${designTokens.colors.accent}, ${designTokens.colors.info});
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.4s ease-out;
    }
    
    &:hover {
      box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);
      
      &::before {
        transform: scaleX(1);
      }
    }
  }

  /* Enhanced table */
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin: ${designTokens.spacing.md} 0;
    
    thead {
      background: rgba(13, 110, 253, 0.03);
    }
    
    th {
      font-weight: ${designTokens.typography.fontWeightBold};
      color: ${designTokens.colors.textSecondary};
      text-transform: uppercase;
      font-size: 0.75rem;
      letter-spacing: 0.05em;
      padding: ${designTokens.spacing.md} ${designTokens.spacing.lg};
      border-bottom: 2px solid ${designTokens.colors.divider};
      text-align: left;
      position: relative;
      
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: ${designTokens.colors.accent};
        transform: scaleX(0);
        transition: transform 0.3s ease;
      }
      
      &:hover::after {
        transform: scaleX(0.8);
      }
    }
    
    td {
      padding: ${designTokens.spacing.md} ${designTokens.spacing.lg};
      border-bottom: 1px solid ${designTokens.colors.divider};
      color: ${designTokens.colors.text};
      transition: ${designTokens.transitions.short};
    }
    
    tr:last-child td {
      border-bottom: none;
    }
    
    tbody tr {
      transition: all 0.2s ease;
      
      &:hover {
        background-color: rgba(13, 110, 253, 0.05);
        transform: translateX(4px);
        
        td {
          color: ${designTokens.colors.accent};
        }
      }
    }
    
    
  }
  
  /* Section headings */
  .section-heading {
    font-size: ${designTokens.typography.fontSizeLg};
    color: ${designTokens.colors.text};
    font-weight: ${designTokens.typography.fontWeightBold};
    margin-bottom: ${designTokens.spacing.lg};
    position: relative;
    padding-bottom: ${designTokens.spacing.sm};
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 3px;
      background: ${designTokens.colors.accent};
      border-radius: ${designTokens.borderRadius.full};
    }
  }
  
  /* Card layout within white-card */
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: ${designTokens.spacing.lg};
    margin-top: ${designTokens.spacing.lg};
  }
  
  .info-card {
    background: ${designTokens.colors.surface};
    border-radius: ${designTokens.borderRadius.md};
    padding: ${designTokens.spacing.lg};
    box-shadow: ${designTokens.shadows.sm};
    border: 1px solid ${designTokens.colors.divider};
    transition: ${designTokens.transitions.medium};
    position: relative;
    overflow: hidden;
    
    &:hover {
      transform: translateY(-4px);
      box-shadow: ${designTokens.shadows.md};
      border-color: ${designTokens.colors.accentLight};
    }
    
    .card-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      background: ${designTokens.colors.accentLight};
      color: ${designTokens.colors.accent};
      border-radius: ${designTokens.borderRadius.full};
      margin-bottom: ${designTokens.spacing.md};
      font-size: 1.25rem;
      transition: ${designTokens.transitions.medium};
    }
    
    &:hover .card-icon {
      background: ${designTokens.colors.accent};
      color: ${designTokens.colors.primary};
      transform: scale(1.1);
    }
    
    .card-title {
      font-weight: ${designTokens.typography.fontWeightBold};
      font-size: 1.1rem;
      margin-bottom: ${designTokens.spacing.sm};
      color: ${designTokens.colors.text};
    }
    
    .card-value {
      font-size: 1.5rem;
      font-weight: ${designTokens.typography.fontWeightBold};
      color: ${designTokens.colors.accent};
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    padding: ${designTokens.spacing.lg};
    
    .card-grid {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
  }
  
  @media (max-width: 768px) {
    margin-left: 0;
    padding: ${designTokens.spacing.md};
    
    .breadcrumb-container {
      padding: ${designTokens.spacing.sm};
    }
    
    .white-card {
      padding: ${designTokens.spacing.lg};
    }
    
    .card-grid {
      grid-template-columns: 1fr;
    }
    
    table {
      display: block;
      overflow-x: auto;
      
      th, td {
        min-width: 120px;
      }
    }
  }
`;

const BackButton = styled(Link)`
    display: flex;
    align-items: center;
    padding: ${designTokens.spacing.sm} ${designTokens.spacing.md};
    color: ${designTokens.colors.textSecondary};
    text-decoration: none;
    font-weight: ${designTokens.typography.fontWeightMedium};
    transition: ${designTokens.transitions.short};
    border-radius: ${designTokens.borderRadius.md};
    margin: ${designTokens.spacing.sm} ${designTokens.spacing.sm} ${designTokens.spacing.md};
    
    &:hover {
        background: ${designTokens.colors.accentLight};
        color: ${designTokens.colors.accentDark};
        transform: translateX(-4px);
    }
    
    svg {
        margin-right: ${designTokens.spacing.sm};
    }
`;

const DynamicPageLayout = () => {
    const { menuName } = useParams();
    const { navigation, isLoading } = useNavigation();
    const { leftSideBarType } = useSelector((state) => state.Layout);
    const [selectedModule, setSelectedModule] = useState(null);
    const [selectedSubmenu, setSelectedSubmenu] = useState(null);
    const [selectedSubmenuRoute, setSelectedSubmenuRoute] = useState(null);
    const navigate = useNavigate();
    const { collapseSidebar, collapsed } = useProSidebar();

    useEffect(() => {
        if (leftSideBarType === "condensed" && !collapsed) {
            collapseSidebar();
        } else if (leftSideBarType !== "condensed" && collapsed) {
            collapseSidebar();
        }

        const sidebar = document.querySelector('.ps-sidebar-root');

        const handleMouseEnter = () => {
            if (leftSideBarType === "condensed") {
                collapseSidebar(false);
            }
        };

        const handleMouseLeave = () => {
            if (leftSideBarType === "condensed") {
                collapseSidebar(true);
            }
        };

        if (sidebar) {
            sidebar.addEventListener('mouseenter', handleMouseEnter);
            sidebar.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (sidebar) {
                sidebar.removeEventListener('mouseenter', handleMouseEnter);
                sidebar.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, [leftSideBarType, collapsed, collapseSidebar]);

    const groupedNavigation = useMemo(() => {
        return navigation.reduce((acc, item) => {
            if (!acc[item.menu_name]) {
                acc[item.menu_name] = {};
            }
            if (!acc[item.menu_name][item.submenu_name]) {
                acc[item.menu_name][item.submenu_name] = [];
            }
            acc[item.menu_name][item.submenu_name].push({
                module_name: item.module_name,
                module_route: item.module_route,
                submenu_route: item.submenu_route
            });
            return acc;
        }, {});
    }, [navigation]);

    const uniqueMenus = useMemo(() =>
            [...new Set(navigation.map(item => item.menu_name))],
        [navigation]
    );

    const currentMenu = useMemo(() => {
        if (!menuName) return null;
        const formattedMenuName = menuName.replace(/-/g, ' ');
        return Object.keys(groupedNavigation).find(
            menu => menu.toLowerCase() === formattedMenuName.toLowerCase()
        );
    }, [menuName, groupedNavigation]);

    useEffect(() => {
        if (currentMenu && groupedNavigation[currentMenu]) {
            const firstSubmenu = Object.keys(groupedNavigation[currentMenu])[0];
            const firstModule = groupedNavigation[currentMenu][firstSubmenu][0];
            setSelectedSubmenu(firstSubmenu);
            setSelectedModule(firstModule.module_name);
            setSelectedSubmenuRoute(firstModule.module_route);
        }
    }, [currentMenu, groupedNavigation]);

    const handleModuleSelect = (submenuName, moduleName, moduleRoute) => {
        setSelectedSubmenu(submenuName);
        setSelectedModule(moduleName);
        setSelectedSubmenuRoute(moduleRoute);
    };

    const handleMenuSelect = (selectedMenuName) => {
        navigate(`/${selectedMenuName.toLowerCase().replace(/ /g, '-')}`);
    };

    const getIcon = (menuName) => {
        switch (menuName.toLowerCase()) {
            case 'dashboard': return <FaHome />;
            case 'human resources': return <FaUserShield />;
            case 'incident': return <FaExclamationTriangle />;
            case 'reports': return <FaChartBar />;
            default: return <FaCog />;
        }
    };

    const getSubMenuIcon = (moduleRoute) => {
        const key = Object.keys(componentMap).find(k =>
            moduleRoute.toLowerCase().includes(k.toLowerCase())
        );
        const IconComponent = key ? componentMap[key].icon : FaCog;
        return <IconComponent />;
    };

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                background: designTokens.colors.background
            }}>
                <div style={{
                    background: designTokens.colors.surface,
                    padding: designTokens.spacing.xl,
                    borderRadius: designTokens.borderRadius.lg,
                    boxShadow: designTokens.shadows.md,
                    textAlign: 'center',
                    maxWidth: '400px'
                }}>
                    <div style={{
                        width: '40px',
                        height: '40px',
                        border: `3px solid ${designTokens.colors.divider}`,
                        borderTop: `3px solid ${designTokens.colors.accent}`,
                        borderRadius: '50%',
                        margin: '0 auto 20px',
                        animation: 'spin 1s linear infinite'
                    }} />
                    <p style={{
                        fontSize: designTokens.typography.fontSizeBase,
                        color: designTokens.colors.text,
                        margin: 0
                    }}>Loading M-SPHERE...</p>
                </div>
                <style>
                    {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}
                </style>
            </div>
        );
    }



    return (
        <div style={{ display: 'flex', height: '100vh', fontFamily: designTokens.typography.fontFamily }}>
            <StyledSidebar
                className={`app ${collapsed ? 'collapsed' : ''}`}
                width="280px"
                collapsedWidth="80px"
                transitionDuration={300}
                collapsed={collapsed}
            >
                <Menu>
                    <BackButton to="/dashboard">
                        <FaArrowLeft />
                        Back to Home
                    </BackButton>

                    {currentMenu && groupedNavigation[currentMenu] &&
                        Object.entries(groupedNavigation[currentMenu]).map(([submenuName, modules]) => (
                            <SubMenu
                                key={submenuName}
                                label={submenuName}
                                icon={getSubMenuIcon(modules[0].module_route)}
                            >
                                {Array.from(new Set(modules.map(JSON.stringify)), JSON.parse)
                                    .map((module) => (
                                        <MenuItem
                                            key={module.module_name}
                                            icon={getSubMenuIcon(module.module_route)}
                                            onClick={() => handleModuleSelect(
                                                submenuName,
                                                module.module_name,
                                                module.module_route
                                            )}
                                            className={selectedModule === module.module_name ? 'ps-active' : ''}
                                        >
                                            {module.module_name.trim()}
                                        </MenuItem>
                                    ))}
                            </SubMenu>
                        ))}
                    <SubMenu label="Menu" icon={<FaGlobe />}>
                        {uniqueMenus.map((menu) => (
                            <MenuItem
                                key={menu}
                                icon={getIcon(menu)}
                                onClick={() => handleMenuSelect(menu)}
                            >
                                {menu}
                            </MenuItem>
                        ))}
                    </SubMenu>
                </Menu>
            </StyledSidebar>

            <MainContent collapsed={collapsed}>
                <Breadcrumb className="mb-3">
                    <BreadcrumbItem>M-SPHERE</BreadcrumbItem>
                    <BreadcrumbItem>
                        {currentMenu || (menuName && menuName.replace(/-/g, ' '))}
                    </BreadcrumbItem>
                    {selectedSubmenu &&
                        <BreadcrumbItem>{selectedSubmenu}</BreadcrumbItem>
                    }
                    {selectedModule &&
                        <BreadcrumbItem active>{selectedModule}</BreadcrumbItem>
                    }
                </Breadcrumb>
                <div className="white-card">
                    <GlobalComponent
                        submenuRoute={selectedSubmenuRoute}
                        navigation={navigation}
                    />
                </div>
            </MainContent>
        </div>
    );
};

export default DynamicPageLayout;