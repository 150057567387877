import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Alert, Card, Col, Row} from "reactstrap";
import {useDispatch} from "react-redux";
import Swal from "sweetalert2";
// import images
import whitelog from "../../assets/images/whitelog.png";
import logodark from "../../assets/images/logo-dark.png";
import {verifyUserEmail} from "../../store/actions/user";

const VerifyEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [verificationStatus, setVerificationStatus] = useState({
        isLoading: true,
        message: "",
        isError: false
    });

    useEffect(() => {
        const verifyEmail = async () => {
            const params = new URLSearchParams(location.search);
            const token = params.get('token');

            if (!token) {
                setVerificationStatus({
                    isLoading: false,
                    message: "Invalid or missing verification token",
                    isError: true
                });
                return;
            }

            try {
                const result = await dispatch(verifyUserEmail(token));
                console.log("result")
                console.log(result)


                if (result.success) {
                    setVerificationStatus({
                        isLoading: false,
                        message: "Email verified successfully!",
                        isError: false
                    });

                    let countdown = 5;

                    const countdownTimer = setInterval(() => {
                        countdown--;
                        if (countdown >= 0) {
                            Swal.update({
                                html: `Email verified successfully!<br><br>Redirecting to login page in ${countdown} seconds...`
                            });
                        } else {
                            clearInterval(countdownTimer);
                            Swal.close();
                            navigate("/login");
                        }
                    }, 1000);

                    Swal.fire({
                        icon: 'success',
                        title: 'Email Verified',
                        html: `Email verified successfully!<br><br>Redirecting to login page in 5 seconds...`,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        timer: 6000,
                        timerProgressBar: true,
                    });
                } else {
                    setVerificationStatus({
                        isLoading: false,
                        message: result.message || "Failed to verify email",
                        isError: true
                    });

                    Swal.fire({
                        icon: 'warning',
                        title: 'Verification Failed',
                        text: result.message || "Failed to verify email"
                    });
                }
            } catch (error) {
                setVerificationStatus({
                    isLoading: false,
                    message: "An error occurred during verification",
                    isError: true
                });

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "An error occurred during verification"
                });
            }
        };

        verifyEmail();
    }, [dispatch, location, navigate]);

    const pageStyle = {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f8f9fa',
    };

    const contentStyle = {
        flex: '1 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
    };

    const cardStyle = {
        width: '100%',
        maxWidth: '1000px',
        boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
        border: 'none',
        borderRadius: '0.5rem',
        overflow: 'hidden',
    };

    const leftColumnStyle = {
        backgroundColor: '#013e7d',
        color: '#fff',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    };

    const rightColumnStyle = {
        backgroundColor: '#fff',
        padding: '2rem',
    };

    const imageStyle = {
        maxWidth: '100%',
        height: 'auto',
        marginBottom: '2rem',
    };

    const footerStyle = {
        backgroundColor: '#f8f9fa',
        padding: '0.5rem 0',
        textAlign: 'center',
        borderTop: '1px solid #dee2e6',
    };

    return (
        <div style={pageStyle}>
            <div style={contentStyle}>
                <Card style={cardStyle}>
                    <Row className="g-0">
                        <Col md={5} style={leftColumnStyle}>
                            <img src={whitelog} alt="HSSE Illustration" style={imageStyle} />
                            <h1 style={{ marginBottom: '0.9rem', fontSize: '2rem', color: 'whitesmoke' }}>Email Verification</h1>
                            <p style={{ fontSize: '0.7rem', marginBottom: '1rem', maxWidth: '80%' }}>
                                We're verifying your email address to ensure secure access to your account.
                            </p>
                        </Col>
                        <Col md={7} style={rightColumnStyle}>
                            <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                                <Link to="/">
                                    <img src={logodark} alt="" height="30" style={{ display: 'inline-block' }} />
                                </Link>
                            </div>
                            <h4 style={{
                                fontSize: '1.5rem',
                                color: '#343a40',
                                marginBottom: '1.5rem',
                                textAlign: 'center'
                            }}>Email Verification Status</h4>

                            {verificationStatus.isLoading ? (
                                <div className="text-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p className="mt-3">Verifying your email address...</p>
                                </div>
                            ) : (
                                <>
                                    {verificationStatus.isError ? (
                                        <Alert color="danger">
                                            {verificationStatus.message}
                                        </Alert>
                                    ) : (
                                        <Alert color="success">
                                            {verificationStatus.message}
                                        </Alert>
                                    )}
                                    <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
                                        <p style={{ color: '#6c757d', margin: 0 }}>
                                            {verificationStatus.isError ? (
                                                <>
                                                    Need help?{" "}
                                                    <Link to="/contact" style={{ fontWeight: 500, color: '#013e7d' }}>
                                                        Contact Support
                                                    </Link>
                                                </>
                                            ) : (
                                                <>
                                                    Proceed to{" "}
                                                    <Link to="/login" style={{ fontWeight: 500, color: '#013e7d' }}>
                                                        Login
                                                    </Link>
                                                </>
                                            )}
                                        </p>
                                    </div>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card>
            </div>
            <footer style={footerStyle}>
                <p style={{ margin: 0, fontSize: '0.875rem' }}>
                    © {new Date().getFullYear()} HSSE. Crafted with{" "}
                    <i className="mdi mdi-heart" style={{ color: '#dc3545' }}></i> by M-SPHERE
                </p>
            </footer>
        </div>
    );
};

export default VerifyEmail;