import {ADD_PROJECT, GET_PROJECT, GET_PROJECT_VALUES, GET_PROJECTS, UPDATE_PROJECT} from "../../types";


const initialState = {list: {}, single: null,values:[]};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_PROJECTS:
            return {...state, list: payload};
        case GET_PROJECT_VALUES:
            return {...state, values: payload};

        case GET_PROJECT:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case ADD_PROJECT:
            return {...state, list: payload};

        case UPDATE_PROJECT:
            return {...state, list: payload};

        default:
            return state;
    }
};

export default reducer;
