import {
    ADD_BUSINESS_UNIT,
    GET_COUNTRIES,
    GET_BUSINESS_UNIT,
    GET_BUSINESS_UNITS,
    UPDATE_BUSINESS_UNIT,
    GET_BUSINESS_UNITS_COUNTRIES, GET_BUSINESS_UNITS_GROUPS
} from "../../types";


const initialState = {list: {},countries:[],businessGroups:[],single: null};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_BUSINESS_UNITS:
            return {...state, list: payload};

        case GET_BUSINESS_UNITS_COUNTRIES:
            return {...state, countries: payload};

        case GET_BUSINESS_UNITS_GROUPS:
            return {...state, businessGroups: payload};

        case GET_BUSINESS_UNIT:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case ADD_BUSINESS_UNIT:
            return {...state, list: payload};

        case UPDATE_BUSINESS_UNIT:
            return {...state, list: payload};

        default:
            return state;
    }
};

export default reducer;
