export const login = (user) => {
    localStorage.setItem('auth0', JSON.stringify(user))
}

// LOGOUT
export const logout = () => {
    localStorage.removeItem('auth0')
    localStorage.removeItem('copiedScope')
}

// LOGIN STATUS
export const isLogin = () => {
    var myObj = JSON.parse(localStorage.getItem('auth0'));
    var size = myObj ? Object.keys(myObj).length  : 0;
    if (size > 0) return true;
    return false;
}

// GET LOGIN DATA
export const getLoginData = () => {
    return JSON.parse(localStorage.getItem('auth0'));
}