import axios from "../../../axios";
import {COURSES_ENROLLED_BY_URUSER, COURSES_ENROLLED_BY_USER} from "../../types";
import {setAlert} from "../alert";

export const getUREnrolledCourses=(url)=>async (dispatch) => {
    try {
        const res = await axios.get(`/course/enrolled/${url}`);
        if (res.data.code === 1) {
            console.log("UNREGISTER USER RESPONSE",res)
            dispatch({type: COURSES_ENROLLED_BY_URUSER, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}