import jsPDF from "jspdf";

export const generatePermissionsPDF = (roleData, permissionsState, moduleStructure) => {
    const doc = new jsPDF({
        format: 'a3',
        unit: 'mm',
        orientation: 'landscape'
    });

    // A3 dimensions in mm
    const pageHeight = 297;
    const pageWidth = 420;
    const margin = 20;

    // Calculate usable width for permissions columns
    const labelWidth = 100;
    const remainingWidth = pageWidth - labelWidth - (2 * margin);
    const permissions = ['CREATE', 'READ', 'UPDATE', 'DELETE', 'SHARED READ', 'SHARED UPDATE', 'SHARED DELETE'];
    const colWidth = remainingWidth / permissions.length;

    let yPos = margin;

    // Title section
    doc.setFontSize(16);
    doc.text(`Role Permissions Report - ${roleData?.label || 'No Role Selected'}`, margin, yPos);
    yPos += 10;

    doc.setFontSize(12);
    doc.text('Select All', margin, yPos);
    yPos += 10;

    doc.setFontSize(14);
    doc.text('Module Permissions', margin, yPos);
    yPos += 15;

    // Permission headers
    doc.setFontSize(8);
    permissions.forEach((perm, index) => {
        doc.text(perm, labelWidth + margin + (index * colWidth), yPos, { align: 'center' });
    });
    yPos += 10;

    // Module structure
    if(moduleStructure) {
        Object.entries(moduleStructure).forEach(([menu, submenus]) => {
            // Check if we need a new page
            if (yPos > pageHeight - margin) {
                doc.addPage();
                yPos = margin;

                // Repeat headers on new page
                doc.setFontSize(8);
                permissions.forEach((perm, index) => {
                    doc.text(perm, labelWidth + margin + (index * colWidth), yPos, { align: 'center' });
                });
                yPos += 10;
            }

            // Menu header
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.text(menu, margin, yPos);
            yPos += 8;

            Object.entries(submenus).forEach(([submenu, modules]) => {
                if (yPos > pageHeight - margin) {
                    doc.addPage();
                    yPos = margin;

                    // Repeat headers on new page
                    doc.setFontSize(8);
                    permissions.forEach((perm, index) => {
                        doc.text(perm, labelWidth + margin + (index * colWidth), yPos, { align: 'center' });
                    });
                    yPos += 10;
                }

                // Submenu
                doc.setFontSize(9);
                doc.setFont('helvetica', 'normal');
                doc.text(submenu, margin + 10, yPos);
                yPos += 8;

                modules.forEach(module => {
                    if (yPos > pageHeight - margin) {
                        doc.addPage();
                        yPos = margin;

                        // Repeat headers on new page
                        doc.setFontSize(8);
                        permissions.forEach((perm, index) => {
                            doc.text(perm, labelWidth + margin + (index * colWidth), yPos, { align: 'center' });
                        });
                        yPos += 10;
                    }

                    // Module name
                    doc.setFontSize(8);
                    doc.text(module.name, margin + 20, yPos);

                    // Permission checkboxes - always empty if no role selected
                    permissions.forEach((perm, index) => {
                        const x = labelWidth + margin + (index * colWidth) - 1.5;
                        const boxSize = 3;
                        doc.rect(x, yPos - 2.5, boxSize, boxSize);

                        // Only fill checkbox if role is selected and has permission
                        if(roleData && permissionsState) {
                            const modulePermissions = permissionsState[menu]?.[submenu]?.[module.name];
                            if (modulePermissions?.[perm]) {
                                doc.rect(x, yPos - 2.5, boxSize, boxSize, 'F');
                            }
                        }
                    });

                    yPos += 8;
                });
            });

            yPos += 5;
        });
    }

    doc.save(`${roleData?.label || 'no-role'}_permissions.pdf`);
};