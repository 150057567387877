import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useProSidebar } from 'react-pro-sidebar';
import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";

const Header = (props) => {
  const [search, setsearch] = useState(false);
  const { collapseSidebar } = useProSidebar();

  useEffect(() => {
    const sidebar = document.querySelector('.sidebar');
    if (sidebar) {
      sidebar.addEventListener('mouseenter', expandSidebar);
      sidebar.addEventListener('mouseleave', collapseSidebarOnLeave);
    }
    return () => {
      if (sidebar) {
        sidebar.removeEventListener('mouseenter', expandSidebar);
        sidebar.removeEventListener('mouseleave', collapseSidebarOnLeave);
      }
    };
  }, []);

  const expandSidebar = () => {
    if (props.leftSideBarType === "condensed") {
      var body = document.body;
      body.classList.remove("vertical-collpsed");
      body.classList.add("sidebar-enable");
    }
  };

  const collapseSidebarOnLeave = () => {
    if (props.leftSideBarType === "condensed") {
      var body = document.body;
      body.classList.add("vertical-collpsed");
      body.classList.remove("sidebar-enable");
    }
  };

  function toggleFullscreen() {
    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    collapseSidebar();
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
    props.changeSidebarType(
        props.leftSideBarType === "default" ? "condensed" : "default"
    );
  }

  return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box text-center">
                <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="logo-sm-dark" height="22" />
                </span>
                  <span className="logo-lg">
                  <img src={logoDark} alt="logo-dark" height="24" />
                </span>
                </Link>

                <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="logo-sm-light" height="22" />
                </span>
                  <span className="logo-lg">
                  <img src={logoLight} alt="logo-light" height="24" />
                </span>
                </Link>
              </div>

              <button
                  type="button"
                  className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                  id="vertical-menu-btn"
                  onClick={() => {
                    tToggle();
                  }}
              >
                <i className="ri-menu-2-line align-middle"></i>
              </button>

              <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                  />
                  <span className="ri-search-line"></span>
                </div>
              </form>
            </div>

            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ms-2">
                <button
                    onClick={() => {
                      setsearch(!search);
                    }}
                    type="button"
                    className="btn header-item noti-icon"
                    id="page-header-search-dropdown"
                >
                  <i className="ri-search-line" />
                </button>
                <div
                    className={
                      search
                          ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                          : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                    }
                    aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search ..."
                            aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="ri-search-line" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                    type="button"
                    onClick={() => {
                      toggleFullscreen();
                    }}
                    className="btn header-item noti-icon"
                    data-toggle="fullscreen"
                >
                  <i className="ri-fullscreen-line" />
                </button>
              </div>

              <ProfileMenu />

              <div
                  className="dropdown d-inline-block"
                  onClick={() => {
                    props.showRightSidebarAction(!props.showRightSidebar);
                  }}
              >
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));