import {
    ADD_USER,
    GET_USER,
    GET_USER_BINDINGS,
    GET_USER_MENU,
    GET_USERS,
    UPDATE_USER,
    GET_USER_BINDING,
    GET_USERS_ACTIVE,
    GET_USER_VALUES,
    GET_USER_LINKS,
    GET_USER_MODULES,
    ADD_USER_LINKS, UPDATE_USER_LINKS,
    ADD_USER_MODULES,
    UPDATE_USER_MODULES,
    GET_USER_PROFILE, GET_USER_FEEDBACK, GET_UNREGISTERED_PROFILE
} from "../types";


const initialState = {list: [],unRegisteredUser:null,single: null, userProfile:null,permissions: [],userBindings:[],userBinding:null,active:[],userValues:[],userLinks:[],userModules:[],feedback:[]};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_USERS:
            return {...state, list: payload};
        case GET_USER_FEEDBACK:
            return {...state, feedback: payload};
        case GET_USER_PROFILE:
            return {...state, userProfile: payload};
            case GET_UNREGISTERED_PROFILE:
            return {...state, unRegisteredUser: payload};
        case GET_USERS_ACTIVE:
            return {...state, active: payload};
        case GET_USER_VALUES:
            return {...state, userValues: payload};
        case GET_USER_BINDINGS:
            return {...state, userBindings: payload};
        case GET_USER_BINDING:
            return {...state, userBinding: payload};
        case GET_USER:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case GET_USER_MENU:
            return {
                ...state, permissions: payload
            };
        case ADD_USER:
            return {}
        case GET_USER_LINKS:
            return {...state, userLinks: payload} 
        case ADD_USER_LINKS:
                return {
                    ...state,
                    userLinks: [payload, ...state.list],
                };
        case UPDATE_USER_LINKS:
            return {
                ...state,
                userLinks: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };
        case GET_USER_MODULES:
            return {...state, userModules: payload} 
        case ADD_USER_MODULES:
                return {
                    ...state,
                    userModules: [payload, ...state.list],
                };
        case UPDATE_USER_MODULES:
            return {
                ...state,
                userModules: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };

        case UPDATE_USER:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };


        default:
            return state;
    }
};

export default reducer;
