import Select from "react-select";
import React from "react";
import styled from "styled-components";

export const SelectWithAll = ({ options, value, onChange, ...props }) => {
    const allOption = { value: '*', label: 'Select All' };

    const handleChange = (selected, { action, option }) => {
        if (action === 'select-option' && option.value === '*') {
            // When "Select All" is clicked
            onChange(options);
        } else if (action === 'deselect-option' && option.value === '*') {
            // When "Select All" is deselected
            onChange([]);
        } else if (action === 'deselect-option') {
            // When any option is deselected
            onChange(selected.filter(option => option.value !== '*'));
        } else {
            // When any individual option is selected
            onChange(selected.filter(option => option.value !== '*'));
        }
    };

    return (
        <Select
            {...props}
            isMulti
            options={[allOption, ...options]}
            value={value}
            onChange={handleChange}
        />
    );
};


export const designTokens = {
    colors: {
        // Core Palette
        primary: '#ffffff',          // White (Surfaces, text on accent)
        accent: '#0d6efd',           // Standard Bootstrap Blue (Matches button/logo feel)
        accentDark: '#0b5ed7',       // Darker shade for hover/active
        accentLight: '#cfe2ff',      // Lighter shade for subtle backgrounds

        // Text
        text: '#212529',             // Standard dark text
        textSecondary: '#6c757d',    // Standard gray secondary text (like dates, card content)
        textOnAccent: '#ffffff',     // Added: Text on accent background (buttons, active tabs)

        // Backgrounds / Surfaces
        background: '#f8f9fa',       // Very light gray page background
        surface: '#ffffff',          // White for cards, menu items
        surfaceSubtle: '#f8f9fa',    // Added: Slightly off-white for subtle gradients/hovers (can be same as background)

        // Borders & Dividers
        border: '#dee2e6',           // Standard light border color
        divider: '#e9ecef',          // Slightly lighter for internal dividers

        // Other UI Colors from Image (assuming these are correct)
        buttonGrayBg: '#6c757d',     // Background for "Read More" button
        buttonGrayText: '#ffffff',   // Text color for gray button
        iconSystemAdmin: '#198754',  // Green (Example)
        iconFilingCabinet: '#6c757d',// Gray (Example)
        iconIncident: '#ffc107',     // Yellow (Example)
        iconSafety: '#0d6efd',       // Blue (Example)

        // Status (Standard Bootstrap Defaults)
        success: '#198754',
        error: '#dc3545',
        warning: '#ffc107',
        info: '#0dcaf0', // Or use accent
    },
    shadows: {
        // Very Subtle Shadows matching the flat look
        sm: '0 1px 3px rgba(0, 0, 0, 0.05)', // Subtle shadow for elevation
        md: '0 3px 8px rgba(0, 0, 0, 0.06)', // Default card/menu shadow
        lg: '0 5px 12px rgba(0, 0, 0, 0.07)', // Subtle hover shadow
        xl: '0 8px 20px rgba(0, 0, 0, 0.08)', // Added: Larger shadow for modals/popovers if needed
        none: 'none', // Explicitly no shadow
    },
    transitions: {
        // Standard, quick transitions
        short: 'all 150ms ease-in-out',
        medium: 'all 250ms ease-in-out',
    },
    spacing: { // Kept same, good baseline
        xs: '0.25rem', // 4px
        sm: '0.5rem',  // 8px
        md: '1rem',    // 16px
        lg: '1.5rem',  // 24px
        xl: '2rem',    // 32px
        xxl: '3rem',   // 48px
    },
    borderRadius: { // Matching screenshot look
        sm: '0.25rem', // 4px (buttons, inputs)
        md: '0.75rem', // 12px (cards, menu items, pagination) - Adjusted from 0.75 to match card better
        lg: '1rem',    // 16px (Larger cards, Modals?) - Adjusted CardStyles below to use md for consistency
        full: '50rem', // Pill shape
    },
    typography: {
        // Standard system font stack for broad compatibility
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        fontSizeBase: '1rem', // 16px
        fontSizeSm: '0.875rem', // 14px
        fontSizeLg: '1.25rem', // 20px
        fontWeightNormal: 400,
        fontWeightMedium: 500, // Added for potential use
        fontWeightBold: 700,
    }
};
const CardStyles = `
  background-color: ${designTokens.colors.surface};
  border-radius: ${designTokens.borderRadius.md}; // Changed to md for consistency
  border: 1px solid ${designTokens.colors.divider};
  box-shadow: ${designTokens.shadows.md};
  overflow: hidden;
  transition: transform ${designTokens.transitions.medium}, box-shadow ${designTokens.transitions.medium};
  margin-bottom: ${designTokens.spacing.lg};
  position: relative;
  will-change: transform, box-shadow;

  &:hover {
    transform: translateY(-4px);
    box-shadow: ${designTokens.shadows.lg};
  }

  .card-padding {
     padding: ${designTokens.spacing.lg};
     @media (max-width: 768px) {
        padding: ${designTokens.spacing.md}; // Reduce padding on smaller screens
     }
      @media (max-width: 576px) {
        padding: ${designTokens.spacing.sm}; // Further reduce padding
     }
  }

  .card-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: ${designTokens.colors.text};
    margin: 0 0 ${designTokens.spacing.sm} 0;
    letter-spacing: -0.01em;
  }

  .card-content {
    color: ${designTokens.colors.textSecondary};
    font-size: 0.9rem;
    line-height: 1.55;
  }
`;
const MenuItemStyles = `
  background: ${designTokens.colors.surface}; // Simpler solid background initially
  // Removed gradient for simpler base style, can be added back if needed
  border-radius: ${designTokens.borderRadius.md}; // Changed to md
  transition: ${designTokens.transitions.medium};
  text-decoration: none;
  color: ${designTokens.colors.text};
  box-shadow: ${designTokens.shadows.md};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${designTokens.spacing.lg};
  min-height: 150px; // Adjusted min-height slightly
  position: relative;
  overflow: hidden;
  will-change: transform, box-shadow, background;
  border: 1px solid ${designTokens.colors.divider};

   @media (max-width: 576px) {
        padding: ${designTokens.spacing.md}; // Reduce padding on small screens
        min-height: 120px; // Reduce min-height
    }

  &:hover {
    transform: translateY(-6px) scale(1.02);
    box-shadow: ${designTokens.shadows.lg};
    background: ${designTokens.colors.surface};
    border-color: ${designTokens.colors.border};
  }

  .icon {
    font-size: 32px; // Slightly smaller icon
    margin-bottom: ${designTokens.spacing.md};
    transition: transform ${designTokens.transitions.medium}, color ${designTokens.transitions.short};
    color: ${designTokens.colors.accent};
    filter: drop-shadow(0 2px 4px rgba(0, 122, 255, 0.2));

     @media (max-width: 576px) {
        font-size: 28px; // Smaller icon on small screens
        margin-bottom: ${designTokens.spacing.sm};
    }
  }

  .text {
    font-size: 0.9rem; // Slightly smaller text
    font-weight: 500;
    transition: color ${designTokens.transitions.short};
    color: ${designTokens.colors.text};
    line-height: 1.4;
     @media (max-width: 576px) {
        font-size: 0.85rem; // Smaller text on small screens
    }
  }

  &:hover .icon {
    transform: translateY(-4px);
    color: ${designTokens.colors.accentDark};
    filter: drop-shadow(0 3px 6px rgba(0, 94, 203, 0.3));
  }

  &:hover .text {
    color: ${designTokens.colors.accentDark};
  }

  &:focus-visible {
    outline: 3px solid ${designTokens.colors.accentLight};
    outline-offset: 2px;
    box-shadow: ${designTokens.shadows.lg};
    border-color: ${designTokens.colors.accent};
    background: ${designTokens.colors.surface};
  }
`;
export const DashboardWrapper = styled.div`
    /* Apply base font family and ensure box-sizing */
    font-family: ${designTokens.typography.fontFamily};
    color: ${designTokens.colors.text};
    background-color: ${designTokens.colors.background};

    *, *::before, *::after {
        box-sizing: border-box; // Apply border-box globally within this scope
    }

    /* CSS variables */
    --accent-color: ${designTokens.colors.accent};
    --accent-dark: ${designTokens.colors.accentDark};
    --accent-light: ${designTokens.colors.accentLight};
    --text-color: ${designTokens.colors.text};
    --text-secondary: ${designTokens.colors.textSecondary};
    --text-on-accent: ${designTokens.colors.textOnAccent};
    --background-color: ${designTokens.colors.background};
    --surface-color: ${designTokens.colors.surface};
    --surface-subtle: ${designTokens.colors.surfaceSubtle};
    --border-color: ${designTokens.colors.border};
    --divider-color: ${designTokens.colors.divider};
    --success-color: ${designTokens.colors.success};
    --error-color: ${designTokens.colors.error};
    --warning-color: ${designTokens.colors.warning};
    --shadow-sm: ${designTokens.shadows.sm};
    --shadow-md: ${designTokens.shadows.md};
    --shadow-lg: ${designTokens.shadows.lg};
    --shadow-xl: ${designTokens.shadows.xl};
    --trans-short: ${designTokens.transitions.short};
    --trans-medium: ${designTokens.transitions.medium};
    --border-radius-sm: ${designTokens.borderRadius.sm};
    --border-radius-md: ${designTokens.borderRadius.md};
    --border-radius-lg: ${designTokens.borderRadius.lg};
    --border-radius-full: ${designTokens.borderRadius.full};


    /* Mobile menu list */
    .mobile-menu-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 1rem;
        padding: 0;
        box-sizing: border-box;
    }

    .mobile-menu-item {
        display: flex;
        align-items: center;
        padding: 0.875rem 0.75rem;
        background-color: var(--surface-color, #ffffff);
        border-radius: 0.5rem;
        margin-bottom: 0.75rem;
        text-decoration: none;
        color: var(--text-color, #212529);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
        transition: transform 150ms ease-out, box-shadow 150ms ease-out;
        border: 1px solid var(--divider-color, #e9ecef);
        position: relative;
        border-left-width: 4px;
        border-left-style: solid;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
    }

    .mobile-menu-item:active {
        transform: translateY(1px);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
    }

    .mobile-menu-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        width: 32px;
        min-width: 32px;
        margin-right: 0.75rem;
        flex-shrink: 0;
    }

    .mobile-menu-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-width: 0; /* Important - prevents content from forcing parent to grow */
        overflow: hidden;
    }

    .mobile-menu-title {
        font-weight: 600;
        font-size: 0.95rem;
        margin-bottom: 0.25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mobile-menu-description {
        font-size: 0.8rem;
        color: var(--text-secondary, #6c757d);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mobile-menu-arrow {
        color: var(--text-secondary, #6c757d);
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        opacity: 0.7;
        margin-left: 0.5rem;
        width: 24px;
        min-width: 24px;
        flex-shrink: 0;
    }

    .mobile-menu-item:hover .mobile-menu-arrow {
        opacity: 1;
        transform: translateX(2px);
    }

    .mobile-menu-item:hover {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    }

    /* Mobile-specific page content adjustments */
    @media (max-width: 580px) {
        /* Ensure no horizontal overflow anywhere */
        html, body {
            max-width: 100vw;
            overflow-x: hidden;
        }

        /* Fix container padding */
        .container-fluid {
            padding-left: 8px !important;
            padding-right: 8px !important;
            width: 100% !important;
            max-width: 100% !important;
            overflow-x: hidden !important;
            box-sizing: border-box !important;
        }

        /* Fix page content */
        .page-content {
            padding: 0 !important;
            width: 100% !important;
            max-width: 100% !important;
            overflow-x: hidden !important;
            margin-top: 60px !important; /* Adjust if header height changes */
        }

        /* Card adjustments */
        .dashboard-card {
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: 100% !important;
            box-sizing: border-box !important;
        }

        .card-padding {
            padding: 12px !important;
        }

        /* Row and column fixes */
        .row {
            margin-left: -4px !important;
            margin-right: -4px !important;
        }

        .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6,
        .col-7, .col-8, .col-9, .col-10, .col-11, .col-12,
        .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3,
        .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8,
        .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto,
        .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4,
        .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9,
        .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm,
        .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5,
        .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10,
        .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1,
        .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6,
        .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11,
        .col-xl-12, .col-xl-auto {
            padding-left: 4px !important;
            padding-right: 4px !important;
        }

        /* Mobile menu specific fixes */
        .mobile-menu-list {
            width: 100% !important;
            max-width: 100% !important;
            padding: 0 !important;
            margin-bottom: 8px !important;
        }

        .mobile-menu-item {
            width: 100% !important;
            max-width: 100% !important;
            margin-bottom: 6px !important;
            padding: 10px 8px !important;
        }

        .mobile-menu-icon {
            font-size: 1.25rem !important;
            min-width: 28px !important;
            width: 28px !important;
            margin-right: 8px !important;
        }

        .mobile-menu-title {
            font-size: 0.9rem !important;
        }

        .mobile-menu-description {
            font-size: 0.75rem !important;
        }

        /* Headings */
        h2.card-title {
            font-size: 1.25rem !important;
            margin-bottom: 12px !important;
        }

        h3.card-title {
            font-size: 1.1rem !important;
            margin-bottom: 10px !important;
        }
    }

    /* Customize each menu item's left border based on its color */
    .mobile-menu-item {
        border-left-width: 4px;
        border-left-style: solid;
    }

    /* Add light ripple effect for taps */
    .mobile-menu-item:active::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 0.5rem;
        pointer-events: none;
    }


    /* Accessibility: Reduced motion preferences */
    @media (prefers-reduced-motion: reduce) {
        *, *::before, *::after {
            animation: none !important;
            transition: none !important;
            scroll-behavior: auto !important;
        }
    }

    /* Main layout */
    /* Base page-content styles */
    .page-content {
        padding: 1.5rem;
        margin-top: 70px;
        overflow-x: hidden;
        width: 100%;
        max-width: 100%;
        transition: padding 0.2s ease-out;
    }

    /* Media queries in the correct cascade order */
    @media (max-width: 1199.98px) {
        .page-content {
            padding: 1.25rem;
        }
    }

    @media (max-width: 991.98px) {
        .page-content {
            padding: 1rem;
            margin-top: 70px;
        }

        body[data-layout="horizontal"] .page-content {
            padding-top: 1rem;
        }
    }

    /* === Card components === */
    .dashboard-card {
        ${CardStyles}
            /* Add specific overrides for dashboard cards if needed */
        .card-padding {
            /* Overwrite CardStyles padding if different logic needed here */
            padding: ${designTokens.spacing.lg};
            @media (max-width: 768px) { padding: ${designTokens.spacing.md}; }
            @media (max-width: 576px) { padding: ${designTokens.spacing.sm}; }
        }
    }

    /* === Menu grid === */
    .menu-container {
        display: grid;
        /* Base desktop grid */
        grid-template-columns: repeat(4, 1fr);
        gap: ${designTokens.spacing.lg};
        padding: ${designTokens.spacing.sm} 0;
        width: 100%;

        @media (max-width: 991px) {
            /* Tablet view - 3 columns */
            grid-template-columns: repeat(3, 1fr);
            gap: ${designTokens.spacing.md};
        }

        @media (max-width: 767px) {
            /* Small tablet - 2 columns */
            grid-template-columns: repeat(2, 1fr);
            gap: ${designTokens.spacing.md};
        }

        @media (max-width: 575px) {
            /* Mobile view - force single column */
            grid-template-columns: 1fr;
            gap: ${designTokens.spacing.sm};
        }
    }
    /* === Menu items === */
    .menu-item {
        ${MenuItemStyles}
    }

    /* === Tab navigation (Pill Style) === */
    .nav-tabs {
        border-bottom: none;
        margin-bottom: ${designTokens.spacing.xl};
        display: flex;
        gap: ${designTokens.spacing.sm};
        flex-wrap: wrap; // Allows wrapping on smaller screens
        position: relative;
        // Remove horizontal padding if page-content handles it
            // padding: 0 ${designTokens.spacing.lg}; // Remove if handled by page-content

        .nav-link {
            color: var(--text-secondary);
            border: none;
            padding: ${designTokens.spacing.sm} ${designTokens.spacing.md};
            border-radius: var(--border-radius-full);
            font-weight: 500;
            font-size: 0.9rem;
            transition: color var(--trans-short), background-color var(--trans-short), box-shadow var(--trans-short);
            background-color: transparent;
            text-decoration: none;
            white-space: nowrap;

            &.active {
                color: var(--text-on-accent);
                background-color: var(--accent-color);
                font-weight: 600;
                box-shadow: 0 2px 6px rgba(0, 122, 255, 0.3);
            }

            &:hover:not(.active) {
                color: var(--text-color);
                background-color: ${designTokens.colors.divider};
            }

            &:focus-visible {
                outline: 2px solid var(--accent-color);
                outline-offset: 2px;
                z-index: 1;
                color: var(--text-color);
                background-color: ${designTokens.colors.divider};
            }
        }
    }

    /* === List group === */
    .list-group {
        padding-left: 0;
        list-style: none;
        margin-bottom: ${designTokens.spacing.lg};
        // width: 100%; // Ensure it doesn't overflow its container if nested
    }

    .list-group-item {
        // Styles seem generally okay for responsiveness
        border: none;
        padding: ${designTokens.spacing.sm} 0; // Adjust padding if needed, remove horizontal if container handles it
        border-radius: var(--border-radius-md);
        transition: background-color var(--trans-short);
        display: flex;
        align-items: center;
        background-color: transparent;
        position: relative;
        margin: 0 ${designTokens.spacing.sm}; // Add slight horizontal margin if items feel cramped side-to-side
        @media (max-width: 576px) {
            margin: 0; // Remove margin on smallest screens
            padding: ${designTokens.spacing.sm} ${designTokens.spacing.xs}; // Adjust padding
        }


        &:hover {
            background-color: var(--accent-light);
            cursor: pointer;
        }

        a {
            color: var(--text-color);
            text-decoration: none;
            flex-grow: 1;
            display: flex;
            align-items: center;
            padding: ${designTokens.spacing.xs} ${designTokens.spacing.md}; // Add internal padding back
            @media (max-width: 576px) {
                padding: ${designTokens.spacing.xs} ${designTokens.spacing.sm}; // Adjust padding
            }

            &:focus-visible {
                outline: none;
            }

            &:hover .item-icon,
            &:hover .external-icon {
                color: var(--accent-dark);
            }
            &:hover .item-text {
                color: var(--accent-dark);
            }
        }

        .item-icon {
            margin-right: ${designTokens.spacing.md};
            color: var(--text-secondary);
            font-size: 1.1rem;
            transition: color var(--trans-short);
            flex-shrink: 0;
            line-height: 1;
            @media (max-width: 576px) {
                margin-right: ${designTokens.spacing.sm}; // Reduce margin
            }
        }

        .item-text {
            flex-grow: 1;
            transition: color var(--trans-short);
            // Add word-break if long text is possible
            // word-break: break-word;
        }

        .external-icon {
            margin-left: ${designTokens.spacing.sm};
            color: var(--text-secondary);
            opacity: 0.7;
            font-size: 0.9rem;
            transition: opacity var(--trans-short), transform var(--trans-short), color var(--trans-short);
        }

        &:hover .external-icon {
            opacity: 1;
            transform: translateX(3px);
        }

        &:focus-within,
        &:has(a:focus-visible) {
            outline: 2px solid var(--accent-color);
            outline-offset: -2px;
            background-color: var(--accent-light);
            border-radius: var(--border-radius-md);
            z-index: 1;

            .item-icon, .external-icon, .item-text {
                color: var(--accent-dark);
            }
        }
    }

    /* === Action buttons === */
    .action-button {
        // Styles seem okay, ensure container allows wrapping if needed
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: ${designTokens.spacing.sm} ${designTokens.spacing.lg};
        border-radius: var(--border-radius-md);
        font-weight: 500;
        font-size: 0.95rem;
        line-height: 1.4;
        text-transform: none;
        letter-spacing: normal;
        border: none;
        background: var(--accent-color);
        color: var(--text-on-accent);
        box-shadow: var(--shadow-sm);
        transition: transform var(--trans-short), box-shadow var(--trans-short), background-color var(--trans-short);
        will-change: transform, box-shadow, background-color;
        cursor: pointer;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
            background-color: var(--accent-dark);
            box-shadow: var(--shadow-md);
            transform: translateY(-2px);
        }
        &:active {
            transform: translateY(0px);
            box-shadow: var(--shadow-sm);
            background-color: var(--accent-dark);
        }
        &:focus-visible {
            outline: 3px solid var(--accent-light);
            outline-offset: 2px;
            box-shadow: var(--shadow-md);
        }

        @media (max-width: 576px) {
            padding: ${designTokens.spacing.sm} ${designTokens.spacing.md}; // Smaller padding on small screens
            font-size: 0.9rem; // Slightly smaller text
        }
    }

    /* === Stats and KPI cards section === */
    .stats-grid {
        display: grid;
        /* ADJUSTED minmax for better small screen fit */
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); // Match menu grid
        gap: ${designTokens.spacing.lg};
        margin-bottom: ${designTokens.spacing.xl};
        // Remove horizontal padding if page-content handles it

        @media (max-width: 768px) {
            gap: ${designTokens.spacing.md}; // Reduce gap earlier
        }

        @media (max-width: 576px) {
            /* Further reduce minmax */
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
            gap: ${designTokens.spacing.md};
        }
        @media (max-width: 375px) { // Example for very small screens
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); // Even smaller min
            gap: ${designTokens.spacing.sm};
        }


        .stat-card {
            ${CardStyles}
                /* Override card padding specifically for stat cards */
            .card-padding { // Use class directly if card doesn't wrap content in it
                padding: ${designTokens.spacing.md};
                @media (max-width: 576px) { padding: ${designTokens.spacing.sm}; }
            }
            // Or apply padding directly if .card-padding isn't used inside
                // padding: ${designTokens.spacing.md};
                // @media (max-width: 576px) { padding: ${designTokens.spacing.sm}; }

            text-align: left;
            margin-bottom: 0; // Remove bottom margin if gap handles spacing

            .stat-label {
                font-size: 0.75rem; // Even smaller label
                color: var(--text-secondary);
                text-transform: uppercase;
                letter-spacing: 0.06em;
                font-weight: 500;
                margin-bottom: ${designTokens.spacing.xs};
                white-space: nowrap; // Prevent label wrapping
                overflow: hidden;
                text-overflow: ellipsis; // Add ellipsis if label is too long
            }

            .stat-value {
                font-size: 1.6rem; // Slightly smaller value
                font-weight: 600;
                color: var(--text-color);
                margin: 0 0 ${designTokens.spacing.xs} 0;
                line-height: 1.2;
                letter-spacing: -0.02em;
                white-space: nowrap; // Prevent value wrapping
            }

            .stat-icon {
                font-size: 1.3rem; // Slightly smaller
                color: var(--text-secondary);
                margin-right: ${designTokens.spacing.sm};
                opacity: 0.8;
            }

            .stat-trend {
                display: flex;
                align-items: center;
                font-size: 0.8rem; // Smaller trend text
                font-weight: 500;
                margin-top: ${designTokens.spacing.sm};

                &.positive { color: var(--success-color); }
                &.negative { color: var(--error-color); }
                &.neutral { color: var(--text-secondary); }

                .trend-icon {
                    margin-right: ${designTokens.spacing.xs};
                    font-size: 0.9rem; // Smaller trend icon
                }
            }
        }
    }

    /* === Skeleton loading states === */
    @keyframes shimmer-animation {
        0% {
            background-position: -1000px 0;
        }
        100% {
            background-position: 1000px 0;
        }
    }

    .skeleton {
        background: linear-gradient(90deg, rgba(0,0,0,0.03) 25%, rgba(0,0,0,0.05) 37%, rgba(0,0,0,0.03) 63%);
        background-size: 2000px 100%;
        animation: shimmer-animation 1.5s infinite linear;
        border-radius: var(--border-radius-md);
    }

    .skeleton-text {
        height: 1em;
        margin-bottom: 0.5em;
    }

    .skeleton-title {
        height: 1.5em;
        margin-bottom: 1em;
        width: 60%;
    }

    .skeleton-avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }

    .skeleton-button {
        height: 36px;
        width: 120px;
        border-radius: var(--border-radius-md);
    }

    .skeleton-card {
        border-radius: var(--border-radius-md);
    }

    .skeleton-menu-item {
        height: 150px;
        border-radius: var(--border-radius-md);
        @media (max-width: 576px) {
            height: 120px;
        }
    }

    /* === Toast notifications === */
    .toast-container {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 9999;
    }

    .toast {
        display: flex;
        align-items: flex-start;
        width: 300px;
        max-width: 90vw;
        background: var(--surface-color);
        border-radius: var(--border-radius-md);
        margin-bottom: 8px;
        padding: 12px 16px;
        border-left: 4px solid;
        box-shadow: var(--shadow-lg);
        animation: toast-in 0.3s ease forwards;
        overflow: hidden;
    }

    .toast-success { border-color: var(--success-color); }
    .toast-error { border-color: var(--error-color); }
    .toast-warning { border-color: var(--warning-color); }
    .toast-info { border-color: var(--accent-color); }

    .toast-icon {
        margin-right: 12px;
        font-size: 20px;
        flex-shrink: 0;
    }

    .toast-success .toast-icon { color: var(--success-color); }
    .toast-error .toast-icon { color: var(--error-color); }
    .toast-warning .toast-icon { color: var(--warning-color); }
    .toast-info .toast-icon { color: var(--accent-color); }

    .toast-content {
        flex: 1;
    }

    .toast-close {
        background: none;
        border: none;
        color: var(--text-secondary);
        cursor: pointer;
        margin-left: 8px;
        padding: 0;
        font-size: 16px;
        opacity: 0.7;
        transition: opacity var(--trans-short);
    }

    .toast-close:hover {
        opacity: 1;
    }

    @keyframes toast-in {
        from {
            transform: translateX(100%);
            opacity: 0;
        }
        to {
            transform: translateX(0);
            opacity: 1;
        }
    }

    /* === Pagination component === */
    .pagination {
        // Styles seem generally okay for responsiveness due to flex-wrap
        display: flex;
        flex-wrap: wrap;
        gap: ${designTokens.spacing.sm};
        align-items: center;
        justify-content: center;
        margin-top: ${designTokens.spacing.xl};
        padding: 0;
        list-style: none;
        // Remove horizontal padding if page-content handles it

        .page-item {
            .page-link {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 36px;
                height: 36px;
                padding: 0 ${designTokens.spacing.xs};
                border-radius: var(--border-radius-md);
                background-color: var(--surface-color);
                color: var(--text-color);
                box-shadow: var(--shadow-sm);
                transition: all var(--trans-short);
                border: 1px solid var(--divider-color);
                text-decoration: none;
                font-weight: 500;
                cursor: pointer;
                font-size: 0.9rem;

                svg { height: 1em; width: 1em; }

                &:hover:not(.active) {
                    border-color: var(--border-color);
                    box-shadow: var(--shadow-md);
                    transform: translateY(-1px);
                    color: var(--accent-color);
                }

                &.active {
                    background-color: var(--accent-color);
                    color: var(--text-on-accent);
                    font-weight: 600;
                    border-color: var(--accent-color);
                    box-shadow: 0 2px 6px rgba(0, 122, 255, 0.3);
                    cursor: default;
                }

                &:focus-visible {
                    outline: 2px solid var(--accent-color);
                    outline-offset: 2px;
                    z-index: 1;
                    border-color: var(--accent-color);
                }
            }

            &.disabled .page-link {
                opacity: 0.5;
                pointer-events: none;
                box-shadow: none;
                background-color: var(--surface-subtle);
                border-color: var(--divider-color);
                color: var(--text-secondary);
            }
        }
    }

    /* === Search component === */
    .search-container {
        // Styles seem okay, input is width: 100%
        position: relative;
        margin-bottom: ${designTokens.spacing.lg};
        max-width: 100%; // Ensure container doesn't overflow

        .search-input {
            width: 100%;
            height: 44px;
            padding: ${designTokens.spacing.sm} ${designTokens.spacing.lg};
            padding-left: 40px;
            border-radius: var(--border-radius-md);
            border: 1px solid var(--border-color);
            background-color: var(--surface-color);
            box-shadow: none;
            transition: border-color var(--trans-short), box-shadow var(--trans-short);
            font-size: 0.95rem;
            color: var(--text-color);

            &:focus {
                outline: none;
                border-color: var(--accent-color);
                box-shadow: 0 0 0 3px var(--accent-light);
            }

            &::placeholder {
                color: var(--text-secondary);
                opacity: 1;
            }
        }

        .search-icon {
            position: absolute;
            left: 14px;
            top: 50%;
            transform: translateY(-50%);
            color: var(--text-secondary);
            pointer-events: none;
            font-size: 1.2rem;
        }
    }

    /* Ensure container respects viewport width at all times */
    .container-fluid {
        max-width: 100vw !important;
        box-sizing: border-box !important;
    }

    /* Fix for mobile container width */
    @media (max-width: 768px) {
        .container-fluid {
            width: 100% !important;
        }
    }

    /* Global mobile menu container */
    .mobile-menu-container {
        width: 100%;
        overflow: hidden;
    }
`;