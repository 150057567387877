import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Components
import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../../components/Common/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../../components/Common/TopbarDropdown/ProfileMenu";
import AppsDropdown from "../../components/Common/TopbarDropdown/AppsDropdown";

// i18n
import { withTranslation } from "react-i18next";

// Images
import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

// Styled Components
const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.25s ease-in-out;
  height: 70px;
  
  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);
  }
`;

const NavbarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 1.5rem;
  
  @media (min-width: 992px) {
    padding: 0 2rem;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const BrandBox = styled.div`
  text-align: center;
  padding-right: 1.5rem;
  
  .logo {
    display: block;
    line-height: 70px;
    transition: all 0.2s;
    
    &:hover {
      opacity: 0.85;
    }
  }
  
  .logo-lg {
    display: block;
    
    @media (max-width: 991.98px) {
      display: none;
    }
  }
  
  .logo-sm {
    display: none;
    
    @media (max-width: 991.98px) {
      display: block;
    }
  }
`;

const MenuButton = styled.button`
  width: 36px;
  height: 36px;
  padding: 0;
  font-size: 1.1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #495057;
  background: #f8f9fa;
  transition: all 0.2s;
  margin-right: 0.5rem;
  
  &:hover {
    background: #e9ecef;
    color: #0d6efd;
    transform: translateY(-2px);
  }
  
  @media (min-width: 992px) {
    display: none;
  }
`;

const SearchForm = styled.form`
  max-width: 240px;
  
  @media (max-width: 991.98px) {
    display: none;
  }
  
  .search-box {
    position: relative;
    
    input {
      padding-left: 2.5rem;
      padding-right: 1rem;
      height: 38px;
      border: 1px solid #e9ecef;
      border-radius: 30px;
      background-color: #f8f9fa;
      transition: all 0.2s;
      
      &:focus {
        border-color: #dee2e6;
        background-color: #fff;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.1);
      }
    }
    
    i {
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
      color: #adb5bd;
      pointer-events: none;
    }
  }
`;

const MobileSearchButton = styled.button`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f8f9fa;
  border: none;
  color: #495057;
  margin-right: 0.5rem;
  transition: all 0.2s;
  
  &:hover {
    background: #e9ecef;
    color: #0d6efd;
  }
  
  @media (min-width: 992px) {
    display: none;
  }
`;

const MobileSearchDropdown = styled.div`
  width: 300px !important;
  border-radius: 0.5rem;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  border: none;
  
  .search-form {
    padding: 1rem;
    
    .input-group {
      .form-control {
        height: 38px;
        border-radius: 0.25rem 0 0 0.25rem;
        border: 1px solid #dee2e6;
        
        &:focus {
          box-shadow: none;
          border-color: #0d6efd;
        }
      }
      
      .btn {
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0.25rem 0.25rem 0;
      }
    }
  }
`;

const HeaderIcon = styled.button`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  border: none;
  color: #495057;
  margin: 0 0.3rem;
  transition: all 0.2s;
  position: relative;
  
  &:hover {
    background: #f8f9fa;
    color: #0d6efd;
    transform: translateY(-2px);
  }
  
  i {
    font-size: 1.2rem;
  }
  
  @media (max-width: 991.98px) {
    margin: 0 0.2rem;
  }
`;

const DropdownSeparator = styled.div`
  height: 25px;
  width: 1px;
  background-color: #e9ecef;
  margin: 0 0.5rem;
  
  @media (max-width: 576px) {
    display: none;
  }
`;

const Header = (props) => {
  const [search, setSearch] = useState(false);

  function toggleFullscreen() {
    if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
      <React.Fragment>
        <HeaderWrapper id="page-topbar">
          <NavbarHeader>
            <LeftSection>
              <BrandBox>
                <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="logo-sm-dark" height="28" />
                </span>
                  <span className="logo-lg">
                  <img src={logoDark} alt="logo-dark" height="30" />
                </span>
                </Link>

                <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="logo-sm-light" height="28" />
                </span>
                  <span className="logo-lg">
                  <img src={logoLight} alt="logo-light" height="30" />
                </span>
                </Link>
              </BrandBox>

              <MenuButton
                  type="button"
                  onClick={() => {
                    props.toggleLeftmenu(!props.leftMenu);
                  }}
                  data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
              </MenuButton>

              <SearchForm className="app-search">
                <div className="search-box position-relative">
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                  />
                  <i className="ri-search-line"></i>
                </div>
              </SearchForm>
            </LeftSection>

            <RightSection>
              <div className="d-inline-block d-lg-none">
                <MobileSearchButton
                    onClick={() => {
                      setSearch(!search);
                    }}
                    type="button"
                    id="page-header-search-dropdown"
                >
                  <i className="ri-search-line" />
                </MobileSearchButton>

                <MobileSearchDropdown
                    className={
                      search
                          ? "dropdown-menu dropdown-menu-end show"
                          : "dropdown-menu dropdown-menu-end"
                    }
                    aria-labelledby="page-header-search-dropdown"
                >
                  <form className="search-form">
                    <div className="input-group mb-0">
                      <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          aria-label="Search input"
                      />
                      <button className="btn btn-primary" type="submit">
                        <i className="ri-search-line" />
                      </button>
                    </div>
                  </form>
                </MobileSearchDropdown>
              </div>

              <LanguageDropdown />
              <DropdownSeparator />
              <AppsDropdown />

              <HeaderIcon
                  type="button"
                  onClick={toggleFullscreen}
                  className="d-none d-lg-inline-block"
                  data-toggle="fullscreen"
              >
                <i className="ri-fullscreen-line" />
              </HeaderIcon>

              <NotificationDropdown />
              <DropdownSeparator />
              <ProfileMenu />

              <HeaderIcon
                  type="button"
                  onClick={() => {
                    props.showRightSidebarAction(!props.showRightSidebar);
                  }}
                  className="right-bar-toggle"
              >
                <i className="mdi mdi-cog" />
              </HeaderIcon>
            </RightSection>
          </NavbarHeader>
        </HeaderWrapper>
      </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
      state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));