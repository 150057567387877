import React, { useEffect, useState, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from 'classnames';
import { getNavigation } from "../../store/actions/navigation";
import {
    FaChartBar,
    FaExclamationTriangle,
    FaHardHat,
    FaInfoCircle,
    FaProjectDiagram,
    FaQuestion,
    FaShieldAlt,
    FaUserGraduate,
    FaUserShield,
    FaAccessibleIcon,
    FaRegLightbulb,
    FaExternalLinkAlt,
    FaSearch,
    FaChevronRight
} from "react-icons/fa";
import { getLatestNews } from "../../store/actions/news";
import CustomNewsCarousel from "./NewsCarouselModel";
import { getUserLinks, getUserQuickRoutes } from "../../store/actions/user";
import { componentMap } from '../../components/GlobalComponents/index';
import { FcFilingCabinet } from "react-icons/fc";
import { SlipperyIcon, SuspenseModalComponent } from "../../utils/functions";
import { DashboardWrapper, SelectWithAll, designTokens } from "../../utils/components";

const SearchComponent = () => {
    return (
        <div className="search-container">
            <input
                type="search"
                className="search-input"
                placeholder="Search M-SPHERE..."
                aria-label="Search M-SPHERE"
            />
            <FaSearch className="search-icon" aria-hidden="true" />
        </div>
    );
};

const MetricsSummary = () => {
    const isMobile = useMediaQuery({ maxWidth: 575.98 });
    const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 991.98 });

    const metrics = [
        { id: 1, label: "Active Incidents", value: 12, icon: <FaExclamationTriangle />, trend: "+2", trendType: "negative" },
        { id: 2, label: "Completion Rate", value: "94%", icon: <FaChartBar />, trend: "+5%", trendType: "positive" },
        { id: 3, label: "Open Tasks", value: 28, icon: <FaShieldAlt />, trend: "-3", trendType: "positive" },
        { id: 4, label: "System Health", value: "Good", icon: <FaInfoCircle />, trend: "Stable", trendType: "neutral" }
    ];

    const displayMetrics = isMobile ? metrics.slice(0, 2) : metrics;

    return (
        <div className="stats-grid" style={isMobile ? { gridTemplateColumns: '1fr 1fr' } : {}}>
            {displayMetrics.map(metric => (
                <div className="stat-card" key={metric.id}>
                    <div className="stat-label">{metric.label}</div>
                    <div className="stat-value">{metric.value}</div>
                    <div className={`stat-trend ${metric.trendType}`}>
                        <span className="trend-icon">{metric.trendType === "positive" ? "↑" : metric.trendType === "negative" ? "↓" : "→"}</span>
                        {metric.trend}
                    </div>
                </div>
            ))}
            {isMobile && (
                <div className="stat-card view-more">
                    <Link to="/metrics" className="view-all-link">
                        View all metrics
                    </Link>
                </div>
            )}
        </div>
    );
};

const MobileMenuList = ({ menuItems }) => {
    return (
        <div className="mobile-menu-list">
            {menuItems.map((item, index) => (
                <Link
                    key={index}
                    to={`/${item.name.toLowerCase().replace(/\s+/g, "-")}`}
                    className="mobile-menu-item"
                    style={{
                        borderLeftColor: item.color
                    }}
                >
                    <div
                        className="mobile-menu-icon"
                        style={{
                            color: item.color
                        }}
                    >
                        {item.icon}
                    </div>
                    <div className="mobile-menu-content">
                        <div className="mobile-menu-title">
                            {item.name === "Metrics" ? "Dashboard" : item.name}
                        </div>
                        <div className="mobile-menu-description">
                            {item.ethicalNote}
                        </div>
                    </div>
                    <div className="mobile-menu-arrow">
                        <FaChevronRight />
                    </div>
                </Link>
            ))}
        </div>
    );
};

const CommonlyUsedForms = ({ modules, navigationData }) => {
    const [isPending, startTransition] = useTransition();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    const [access, setAccess] = useState(null);
    const [shouldRenderComponent, setShouldRenderComponent] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const isMobile = useMediaQuery({ maxWidth: 575.98 });
    const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 991.98 });

    useEffect(() => {
        if (!isPending && selectedComponent && modalOpen) {
            setShouldRenderComponent(true);
        }

        if (!modalOpen) {
            setShouldRenderComponent(false);
        }
    }, [isPending, selectedComponent, modalOpen]);

    const moduleRouteToId = (submenuRoute) => {
        const module = navigationData.find(item => item.module_route === submenuRoute);
        return module ? module.role_json : null;
    };

    const handleModuleSelect = (object) => {
        setModalTitle(object.name);
        setShouldRenderComponent(false);

        const module = navigationData.find(item => item.role_json.moduleId === object.moduleId);
        if (module) {
            const Component = componentMap[module.module_route];
            if (Component) {
                const moduleAccess = moduleRouteToId(module.module_route);

                startTransition(() => {
                    setSelectedComponent(() => Component);
                    setAccess(moduleAccess);
                    setModalOpen(true);
                });
            } else {
                console.error(`No component found for module_route: ${module.module_route}`);
            }
        }
    };

    const toggleModal = () => {
        if (modalOpen) {
            setModalOpen(false);
            setTimeout(() => {
                setSelectedComponent(null);
            }, 300);
        } else {
            startTransition(() => {
                setModalOpen(true);
            });
        }
    };

    const filteredModules = modules?.filter(module =>
        module.name.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

    const listMaxHeight = isMobile ? '200px' : isTablet ? '250px' : '300px';

    return (
        <>
            <div className="search-container mb-3">
                <input
                    type="search"
                    className="search-input"
                    placeholder={isMobile ? "Find..." : "Find a form..."}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    aria-label="Search forms"
                />
                <FaSearch className="search-icon" aria-hidden="true" />
            </div>

            {isPending && (
                <div className="d-flex align-items-center justify-content-center py-3" aria-live="polite">
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" />
                    <span>Loading forms...</span>
                </div>
            )}

            {filteredModules.length > 0 ? (
                <div
                    className="list-group"
                    style={{
                        maxHeight: listMaxHeight,
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                    aria-label="Available forms"
                >
                    {filteredModules.map((module) => (
                        <div
                            key={module.id}
                            className="list-group-item"
                            onClick={() => handleModuleSelect(module)}
                            aria-disabled={isPending}
                        >
                            <a href="#" onClick={(e) => e.preventDefault()} className="item-text">
                                <div className="item-icon">
                                    <FaRegLightbulb />
                                </div>
                                {module.name}
                                <FaExternalLinkAlt className="external-icon" aria-hidden="true" />
                            </a>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center py-4 text-muted">
                    {modules?.length > 0 ? (
                        <p>No forms match your search criteria</p>
                    ) : (
                        <>
                            <FaRegLightbulb className="mb-2" size={isMobile ? 20 : 24} />
                            <p>No forms available. Please contact your administrator.</p>
                        </>
                    )}
                </div>
            )}

            <SuspenseModalComponent
                isOpen={modalOpen}
                toggle={toggleModal}
                SelectedComponent={shouldRenderComponent ? selectedComponent : null}
                title={modalTitle}
                access={access}
                fullScreen={isMobile}
            />
        </>
    );
};

const DashboardSkeleton = () => {
    const isMobile = useMediaQuery({ maxWidth: 575.98 });
    const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 991.98 });

    return (
        <>
            <div className="skeleton skeleton-card mb-4" style={{ height: isMobile ? "80px" : "100px" }} />
            <div className="stats-grid mb-4" style={isMobile ? { gridTemplateColumns: 'repeat(2, 1fr)' } : {}}>
                {[1, 2, 3, 4].slice(0, isMobile ? 2 : 4).map(i => (
                    <div className="skeleton skeleton-card" key={i} style={{ height: isMobile ? "100px" : "120px" }} />
                ))}
            </div>
            <div className="skeleton skeleton-card mb-4" style={{ height: isMobile ? "200px" : "300px" }} />
            <div className="row">
                <div className={isMobile ? "col-12 mb-4" : isTablet ? "col-md-6 mb-4" : "col-xl-8"}>
                    <div className="skeleton skeleton-card" style={{ height: isMobile ? "300px" : "400px" }} />
                </div>
                <div className={isMobile ? "col-12" : isTablet ? "col-md-6" : "col-xl-4"}>
                    <div className="skeleton skeleton-card" style={{ height: isMobile ? "250px" : "400px" }} />
                </div>
            </div>
        </>
    );
};

const Dashboard = () => {
    const dispatch = useDispatch();
    const navigation = useSelector((state) => state.navigation.list);
    const news = useSelector((state) => state.news.list);
    const quickLinks = useSelector((state) => state.users.userLinks);
    const modules = useSelector((state) => state.users.userModules);

    const isMobileTiny = useMediaQuery({ maxWidth: 580 });
    const isMobile = useMediaQuery({ maxWidth: 575.98 });
    const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 991.98 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isLargeDesktop = useMediaQuery({ minWidth: 1200 });

    const [uniqueMenuNames, setUniqueMenuNames] = useState([]);
    const [activeTab, setActiveTab] = useState('1');
    const [isLoading, setIsLoading] = useState(true);

    // Effect to fetch data when component mounts
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([
                dispatch(getUserLinks()),
                dispatch(getUserQuickRoutes()),
                dispatch(getNavigation()),
                dispatch(getLatestNews())
            ]);
            setIsLoading(false);
        };

        fetchData();
    }, [dispatch]);

    // Effect to process navigation data
    useEffect(() => {
        if (navigation) {
            const uniqueNames = [...new Set(navigation.map((item) => item.menu_name))];
            setUniqueMenuNames(uniqueNames);
        }
    }, [navigation]);

    const menuIcons = {
        Incident: {
            icon: <FaExclamationTriangle/>,
            color: "#ffc107",
            ethicalNote: "Report incidents promptly and accurately"
        },
        Inform: {
            icon: <FaInfoCircle/>,
            color: "#0dcaf0",
            ethicalNote: "Share information transparently"
        },
        Competency: {
            icon: <FaUserGraduate/>,
            color: "#f39c12",
            ethicalNote: "Maintain professional standards"
        },
        "Incident Reporting": {
            icon: <SlipperyIcon/>,
            color: "#FFBF00",
            ethicalNote: "Prioritize health and safety"
        },
        "Safety Communication": {
            icon: <FaInfoCircle/>,
            color: "#0dcaf0",
            ethicalNote: "Clear safety communication saves lives"
        },
        "Learning & Development": {
            icon: <FaUserGraduate/>,
            color: "#f39c12",
            ethicalNote: "Continuous improvement matters"
        },
        "System Admin": {
            icon: <FaUserShield/>,
            color: "#198754",
            ethicalNote: "Protect system integrity"
        },
        Project: {
            icon: <FaProjectDiagram/>,
            color: "#0d6efd",
            ethicalNote: "Deliver projects responsibly"
        },
        "Permit To Work": {
            icon: <FaHardHat/>,
            color: "#e67e22",
            ethicalNote: "Follow all required safety protocols"
        },
        Risk: {
            icon: <FaShieldAlt/>,
            color: "#9b59b6",
            ethicalNote: "Assess risks thoroughly"
        },
        "Filing Cabinet": {
            icon: <FcFilingCabinet/>,
            color: "#6c757d",
            ethicalNote: "Maintain data privacy"
        },
        Metrics: {
            icon: <FaChartBar/>,
            color: "#198754",
            ethicalNote: "Report metrics honestly"
        },
        Accessibility: {
            icon: <FaAccessibleIcon/>,
            color: "#0d6efd",
            ethicalNote: "Ensure equal access for all"
        }
    };

    const menuItems = uniqueMenuNames.map((name) => ({
        name: name,
        ...menuIcons[name] || {icon: <FaQuestion/>, color: "#95a5a6", ethicalNote: "Ask when uncertain"},
    }));

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const getGridTemplateColumns = () => {
        if (isMobile && !isMobileTiny) return 'repeat(2, 1fr)';
        if (isTablet) return 'repeat(3, 1fr)';
        if (isDesktop && !isLargeDesktop) return 'repeat(4, 1fr)';
        return 'repeat(4, 1fr)';
    };

    return (
        <DashboardWrapper>
            <div className="page-content">
                <Container fluid className={isMobile ? "px-2" : isTablet ? "px-3" : "px-4"}>
                    {isLoading ? (
                        <DashboardSkeleton />
                    ) : (
                        <>
                            <Card className="dashboard-card mb-4">
                                <CardBody className="card-padding">
                                    <h2 className="card-title mb-4">Welcome to M-SPHERE</h2>

                                    {isMobileTiny ? (
                                        <div className="mobile-menu-container">
                                            <MobileMenuList menuItems={menuItems}/>
                                        </div>
                                    ) : (
                                        <nav
                                            className="menu-container"
                                            aria-label="Main navigation"
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns: getGridTemplateColumns(),
                                                gap: isMobile ? '1rem' : '1.5rem',
                                                width: '100%'
                                            }}
                                        >
                                            {menuItems.map((item, index) => (
                                                <Link
                                                    key={index}
                                                    to={`/${item.name.toLowerCase().replace(/\s+/g, "-")}`}
                                                    className="menu-item focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    aria-label={`${item.name === "Metrics" ? "Dashboard" : item.name} section${item.ethicalNote ? ` - ${item.ethicalNote}` : ''}`}
                                                    style={{
                                                        background: 'var(--card-bg, #ffffff)',
                                                        borderRadius: '0.75rem',
                                                        transition: 'transform 200ms ease-out, box-shadow 200ms ease-out, border-color 200ms ease-out',
                                                        textDecoration: 'none',
                                                        color: 'var(--text-primary, #212529)',
                                                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.05)',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        textAlign: 'center',
                                                        padding: isMobile ? '1rem 0.75rem' : '1.5rem 1rem',
                                                        minHeight: isMobile ? '120px' : isTablet ? '140px' : '160px',
                                                        position: 'relative',
                                                        overflow: 'hidden',
                                                        border: '1px solid var(--border-color, #e9ecef)',
                                                        willChange: 'transform, box-shadow'
                                                    }}
                                                    onMouseOver={(e) => {
                                                        const icon = e.currentTarget.querySelector('.icon');
                                                        const text = e.currentTarget.querySelector('.text');
                                                        const indicator = e.currentTarget.querySelector('.hover-indicator');

                                                        e.currentTarget.style.transform = 'translateY(-4px)';
                                                        e.currentTarget.style.boxShadow = `0 8px 20px ${item.color}33, 0 6px 12px rgba(0, 0, 0, 0.08)`;
                                                        e.currentTarget.style.borderColor = item.color;

                                                        if (icon) {
                                                            icon.style.transform = 'translateY(-4px) scale(1.05)';
                                                            icon.style.filter = `drop-shadow(0 2px 8px ${item.color}80)`;
                                                        }

                                                        if (text) {
                                                            text.style.color = item.color;
                                                        }

                                                        if (indicator) {
                                                            indicator.style.opacity = '1';
                                                        }
                                                    }}
                                                    onMouseOut={(e) => {
                                                        const icon = e.currentTarget.querySelector('.icon');
                                                        const text = e.currentTarget.querySelector('.text');
                                                        const indicator = e.currentTarget.querySelector('.hover-indicator');

                                                        e.currentTarget.style.transform = '';
                                                        e.currentTarget.style.boxShadow = '0 2px 6px rgba(0, 0, 0, 0.05)';
                                                        e.currentTarget.style.borderColor = 'var(--border-color, #e9ecef)';

                                                        if (icon) {
                                                            icon.style.transform = '';
                                                            icon.style.filter = '';
                                                        }

                                                        if (text) {
                                                            text.style.color = '';
                                                        }

                                                        if (indicator) {
                                                            indicator.style.opacity = '0';
                                                        }
                                                    }}
                                                    onFocus={(e) => {
                                                        const text = e.currentTarget.querySelector('.text');
                                                        e.currentTarget.style.boxShadow = '0 0 0 3px rgba(13, 110, 253, 0.25)';
                                                        e.currentTarget.style.borderColor = 'var(--primary, #0d6efd)';
                                                        if (text) text.style.color = item.color;
                                                    }}
                                                    onBlur={(e) => {
                                                        const text = e.currentTarget.querySelector('.text');
                                                        e.currentTarget.style.boxShadow = '0 2px 6px rgba(0, 0, 0, 0.05)';
                                                        e.currentTarget.style.borderColor = 'var(--border-color, #e9ecef)';
                                                        if (text) text.style.color = '';
                                                    }}
                                                >
                                                    <div className="icon-wrapper" aria-hidden="true">
                                                        <div
                                                            className="icon"
                                                            style={{
                                                                color: item.color,
                                                                fontSize: isMobile ? '32px' : '42px',
                                                                marginBottom: isMobile ? '0.5rem' : '1rem',
                                                                transition: 'transform 200ms ease-out, filter 200ms ease-out',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            {item.icon}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="text"
                                                        style={{
                                                            fontSize: isMobile ? '0.875rem' : '1rem',
                                                            fontWeight: 600,
                                                            transition: 'color 150ms ease-out',
                                                            lineHeight: 1.4
                                                        }}
                                                    >
                                                        {item.name === "Metrics" ? "Dashboard" : item.name}
                                                    </div>
                                                    <div
                                                        className="hover-indicator"
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '0',
                                                            left: '50%',
                                                            transform: 'translateX(-50%)',
                                                            width: isMobile ? '20px' : '30px',
                                                            height: '3px',
                                                            background: item.color,
                                                            borderRadius: '3px 3px 0 0',
                                                            opacity: '0',
                                                            transition: 'opacity 150ms ease-out',
                                                        }}
                                                        aria-hidden="true"
                                                    />
                                                </Link>
                                            ))}
                                        </nav>
                                    )}
                                </CardBody>
                            </Card>

                            <Row className={isMobile ? "g-2" : "g-3"}>
                                <Col xl={8} lg={7} md={12} className={isMobile ? "mb-3" : "mb-4"}>
                                    <Card className="dashboard-card mb-3">
                                        <CardBody className="card-padding">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <h3 className="card-title mb-0">Latest News & Updates</h3>
                                            </div>
                                            <CustomNewsCarousel news={news} />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl={4} lg={5} md={12}>
                                    <Card className="dashboard-card">
                                        <CardBody className="card-padding">
                                            <h3 className="card-title mb-3">Quick Access</h3>
                                            <div className="nav-tabs mb-3">
                                                <a
                                                    className={classnames("nav-link", {active: activeTab === '1'})}
                                                    onClick={() => { toggle('1'); }}
                                                    role="tab"
                                                    aria-selected={activeTab === '1'}
                                                    aria-controls="forms-panel"
                                                >
                                                    My Forms
                                                </a>
                                                <a
                                                    className={classnames("nav-link", {active: activeTab === '2'})}
                                                    onClick={() => { toggle('2'); }}
                                                    role="tab"
                                                    aria-selected={activeTab === '2'}
                                                    aria-controls="links-panel"
                                                >
                                                    External Links
                                                </a>
                                            </div>
                                            <div className="tab-content">
                                                <div
                                                    className={classnames({active: activeTab === '1', 'd-none': activeTab !== '1'})}
                                                    role="tabpanel"
                                                    id="forms-panel"
                                                    aria-labelledby="forms-tab"
                                                >
                                                    <CommonlyUsedForms modules={modules} navigationData={navigation}/>
                                                </div>
                                                <div
                                                    className={classnames({active: activeTab === '2', 'd-none': activeTab !== '2'})}
                                                    role="tabpanel"
                                                    id="links-panel"
                                                    aria-labelledby="links-tab"
                                                >
                                                    {quickLinks && quickLinks.length > 0 ? (
                                                        <div
                                                            className="list-group"
                                                            style={{
                                                                maxHeight: isMobile ? '200px' : isTablet ? '250px' : '300px',
                                                                overflowY: 'auto',
                                                                overflowX: 'hidden'
                                                            }}
                                                            aria-label="External links"
                                                        >
                                                            {quickLinks.map((link) => (
                                                                <div key={link.id} className="list-group-item">
                                                                    <a href={link.link} target="_blank" rel="noopener noreferrer" className="item-text">
                                                                        <div className="item-icon">
                                                                            <FaExternalLinkAlt />
                                                                        </div>
                                                                        {link.name}
                                                                        <FaExternalLinkAlt className="external-icon" aria-hidden="true" />
                                                                    </a>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <div className="text-center py-4 text-muted">
                                                            <p>No external links available</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    )}
                </Container>
            </div>
        </DashboardWrapper>
    );
};

export default Dashboard;