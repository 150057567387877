import {ADD_PROJECT_CLIENT,GET_PROJECT_CLIENT, GET_PROJECT_CLIENTS, UPDATE_PROJECT_CLIENT,GET_PROJECT_CLIENT_VALUES} from "../../types";


const initialState = {list: {}, single: null,values:[]};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_PROJECT_CLIENTS:
            return {...state, list: payload};
        case GET_PROJECT_CLIENT_VALUES:
            return {...state, values: payload};
        case GET_PROJECT_CLIENT:
            return { ...state, single: payload || initialState.single };
        case ADD_PROJECT_CLIENT:
            return {...state, list: payload};
        case UPDATE_PROJECT_CLIENT:
            return {...state, list: payload};
        default:
            return state;
    }
};

export default reducer;
