import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import AppRoutes from "./Routes/index";
// Import Scss
import './assets/scss/theme.scss';
// Import Components
import Login from "./Pages/Authentication/Login";
import Register from "./Pages/Authentication/Register";
import ResetPassword from "./Pages/Authentication/ResetPassowrd";
import VerifyCertificate from "./Pages/VerifyCertificate";
import VerifyEmail from "./Pages/VerifyEmail";
import MaintenancePage from "./Pages/MaintenancePage";
import { NavigationProvider } from "./Navigation";
import {ProSidebarProvider} from "react-pro-sidebar";
import Learning from "./Pages/Competancy/Learning";

// Exempt paths that should be accessible during maintenance
const EXEMPT_PATHS = ['/verify-certificate', '/verify'];


// Wrapper component to handle maintenance mode
const MaintenanceWrapper = ({ children }) => {
    const [maintenanceMode, setMaintenanceMode] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const checkMaintenanceStatus = async () => {
            try {
                // Add timestamp to prevent caching
                const response = await fetch(`/maintenance.json?t=${new Date().getTime()}`);
                const data = await response.json();
                setMaintenanceMode(data.maintenance_mode === 1);
            } catch (error) {
                console.error('Error checking maintenance status:', error);
                setMaintenanceMode(false);
            } finally {
                setIsLoading(false);
            }
        };

        // Check initially
        checkMaintenanceStatus();

        // Check every minute
        const interval = setInterval(checkMaintenanceStatus, 10000);
        return () => clearInterval(interval);
    }, []);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    // If in maintenance mode and not on exempt path, show maintenance page
    if (maintenanceMode && !EXEMPT_PATHS.includes(location.pathname)) {
        return <MaintenancePage />;
    }

    return children;
};

function App() {
    return (
        <React.Fragment>
            <MaintenanceWrapper>
                <ProSidebarProvider >
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/verify-certificate" element={<VerifyCertificate />} />
                        <Route path="/verify" element={<VerifyEmail />} />
                        <Route path="/learn/:courseKey" element={<Learning />} />
                        <Route
                            path="/*"
                            element={
                                <NavigationProvider>
                                    <AppRoutes />
                                </NavigationProvider>
                            }
                        />
                    </Routes>
                </ProSidebarProvider>
            </MaintenanceWrapper>
        </React.Fragment>
    );
}

export default App;