import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import {
    Row, Col, Card, Form,
    FormGroup, Label, Input, Button, Alert
} from "reactstrap";
import { authLogin, sendVerificationEmail, resetPassword } from "../../store/actions/authentication";
import withRouter from "../../components/Common/withRouter";
import whitelog from "../../assets/images/whitelog.png";
import { showWarningDialog } from "../../utils/dialogs";
import { login } from "../../utils/auth";
import Swal from 'sweetalert2';
import {FaMailBulk} from "react-icons/fa";
import {MdEmail} from "react-icons/md";


const Login = (props) => {
    document.title = "Login | Meinhardt EPCM M-SPHERE";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { control, handleSubmit, formState: { errors }, getValues } = useForm();
    const { error } = useSelector(state => state.login);

    const showEmailVerificationDialog = (message, data) => {
        Swal.fire({
            title: 'Email Verification Required',
            text: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Send Verification Link',
            cancelButtonText: 'OK'
        }).then((result) => {
            if (result.isConfirmed) {

                sendVerificationLink(data.email);
            }
        });
    };

    const sendVerificationLink = async (email) => {
        // Implement the logic to send verification link
        const object = {
            email: email
        }

        const result = await dispatch(sendVerificationEmail(object))
        if (result.data.code === 1) {
            Swal.fire(
                'Verification Link Sent',
                'Please check your email for the verification link.',
                'success'
            );
        }
        else {
            showWarningDialog(result.data.message)
        }

        // You might want to call an API endpoint here
    };

    const onFormSubmit = async (data) => {
        try {
            const res = await dispatch(authLogin(data));
            if (res.data.code === 1) {
                const user = {
                    access_token: res.data.access_token,
                    refresh_token: res.data.refresh_token,
                };
                login(user);
                //dispatch({ type: AUTH_LOGIN, payload: user });
                navigate("/dashboard");
            } else if (res.data.code === 3) {
                showEmailVerificationDialog(res.data.message, data);
            } else {
                showWarningDialog(res.data.message);
            }
        } catch (error) {
            showWarningDialog('An error occurred during login. Please try again.');
        }
    };

    const handleForgotPassword = (e) => {
        e.preventDefault(); // Prevent default navigation
        Swal.fire({
            title: 'Reset Password',
            input: 'email',
            inputLabel: 'Your email address',
            inputPlaceholder: 'Enter your email address',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Reset Password',
            cancelButtonText: 'Cancel',
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to enter your email!';
                }
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                const email = result.value;
                try {
                    const resetPasswordResult = await dispatch(resetPassword({ email }));
                    
                    if (resetPasswordResult.data.code === 1) {
                        Swal.fire(
                            'Password Reset Initiated',
                            'Check your email for further instructions.',
                            'success'
                        );
                    } else {
                        showWarningDialog(resetPasswordResult.data.message);
                    }
                } catch (error) {
                    showWarningDialog('An error occurred. Please try again.');
                }
            }
        });
    };


    const pageStyle = {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f8f9fa',
    };

    const contentStyle = {
        flex: '1 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
    };

    const cardStyle = {
        width: '100%',
        maxWidth: '1000px',
        boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
        border: 'none',
        borderRadius: '0.5rem',
        overflow: 'hidden',
    };

    const leftColumnStyle = {
        backgroundColor: '#013e7d',
        color: '#fff',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    };

    const rightColumnStyle = {
        backgroundColor: '#fff',
        padding: '2rem',
    };

    const imageStyle = {
        maxWidth: '100%',
        height: 'auto',
        marginBottom: '2rem',
    };

    const footerStyle = {
        backgroundColor: '#f8f9fa',
        padding: '0.5rem 0',
        textAlign: 'center',
        borderTop: '1px solid #dee2e6',
    };

    const supportStyle = {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        fontSize: '0.875rem',
        color: '#6c757d',
        backgroundColor: '#f8f9fa',
        padding: '0.5rem 0.75rem',
        borderRadius: '4px',
    };



    return (
        <div style={pageStyle}>
            <div style={contentStyle}>
                <Card style={cardStyle}>
                    <Row className="g-0">
                        <Col md={5} style={leftColumnStyle}>
                            <img src={whitelog} alt="HSSE Illustration" style={imageStyle} />
                            <h1 style={{ marginBottom: '0.9rem', fontSize: '1.5rem', color: 'whitesmoke' }}>Welcome to M-SPHERE</h1>
                            <p style={{ fontSize: '0.7rem', marginBottom: '1rem', maxWidth: '80%' }}>
                                Manage your HSSE operations efficiently and effectively with our comprehensive platform.
                            </p>
                        </Col>
                        <Col md={7} style={rightColumnStyle}>
                            <div style={{textAlign: 'center', marginBottom: '2rem'}}>
                                <h4 style={{
                                    fontSize: '1.5rem',
                                    color: '#013e7d',
                                    marginBottom: '1.5rem',
                                    textAlign: 'center'
                                }}>M-SPHERE | HSE SYSTEM</h4>
                                {/*<Link to="/">*/}
                                {/*    <img src={logodark} alt="" height="30" style={{display: 'inline-block'}}/>*/}
                                {/*</Link>*/}
                            </div>
                            <h4 style={{
                                fontSize: '1.5rem',
                                color: '#343a40',
                                marginBottom: '1.5rem',
                                textAlign: 'center'
                            }}>Sign in to continue</h4>
                            <Form onSubmit={handleSubmit(onFormSubmit)}>
                                {error && <Alert color="danger">{error}</Alert>}
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address"
                                            }
                                        }}
                                        render={({field}) => (
                                            <Input
                                                {...field}
                                                type="email"
                                                placeholder="Enter Email"
                                                className="form-control-lg"
                                            />
                                        )}
                                    />
                                    {errors.email && <span
                                        style={{color: '#dc3545', fontSize: '0.875rem'}}>{errors.email.message}</span>}
                                </FormGroup>
                                <FormGroup className="mb-4">
                                    <Label for="password">Password</Label>
                                    <Controller
                                        name="password"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "Password is required",
                                            minLength: {
                                                value: 8,
                                                message: "Password must be at least 8 characters long"
                                            }
                                        }}
                                        render={({field}) => (
                                            <Input
                                                {...field}
                                                type="password"
                                                placeholder="Enter Password"
                                                className="form-control-lg"
                                            />
                                        )}
                                    />
                                    {errors.password && <span style={{
                                        color: '#dc3545',
                                        fontSize: '0.875rem'
                                    }}>{errors.password.message}</span>}
                                </FormGroup>
                                <div className="mb-3">
                                    <Button color="primary" size="lg" block type="submit">
                                        Log In
                                    </Button>
                                </div>
                                <div style={{textAlign: 'right', marginTop: '1rem'}}>
                                    <p style={{color: '#6c757d', margin: 0}}>
                                        <Link style={{fontWeight: 500, color: '#013e7d'}}
                                              onClick={handleForgotPassword}>
                                            Forgot Password?
                                        </Link>
                                    </p>
                                </div>
                            </Form>
                            <div style={{textAlign: 'center', marginTop: '1.5rem'}}>
                                <p style={{color: '#6c757d', margin: 0}}>
                                    Don't have an account?{" "}
                                    <Link to="/register" style={{fontWeight: 500, color: '#013e7d'}}>
                                        Register
                                    </Link>
                                </p>
                            </div>
                        </Col>

                    </Row>
                </Card>
            </div>
            <footer style={footerStyle}>
                <p style={{margin: 0, fontSize: '0.875rem', color: '#013e7d'}}>
                    © {new Date().getFullYear()} HSSE. Crafted {" "}
                    <i className="mdi mdi-heart" style={{color: '#dc3545', margin: '0 0.2rem'}}></i> by M-SPHERE
                </p>
                <p style={{color: '#013e7d', textDecoration: 'none', fontSize: '0.875rem'}}>
                    <MdEmail/> hsse.support@meinhardtepcm.com
                </p>
            </footer>
        </div>
    );
};

export default withRouter(Login);