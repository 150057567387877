import {ADD_RHPW, GENERATE_RHPW, GET_SINGLE_RHPW, GET_USER_RHPW, RHPW_VALUES, UPDATE_RHPW,DELETE_RHPW} from "../../../types";

const initialState = { list: [], single: null, userRHPW: {},values:[],loading:true};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENERATE_RHPW:
            return {
                ...state,
                userRHPW: payload
            };
        case RHPW_VALUES:
            return { ...state, values: payload };
        case GET_USER_RHPW:
            return { ...state, userRHPW: payload ,loading:false};
        case GET_SINGLE_RHPW:
            return { ...state, single: payload };
        case ADD_RHPW:
            return { ...state, userRHPW: payload };
        case UPDATE_RHPW:
            return {
                ...state,
                userRHPW: payload
            };
        case DELETE_RHPW:
                return {
                    ...state,
                    userRHPW: state.userRHPW.user.filter(object => object.id !== payload)
                };
        default:
            return state;
    }
};

export default reducer;