import {
    GENERATE_FAUDIT,
    FAUDIT_VALUES,
    GET_USER_FAUDIT,
    GET_SINGLE_FAUDIT,
    ADD_FAUDIT,
    UPDATE_FAUDIT,
    DELETE_FAUDIT
} from "../../../types";

const initialState = {list: [], single: null, userFAUDIT: {}, values: [], loading: true};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GENERATE_FAUDIT:
            return {
                ...state,
                userFAUDIT: payload
            };
        case FAUDIT_VALUES:
            return {...state, values: payload};
        case GET_USER_FAUDIT:
            return {...state, userFAUDIT: payload, loading: false};
        case GET_SINGLE_FAUDIT:
            return {...state, single: payload};
        case ADD_FAUDIT:
            return {...state, userFAUDIT: payload};
        case UPDATE_FAUDIT:
            return {
                ...state,
                userFAUDIT: payload
            };
        case DELETE_FAUDIT:
            return {
                ...state,
                userFAUDIT: state.userFAUDIT.user.filter(object => object.id !== payload)
            };
        default:
            return state;
    }
};

export default reducer;