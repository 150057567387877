import {
    COUNTRY_DASHBOARD,
    GLOBAL_DASHBOARD,
    GOALZERO_DASHBOARD,
    HSEQ_DASHBOARD,
    PERMIT_DASHBOARD,
} from "../../types";

const initialState = {global:[],country:[],permitDashboard:[],hseqDashboard:[],goalZeroDashboard:[]};


const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GLOBAL_DASHBOARD:
            return {...state, global:payload};
        case GOALZERO_DASHBOARD:
            return {...state, goalZeroDashboard:payload};
        case COUNTRY_DASHBOARD:
            return {...state, country:payload};
        case PERMIT_DASHBOARD:
                return {...state, permitDashboard:payload};
        case HSEQ_DASHBOARD:
            return {...state, hseqDashboard:payload};

        default:
            return state;
    }
};

export default reducer;