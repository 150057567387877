import axios from "../../axios";
import {setAlert} from "./alert";
import {
    GET_USER_BINDING,
    GET_USERS_ACTIVE,
    GET_USER_BINDINGS,
    GET_USER_MENU,
    GET_USERS, SAVE_USER_BINDINGS,
    USER_OTP_VERIFY_FAIL,
    USER_OTP_VERIFY_SUCCESS, GET_USER_VALUES,
    GET_USER_LINKS, GET_USER_MODULES, ADD_USER_LINKS, UPDATE_USER_LINKS, ADD_USER_MODULES, UPDATE_USER_MODULES,
    GET_USER_PROFILE, GET_USER_FEEDBACK, GET_UNREGISTERED_PROFILE
} from "../types";
import {message} from "antd";


export const registerUser = (payload) => async (dispatch) => {
    try {
        const res = await axios.post("user/signup", payload);
        if (res.data.code === 1) {
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message || "Registration failed"};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {success: false, message: err.message};
    }
};
export const giveUserFeedback = (payload) => async (dispatch) => {
    try {
        const res = await axios.post("feedback/add", payload);
        if (res.data.code === 1) {
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message || "Registration failed"};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {success: false, message: err.message};
    }
};
export const updateUser = (payload) => async (dispatch) => {
    try {
        const res = await axios.post("user/update", payload);
        if (res.data.code === 1) {
            
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message || "Registration failed"};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {success: false, message: err.message};
    }
};
export const updateUserStatus = (payload) => async (dispatch) => {
    try {
        const res = await axios.post("user/status", payload);
        if (res.data.code === 1) {
            return {success: true, message: res.data.message};
        } else {
            return {success: false, message: res.data.message || "Update Status Failed"};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
        return {success: false, message: err.message};
    }
};
export const sendVerificationEmail = (email) => async (dispatch) => {
    try {
        const response = await axios.post('user/send-verification', { email });
        
        if (response.data.success) {
            return { success: true };
        } else {
            return { success: false, message: response.data.message };
        }
    } catch (error) {
        console.error('Error sending verification email:', error);
        return { success: false, message: 'An error occurred while sending the verification email' };
    }
};
export const verifyUserEmail= (token) => async (dispatch) => {
    try {
        const response = await axios.get(`user/verify?token=${token}`);
        if (response.data.code===1) {
            return { success: true };
        } else {
            return { success: false, message: response.data.message };
        }
    } catch (error) {
        console.error('Error sending verification email:', error);
        return { success: false, message: 'An error occurred while sending the verification email' };
    }
};
export const verifyOtp = (email, otp) => async (dispatch) => {
    try {
        const res = await axios.post("/api/user/verify-otp", {email, otp});
        dispatch({
            type: USER_OTP_VERIFY_SUCCESS,
            payload: res.data,
        });
        return {success: true};
    } catch (error) {
        dispatch({
            type: USER_OTP_VERIFY_FAIL,
            payload: error.response.data.message,
        });
        return {success: false, message: error.response.data.message};
    }
}
export const getUserModules = () => async (dispatch) => {
    try {
        const res = await axios.get("quick/module/user");
        if (res.data.code === 1) {
            dispatch({type: GET_USER_MODULES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const getUsers = () => async (dispatch) => {
    try {
        const res = await axios.get("user/active");
        if (res.data.code === 1) {
            dispatch({type: GET_USERS, payload: res.data.data.users});
            return {success: true,data: res.data.data.access};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const getAllUser = () => async (dispatch) => {
    try {
        const res = await axios.get("user/all");
        if (res.data.code === 1) {
            console.log("USER RESPONSE")
            console.log(res.data.data);
            dispatch({type: GET_USERS, payload: res.data.data.users});
            return {success: true,data: res.data.data.access};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const getUserValues = () => async (dispatch) => {
    try {
        const res = await axios.get("user/values");
        if (res.data.code === 1) {
            dispatch({type: GET_USER_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const uploadProfilePicture=(formData)=>async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("user/profile/update", formData, config);
        if (res.data.code === 1) {
            return {success: true};
        }
        return {success:false}
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const getActiveUsers = () => async (dispatch) => {
    try {
        const res = await axios.get("user/active");
        if (res.data.code === 1) {
            dispatch({type: GET_USERS_ACTIVE, payload: res.data.data.users ?? []});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getUserMenu = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`permission/user/menu/`,object);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_MENU, payload: res.data.data});
            return {success: true,data: res.data.data};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const verifyEmail = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`user/verify?token=${token}`);
        if (response.data.success) {
            return { success: true };
        } else {
            return { success: false, message: response.data.message };
        }
    } catch (error) {
        console.error('Error verifying email:', error);
        return { success: false, message: 'An error occurred during email verification' };
    }
};
export const getUsersBindings = () => async (dispatch) => {
    try {
        const res = await axios.get("user/binding/values");
        if (res.data.code === 1) {
            dispatch({type: GET_USER_BINDINGS, payload: res.data.data});
            return {success: true,data:res.data.data};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const getSelectedUserBindings = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`user/binding/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_BINDING, payload: res.data.data.bindings});
            return {success: true,data: res.data.data};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const saveUserBindings = (formData) => async (dispatch) => {
    try {
        const res = await axios.post("user/binding/save",formData);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_BINDING, payload: res.data.data});
            message.success(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};


//QUICKS LINKS
export const getUserLinks = () => async (dispatch) => {
    try {
        const res = await axios.get("quick/link/user");
        if (res.data.code === 1) {
            dispatch({type: GET_USER_LINKS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const addUserLinks=(formData)=>async(dispatch)=>{
    try {
        const res = await axios.post("quick/link/save",formData);
        
        if (res.data.code === 1) {
            dispatch({type: GET_USER_LINKS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const updateUserLinks=(formData)=>async(dispatch)=>{
    try {
        const res = await axios.post("quick/link/update",formData);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_LINKS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const deleteUserLinks = (id) => async (dispatch) => {
    try {
        // console.log("INSIDE USER DELETE LINKS")
        const res = await axios.get(`quick/link/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_LINKS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

//QUICK ROUTES
export const getUserQuickRoutes = () => async (dispatch) => {
    try {
        const res = await axios.get("quick/module/user");
        if (res.data.code === 1) {
            dispatch({type: GET_USER_MODULES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const addUserQuickRoutes=(formData)=>async(dispatch)=>{
    try {
        const res = await axios.post("quick/module/save",formData);
        
        if (res.data.code === 1) {
            dispatch({type: GET_USER_MODULES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const updateUserQuickRoutes=(formData)=>async(dispatch)=>{
    try {
        const res = await axios.post("quick/module/update",formData);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_MODULES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const deleteUserQuickRoutes = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`quick/module/delete/${id}`);
        if (res.data.code === 1) {
            dispatch({type: GET_USER_MODULES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const getUserProfile = () => async (dispatch) => {
    try {
        const res = await axios.get("user/profile");
        if (res.data.code === 1) {
            dispatch({type: GET_USER_PROFILE, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const getUnRegisteredUserProfile = (url) => async (dispatch) => {
    try {
        const res = await axios.get(`course/enrolled/user/details/${url}`);
        if (res.data.code === 1) {
            dispatch({type: GET_UNREGISTERED_PROFILE, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const updateUnRegisteredUserProfile = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post(`course/enrolled/user/details/update`,formData,config);
        if (res.data.code === 1) {
            dispatch({type: GET_UNREGISTERED_PROFILE, payload: res.data.data});
            return {success: true, message: res.data.message};
        }
        return {success: false, message: "Contact to admin"};
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const addBulkUser=(formData)=>async(dispatch)=>{
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post("user/add/multiple",formData,config);
        if (res.data.code === 1) {
            return {success: true, message: res.data.message,data:res.data.data};
            //dispatch({type: GET_USER_PROFILE, payload: res.data.data});
        }
        else{
            return {success: false, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const verifyUserCourse=(formData)=>async(dispatch)=>{
    const res = await axios.post("course/check",formData);
    return res.data.data
}

//FEEDBACK
export const getUserFeedbacks=()=>async(dispatch)=>{
    try {
        const res = await axios.get("feedback/all");
        if (res.data.code === 1) {
            dispatch({type: GET_USER_FEEDBACK, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const updateUserPassword=(object)=>async(dispatch)=>{
    try {
        const res = await axios.post("user/password/update",object);
        console.log("response")
        console.log(res)
        if (res.data.code === 1) {
            return {success: true, message: res.data.message};
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}