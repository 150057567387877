import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Card, Checkbox, Col, Divider, Modal, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    getSysRolesModules,
    getSysRolesModulesByRoleId,
    getSysRolesValues,
    manageSysRoleModules
} from "../../../store/actions/sysroles";
import Select from 'react-select';
import { manageCURDAccess } from "../../../utils/functions";
import { showWarningDialog } from "../../../utils/dialogs";
import {generatePermissionsPDF} from "./view";

const { Title } = Typography;

const MemoizedCheckbox = React.memo(({ label, checked, onChange, style, disabled }) => (
    <Checkbox checked={checked} onChange={onChange} style={style} disabled={disabled}>
        {label}
    </Checkbox>
));



const SavePermissionButton = ({ sourceRole, destinationRole, handleSubmit, onSubmit }) => {
   const isDisabled = sourceRole && !destinationRole;

   return (
     <Button
       type="primary"
       onClick={handleSubmit(onSubmit)}
       size="large"
       disabled={isDisabled}
     >
       Save Permissions
     </Button>
   );
 };
const Roles = ({ access }) => {
    const accessRights = manageCURDAccess(access);
    document.title = "M-SPHERE | System Roles";

    const dispatch = useDispatch();
    const roles = useSelector((state) => state.roles.values.systemRoles);
    const modules = useSelector((state) => state.roles.modules);
    const roleModules = useSelector((state) => state.roles.specificModules);

    const { control, handleSubmit, formState: { errors }, setValue } = useForm();
    const [moduleStructure, setModuleStructure] = useState({});
    const [permissionsState, setPermissionsState] = useState({});
    const [currentRoleId, setCurrentRoleId] = useState(null);
    const [isExternalRole, setIsExternalRole] = useState(false);
    const [selectedRoleOption, setSelectedRoleOption] = useState(null);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [formData, setFormData] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        dispatch(getSysRolesValues(access.moduleId));
        dispatch(getSysRolesModules());
    }, [dispatch]);

    const handleRoleChange = useCallback((selectedRole) => {
        if (selectedRole?.value !== undefined) {
            setSelectedRoleOption(selectedRole);
            setCurrentRoleId(selectedRole.value);
            setIsSuperAdmin(selectedRole.label === "SUPER ADMIN");
            const isExternal = selectedRole.type === 'O' || selectedRole.type === 'D';
            setIsExternalRole(isExternal);
            dispatch(getSysRolesModulesByRoleId(selectedRole.value));
        } else {
            setIsExternalRole(false);
        }
    }, [dispatch]);

    const createModuleStructure = useCallback((modules) => {
        return modules.reduce((acc, module) => {
            if (!acc[module.menu]) acc[module.menu] = {};
            if (!acc[module.menu][module.submenu]) acc[module.menu][module.submenu] = [];
            acc[module.menu][module.submenu].push(module);
            return acc;
        }, {});
    }, []);

    useEffect(() => {
        if (modules.length > 0) {
            setModuleStructure(createModuleStructure(modules));
        }
    }, [modules, createModuleStructure]);

    const roleModulesToPermissionsState = useCallback((roleModules, allModules) => {
        const newState = {};
        allModules.forEach(module => {
            if (!newState[module.menu]) newState[module.menu] = { checked: false };
            if (!newState[module.menu][module.submenu]) newState[module.menu][module.submenu] = { checked: false };
            newState[module.menu][module.submenu][module.name] = {
                CREATE: false,
                READ: false,
                UPDATE: false,
                DELETE: false,
                'SHARED READ': false,
                'SHARED UPDATE': false,
                'SHARED DELETE': false
            };
        });

        roleModules.forEach(module => {
            if (newState[module.menu_name]?.[module.submenu_name]?.[module.module_name]) {
                newState[module.menu_name][module.submenu_name][module.module_name] = {
                    CREATE: module.role_json.C === 1,
                    READ: module.role_json.R === 1,
                    UPDATE: module.role_json.U === 1,
                    DELETE: module.role_json.D === 1,
                    'SHARED READ': module.role_json.SR === 1,
                    'SHARED UPDATE': module.role_json.SU === 1,
                    'SHARED DELETE': module.role_json.SD === 1
                };
            }
        });

        console.log("STATE SET")
        console.log(newState)
        return updateParentStates(newState);
    }, []);

    useEffect(() => {
        if (modules.length > 0) {
            console.log("ROLE SET")
            console.log(roleModules);
            console.log(modules);
            setPermissionsState(roleModulesToPermissionsState(roleModules, modules));
        }
    }, [roleModules, modules, roleModulesToPermissionsState]);

    const isDisabledItem = useCallback((menu, submenu, moduleName) => {
        const superAdminCase = isSuperAdmin &&
            menu === "System Admin" &&
            submenu === "Manage Roles" &&
            (moduleName === "System Role Access" || moduleName === "System Roles");

        if (isExternalRole && menu === "System Admin") {
            if (submenu === "User Management" && (moduleName === "Permission Access" || moduleName === "User")) {
                return false;
            }
            return true;
        }

        return superAdminCase;
    }, [isSuperAdmin, isExternalRole]);

    const updateParentStates = useCallback((state) => {
        const newState = { ...state };
        Object.keys(newState).forEach(menu => {
            let menuChecked = true;
            Object.keys(newState[menu]).forEach(submenu => {
                if (submenu !== 'checked') {
                    let submenuChecked = true;
                    Object.keys(newState[menu][submenu]).forEach(module => {
                        if (module !== 'checked') {
                            const moduleChecked = Object.values(newState[menu][submenu][module]).every(v => v);
                            if (!moduleChecked) submenuChecked = false;
                        }
                    });
                    newState[menu][submenu].checked = submenuChecked;
                    if (!submenuChecked) menuChecked = false;
                }
            });
            newState[menu].checked = menuChecked;
        });
        return newState;
    }, []);

    const isProtectedItem = useCallback((menu, submenu = null, moduleName = null) => {
        if (isExternalRole && menu === "System Admin") {
            return true;
        }

        if (isSuperAdmin &&
            menu === "System Admin" &&
            submenu === "Manage Roles" &&
            (moduleName === "System Role Access" || moduleName === "System Roles")) {
            return true;
        }

        return false;
    }, [isExternalRole, isSuperAdmin]);

    const handlePermissionChange = useCallback((type, menu, submenu, module, action, checked) => {
        setPermissionsState(prevState => {
            const newState = { ...prevState };
            const allPermissions = {
                CREATE: checked,
                READ: checked,
                UPDATE: checked,
                DELETE: checked,
                'SHARED READ': checked,
                'SHARED UPDATE': checked,
                'SHARED DELETE': checked
            };

            const updateAllChildren = (menuObj, currentMenu, currentSubmenu = null) => {
                Object.keys(menuObj).forEach(key => {
                    if (isProtectedItem(currentMenu, currentSubmenu, key)) {
                        return;
                    }

                    if (key === 'checked') {
                        menuObj[key] = checked;
                    } else if (typeof menuObj[key] === 'object') {
                        updateAllChildren(menuObj[key], currentMenu, key);
                    } else {
                        menuObj[key] = checked;
                    }
                });
            };

            switch (type) {
                case 'superParent':
                    Object.keys(newState).forEach(menuKey => {
                        if (!isProtectedItem(menuKey)) {
                            updateAllChildren(newState[menuKey], menuKey);
                        }
                    });
                    break;
                case 'menu':
                    updateAllChildren(newState[menu]);
                    break;
                case 'submenu':
                    updateAllChildren(newState[menu][submenu]);
                    break;
                case 'module':
                    if (!isDisabledItem(menu, submenu, module)) {
                        newState[menu][submenu][module] = { ...allPermissions };
                    }
                    break;
                case 'crud':
                    if (!isDisabledItem(menu, submenu, module)) {
                        newState[menu][submenu][module][action] = checked;
                    }
                    break;
            }
            return updateParentStates(newState);
        });
    }, [isProtectedItem, isDisabledItem, updateParentStates]);

    const renderCRUDCheckboxes = useCallback((menu, submenu, module) => (
        <div style={{ display: 'flex', gap: '20px' }}>
            {['CREATE', 'READ', 'UPDATE', 'DELETE', 'SHARED READ', 'SHARED UPDATE', 'SHARED DELETE'].map((action) => (
                <MemoizedCheckbox
                    key={action}
                    label={action}
                    style={{ marginRight: 0, fontSize: '12px' }}
                    checked={permissionsState[menu]?.[submenu]?.[module]?.[action] || false}
                    onChange={(e) => handlePermissionChange('crud', menu, submenu, module, action, e.target.checked)}
                />
            ))}
        </div>
    ), [permissionsState, handlePermissionChange]);

    const renderSubmenus = useCallback((menu, submenus) => (
        Object.entries(submenus).map(([submenu, modules]) => {
            const isSubmenuCheckboxDisabled = isExternalRole && menu === "System Admin";

            return (
                <div key={`${menu}-${submenu}`} style={{
                    marginLeft: '24px',
                    marginBottom: '16px',
                    opacity: 1
                }}>
                    <MemoizedCheckbox
                        label={submenu}
                        style={{ fontWeight: 500, marginBottom: '8px' }}
                        checked={permissionsState[menu]?.[submenu]?.checked || false}
                        onChange={(e) => {
                            if (!isSubmenuCheckboxDisabled) {
                                const newChecked = e.target.checked;
                                handlePermissionChange('submenu', menu, submenu, null, null, newChecked);
                                if (!newChecked) {
                                    modules.forEach(module => {
                                        if (!isDisabledItem(menu, submenu, module.name)) {
                                            handlePermissionChange('module', menu, submenu, module.name, null, false);
                                        }
                                    });
                                }
                            }
                        }}
                        disabled={isSubmenuCheckboxDisabled}
                    />
                    {modules.map(module => {
                        const isModuleDisabled = isDisabledItem(menu, submenu, module.name);

                        return (
                            <div key={module.id} style={{
                                marginLeft: '24px',
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '8px',
                                opacity: isModuleDisabled ? 0.5 : 1
                            }}>
                                <MemoizedCheckbox
                                    label={module.name}
                                    style={{ width: '200px', fontSize: '14px' }}
                                    checked={Object.values(permissionsState[menu]?.[submenu]?.[module.name] || {}).every(v => v)}
                                    onChange={(e) => handlePermissionChange('module', menu, submenu, module.name, null, e.target.checked)}
                                    disabled={isModuleDisabled}
                                />
                                {renderCRUDCheckboxes(menu, submenu, module.name)}
                            </div>
                        );
                    })}
                </div>
            );
        })
    ), [permissionsState, handlePermissionChange, renderCRUDCheckboxes, isDisabledItem, isExternalRole]);

    const generatePermissionsJSON = useCallback(() => {
        const permissions = [];
        Object.entries(permissionsState).forEach(([menu, submenus]) => {
            Object.entries(submenus).forEach(([submenu, modulePermissions]) => {
                Object.entries(modulePermissions).forEach(([moduleName, crud]) => {
                    if (moduleName !== 'checked') {
                        const moduleId = modules.find(m =>
                            m.menu === menu &&
                            m.submenu === submenu &&
                            m.name === moduleName
                        )?.id;

                        if (moduleId) {
                            const crudValues = {
                                C: crud.CREATE ? 1 : 0,
                                R: crud.READ ? 1 : 0,
                                U: crud.UPDATE ? 1 : 0,
                                D: crud.DELETE ? 1 : 0,
                                SR: crud['SHARED READ'] ? 1 : 0,
                                SU: crud['SHARED UPDATE'] ? 1 : 0,
                                SD: crud['SHARED DELETE'] ? 1 : 0
                            };

                            if (Object.values(crudValues).some(value => value === 1)) {
                                permissions.push({
                                    moduleId,
                                    ...crudValues
                                });
                            }
                        }
                    }
                });
            });
        });
        return permissions;
    }, [permissionsState, modules]);

    const resetAllPermissions = useCallback(() => {
        setPermissionsState(prevState => {
            const newState = { ...prevState };
            const getResetCrudState = () => ({
                CREATE: false,
                READ: false,
                UPDATE: false,
                DELETE: false,
                'SHARED READ': false,
                'SHARED UPDATE': false,
                'SHARED DELETE': false
            });

            Object.keys(newState).forEach(menu => {
                newState[menu] = { checked: false };
                Object.keys(moduleStructure[menu] || {}).forEach(submenu => {
                    if (!newState[menu][submenu]) {
                        newState[menu][submenu] = { checked: false };
                    }
                    moduleStructure[menu][submenu].forEach(module => {
                        newState[menu][submenu][module.name] = getResetCrudState();
                    });
                    newState[menu][submenu].checked = false;
                });
                newState[menu].checked = false;
            });
            return newState;
        });
    }, [moduleStructure]);

    useEffect(() => {
        if (currentRoleId === null) {
            resetAllPermissions();
            setIsExternalRole(false);
            setIsSuperAdmin(false);
        } else {
            dispatch(getSysRolesModulesByRoleId(currentRoleId));
        }
    }, [currentRoleId, resetAllPermissions, dispatch]);



    const handleConfirm = () => {
        setIsConfirmModalVisible(false);
        if (formData) {
            const permissionsJSON = generatePermissionsJSON();
            const payload = {
                sRoleId: currentRoleId,
                roles: JSON.stringify(permissionsJSON),
            };

            if(selectedRoleOption.isMe) {
                if(accessRights.C || accessRights.U || accessRights.D) {
                    dispatch(manageSysRoleModules(payload));
                } else {
                    showWarningDialog("You don't have rights to perform the operation", "Warning");
                }
            } else {
                if(accessRights.SU || accessRights.SD) {
                    dispatch(manageSysRoleModules(payload));
                } else {
                    showWarningDialog("You don't have rights to perform the operation", "Warning");
                }
            }
        }
    };

    const handleCancel = () => {
        setIsConfirmModalVisible(false);
        setFormData(null);
    };

    const onSubmit = useCallback((data) => {
        setFormData(data);
        setIsConfirmModalVisible(true);
    }, []);

    const memoizedModulePermissions = useMemo(() => (
        <div style={{ maxHeight: '600px', overflowY: 'auto', padding: '0 16px' }}>
            {Object.entries(moduleStructure).map(([menu, submenus]) => {
                const isMenuDisabled = isExternalRole && menu === "System Admin";

                return (
                    <div key={menu} style={{
                        marginBottom: '24px',
                        opacity: isMenuDisabled ? 0.5 : 1
                    }}>
                        <MemoizedCheckbox
                            label={menu}
                            style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '12px' }}
                            checked={permissionsState[menu]?.checked || false}
                            onChange={(e) => {
                                if (!isMenuDisabled) {
                                    handlePermissionChange('menu', menu, null, null, null, e.target.checked)
                                }
                            }}
                            disabled={isMenuDisabled}
                        />
                        {renderSubmenus(menu, submenus)}
                    </div>
                );
            })}
        </div>
    ), [moduleStructure, permissionsState, handlePermissionChange, renderSubmenus, isExternalRole]);

    const [sourceRole, setSourceRole] = useState(null);
    const [destinationRole, setDestinationRole] = useState(null);

    

    return (
        <>
            <div className="page-content" style={{ padding: '24px' }}>
                <Card style={{ boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row gutter={24}>
                            {/* System Role Card */}
                            <Col xs={24} md={12}>
                                <Card title="System Role">
                                    <Controller
                                        name="type"
                                        control={control}
                                        rules={{ required: 'Type is required' }}
                                        render={({ field }) => (
                                            <div>
                                                <Select
                                                    {...field}
                                                    options={(roles || []).map(role => ({
                                                        type: role.type,
                                                        value: role.id,
                                                        label: role.name,
                                                        isMe: role.isMe,
                                                    }))}
                                                    style={{ width: '100%' }}
                                                    dropdownStyle={{ maxHeight: 300 }}
                                                    menuPlacement="auto"
                                                    isSearchable={true}
                                                    placeholder="Select a role..."
                                                    onChange={(selectedOption) => {
                                                        field.onChange(selectedOption);
                                                        handleRoleChange(selectedOption);
                                                    }}
                                                />
                                                {errors.type && (
                                                    <Typography.Text type="danger" style={{ fontSize: '12px' }}>
                                                        {errors.type.message}
                                                    </Typography.Text>
                                                )}
                                            </div>
                                        )}
                                    />
                                </Card>
                            </Col>

                            {/* Transfer Role Card */}
                            <Col xs={24} md={12}>
                                <Card title="Transfer Role">
                                    {/* Source Role */}
                                    <div style={{marginBottom: 16}}>
                                        <label style={{display: 'block', marginBottom: '8px', fontWeight: 500}}>
                                            Source Role
                                        </label>
                                        <Select
                                            value={sourceRole}
                                            options={(roles || []).map(role => ({
                                                type: role.type,
                                                value: role.id,
                                                label: role.name,
                                                isMe: role.isMe,
                                            }))}
                                            style={{width: '100%'}}
                                            dropdownStyle={{maxHeight: 300}}
                                            placeholder="Select source role..."
                                            isClearable={true}
                                            onChange={(selectedOption) => {
                                                setSourceRole(selectedOption);
                                                if (selectedOption) {
                                                    dispatch(getSysRolesModulesByRoleId(selectedOption.value));
                                                } else {
                                                    // Clear destination role when source role is cleared
                                                    setDestinationRole(null);
                                                    setValue('type', null); // Using setValue instead of field.onChange
                                                }
                                            }}
                                        />
                                    </div>

                                    {/* Destination Role */}
                                    <Controller
                                        name="type"
                                        control={control}
                                        rules={{required: 'Destination role is required'}}
                                        render={({field}) => (
                                            <div>
                                                <label style={{display: 'block', marginBottom: '8px', fontWeight: 500}}>
                                                    Destination Role
                                                </label>
                                                <Select
                                                    {...field}
                                                    value={destinationRole}
                                                    options={(roles || []).map(role => ({
                                                        type: role.type,
                                                        value: role.id,
                                                        label: role.name,
                                                        isMe: role.isMe,
                                                    }))}
                                                    style={{width: '100%'}}
                                                    dropdownStyle={{maxHeight: 300}}
                                                    placeholder="Select destination role..."
                                                    onChange={(selectedOption) => {
                                                        setDestinationRole(selectedOption);
                                                        field.onChange(selectedOption);
                                                        if (!sourceRole) {
                                                            handleRoleChange(selectedOption);
                                                        }
                                                    }}
                                                />
                                                {errors.type && (
                                                    <Typography.Text type="danger" style={{fontSize: '12px'}}>
                                                        {errors.type.message}
                                                    </Typography.Text>
                                                )}
                                            </div>
                                        )}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Divider/>

                        <div style={{textAlign: 'right', marginBottom: '16px'}}>
                            <Button
                                style={{marginRight: '8px'}}
                                onClick={() => generatePermissionsPDF(selectedRoleOption, permissionsState, moduleStructure)}
                                size="large"
                            >
                                Generate PDF
                            </Button>
                            {/*<Button type="primary" onClick={handleSubmit(onSubmit)} size="large">*/}
                            {/*    Save Permissions*/}
                            {/*</Button>*/}
                            <SavePermissionButton
                                sourceRole={sourceRole}
                                destinationRole={destinationRole}
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmit}
                            />
                        </div>

                        <MemoizedCheckbox
                            label="Select All"
                            style={{fontWeight: 'bold', fontSize: '18px', marginBottom: '16px'}}
                            checked={Object.values(permissionsState).every(menu => menu.checked)}
                            onChange={(e) => handlePermissionChange('superParent', null, null, null, null, e.target.checked)}
                        />

                        <Title level={4} style={{marginBottom: '24px'}}>Module Permissions</Title>
                        {memoizedModulePermissions}
                        <Divider/>
                    </form>
                </Card>
            </div>

            <Modal
                title="Confirm Save Permissions"
                visible={isConfirmModalVisible}
                onOk={handleConfirm}
                onCancel={handleCancel}
                okText="Confirm"
                cancelText="Cancel"
                zIndex={9999}
            >
                <p>Are you sure you want to save the current permissions?</p>
            </Modal>
        </>
    );
};

export default React.memo(Roles);