import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { QRCodeSVG } from 'qrcode.react';
import { getUnRegisteredUserProfile, updateUnRegisteredUserProfile } from '../../../store/actions/user';
import logo from '../../../assets/images/logo-dark.png';
import whitelog from '../../../assets/images/certificate-mht.jpg';
import {showSuccessDialog} from "../../../utils/dialogs";

const UnRegisteredCertificate = ({ course }) => {
    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.users.unRegisteredUser);
    const [qrData, setQrData] = useState('');

    // State for the modal
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [nameError, setNameError] = useState('');
    const [imageError, setImageError] = useState('');

    useEffect(() => {
        dispatch(getUnRegisteredUserProfile(course.certId));
    }, [dispatch, course.certId]);

    useEffect(() => {
        if (userProfile) {
            setName(userProfile.name || '');
        }
    }, [userProfile]);

    useEffect(() => {
        const payload = {
            certId: course.certId,
        };
        const encodedData = encodeURIComponent(JSON.stringify(payload));
        const redirectUrl = `${window.location.origin}/verify-certificate?data=${encodedData}`;
        setQrData(redirectUrl);
    }, [course]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleImageChange = (e) => {
        setImageError('');

        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setProfileImage(file);

            // Create preview URL
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setProfileImage(null);
            setPreviewImage(null);
            setImageError('Profile image is required');
        }
    };

    const validateName = (value) => {
        // Check if name contains any numbers
        if (/\d/.test(value)) {
            setNameError('Name should not contain any numbers');
            return false;
        }

        // Check if name is empty
        if (!value.trim()) {
            setNameError('Name is required');
            return false;
        }

        setNameError('');
        return true;
    };

    const validateImage = () => {
        if (!profileImage) {
            setImageError('Profile image is required');
            return false;
        }

        setImageError('');
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Reset errors
        setNameError('');
        setImageError('');

        // Validate inputs
        const isNameValid = validateName(name);
        const isImageValid = validateImage();

        if (!isNameValid || !isImageValid) {
            return; // Stop form submission if validation fails
        }

        // Create FormData object
        const formData = new FormData();
        formData.append('name', name);
        formData.append('id', userProfile.id);
        formData.append('certId', course.certId);
        formData.append('profileImage', profileImage);

        // Dispatch update action with FormData
        const result = await dispatch(updateUnRegisteredUserProfile(formData))

        if(result.success) {
            showSuccessDialog("Profile Updated Successfully");
            setName('')
            setProfileImage(null)
            setModalOpen(false);
        }
    };

    const userProfileImage = userProfile?.fullPath;
    const showUpdateButton = userProfile && userProfile.confirmed === false;


    return (
        <Container>
            {/* Profile Update Modal */}
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Update Your Profile</ModalHeader>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="name">Name*</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    validateName(e.target.value);
                                }}
                                invalid={!!nameError}
                            />
                            {nameError && (
                                <div className="text-danger mt-1 small">{nameError}</div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="profileImage">Profile Image*</Label>
                            <Input
                                type="file"
                                name="profileImage"
                                id="profileImage"
                                accept="image/*"
                                onChange={handleImageChange}
                                invalid={!!imageError}
                                required
                            />
                            {imageError && (
                                <div className="text-danger mt-1 small">{imageError}</div>
                            )}
                            {previewImage && (
                                <div className="mt-3 text-center">
                                    <img
                                        src={previewImage}
                                        alt="Preview"
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '200px',
                                            objectFit: 'cover',
                                            borderRadius: '4px'
                                        }}
                                    />
                                </div>
                            )}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">Save Profile</Button>
                        <Button color="secondary" onClick={() => setModalOpen(false)}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>

            {/* Certificate Card */}
            <Card className="border-warning" style={{ maxWidth: '800px', margin: 'auto' }}>
                <CardHeader className="bg-white border-bottom-0">
                    <Row className="align-items-center">
                        <Col xs="4">
                            <img
                                src={logo}
                                alt="Company Logo"
                                style={{ height: '30px', width: 'auto' }}
                            />
                        </Col>
                        <Col xs="4" className="text-center">
                            <h1 className="display-5 py-4" style={{ color: '#013e7d', margin: 0, fontSize: '1.5rem' }}>
                                {course?.name}
                            </h1>
                        </Col>
                        <Col xs="4" className="text-end">
                            {qrData && (
                                <QRCodeSVG
                                    value={qrData}
                                    size={200}
                                    level="M"
                                    includeMargin={true}
                                    style={{ margin: '10px', backgroundColor: 'white' }}
                                />
                            )}
                        </Col>
                    </Row>
                </CardHeader>

                <CardBody>
                    <Row className="g-4">
                        <Col md={4}>
                            {userProfileImage ? (
                                <img
                                    src={userProfileImage}
                                    alt="Profile"
                                    className="img-fluid rounded"
                                    style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                                />
                            ) : (
                                <div
                                    className="d-flex align-items-center justify-content-center rounded"
                                    style={{ width: '200px', height: '200px', backgroundColor: '#f0f0f0' }}
                                >
                                    <svg width="80" height="80" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="50" cy="30" r="20" fill="#cccccc" />
                                        <rect x="25" y="60" width="50" height="20" rx="10" fill="#cccccc" />
                                    </svg>
                                </div>
                            )}

                            {/* Update Profile Button */}
                            {showUpdateButton && (
                                <Button
                                    color="primary"
                                    className="mt-3 w-100"
                                    onClick={() => setModalOpen(true)}
                                >
                                    Update Profile
                                </Button>
                            )}
                        </Col>
                        <Col md={8}>
                            <div className="mb-3">
                                <div className="text-muted">Name</div>
                                <div className="h5" style={{color: '#013e7d'}}>
                                    {userProfile?.name || 'Participant'}
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="text-muted">Company Name</div>
                                <div className="h5" style={{color: '#013e7d'}}>
                                    {userProfile?.organization || 'Organization'}
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="text-muted">Certificate ID</div>
                                <div className="h5" style={{color: '#013e7d'}}>
                                    {course?.certId || 'Certificate'}
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="text-muted">Expires on</div>
                                <div className="h5" style={{color: '#013e7d'}}>
                                    {(() => {
                                        const date = new Date(course.issuedAt);
                                        date.setFullYear(date.getFullYear() + 2);

                                        const options = {
                                            day: 'numeric',
                                            month: 'long',
                                            year: 'numeric'
                                        };

                                        return date.toLocaleDateString('en-US', options);
                                    })()}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4 align-items-center justify-content-end">
                        <Col xs="auto" className="text-end">
                            <img
                                src={whitelog}
                                alt="White Logo"
                                style={{height: '60px', width: 'auto', transform: 'scale(0.8)'}}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col>
                            <small>Issued on: {formatDate(new Date(course.issuedAt))}</small>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    );
};

export default UnRegisteredCertificate;