import axios from "../../axios";
import {GET_ROLE_MODULES, GET_ROLES, GET_ROLES_VALUES, GET_SPECIFIC_ROLE_MODULES} from "../types";
import {setAlert} from "./alert";
import {message} from "antd";

export const getSysRoles = (moduleId) => async (dispatch) => {
    try {
        const object={
            moduleId:parseInt(moduleId)
        }
        const res = await axios.post("/systemroles/user",object);
        console.log(res)

        if (res.data.code === 1) {
            dispatch({type: GET_ROLES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getSysRolesValues = (moduleId) => async (dispatch) => {
    try {

        const object={
            moduleId:parseInt(moduleId)
        }
        const res = await axios.post("/systemroles/values",object);
        if (res.data.code === 1) {
            dispatch({type: GET_ROLES_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getSysRolesModules = () => async (dispatch) => {
    try {
        const res = await axios.get("/systemroles/modules");


        if (res.data.code === 1) {
            console.log(res.data.data);
            dispatch({type: GET_ROLE_MODULES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getSysRolesModulesByRoleId = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/systemroles/roles/${id}`);
        if (res.data.code === 1) {
            console.log(res.data.data);
            dispatch({type: GET_SPECIFIC_ROLE_MODULES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const addSystemRole = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/systemroles/add`, formData);
        if (res.data.code === 1) {

            dispatch({type: GET_ROLES, payload: res.data.data});
            message.success(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}
export const updateSystemRole = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/systemroles/update`, formData);
        if (res.data.code === 1) {
            dispatch({type: GET_ROLES, payload: res.data.data});
            message.success(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const manageSysRoleModules = (formData) => async (dispatch) => {
    try {
        const res = await axios.post(`/systemroles/binding/save`, formData);
        
        if (res.data.code === 1) {
            dispatch({type: GET_SPECIFIC_ROLE_MODULES, payload: res.data.data});
            message.success(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
}

export const deleteSystemRole = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`/systemroles/delete/`,object);
        if (res.data.code === 1) {
            message.success(res.data.message);
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};