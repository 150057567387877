import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Row, Col, Card, Form, FormGroup, Label, Input, Button, Alert } from "reactstrap";
import { showInfoDialog, showWarningDialog } from "../../utils/dialogs";
import withRouter from "../../components/Common/withRouter";
// import images
import whitelog from "../../assets/images/whitelog.png";
import logodark from "../../assets/images/logo-dark.png";
import { useDispatch } from "react-redux";
import { resetPassword, resetUserPassword } from "../../store/actions/authentication";
import Swal from "sweetalert2";

const ResetPassword = () => {
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    
  }, [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (!token) {
      setError("Invalid or missing token. Please try resetting your password again.");
    }
  }, [location]);

  const validateForm = () => {
    let formErrors = {};
    if (!password) {
      formErrors.password = "New password is required";
    } else if (password.length < 8) {
      formErrors.password = "Password must be at least 8 characters";
    }
    if (!confirmPassword) {
      formErrors.confirmPassword = "Please confirm your password";
    } else if (confirmPassword !== password) {
      formErrors.confirmPassword = "Passwords do not match";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
  
      if (token) {
        try {
          const object = {
            password: password,
            verificationToken: token
          }
  
          const result = await dispatch(resetUserPassword(object));
          //console.log(result)
          if (result.data.code === 1) {
            let countdown = 5;
            
            const countdownTimer = setInterval(() => {
              countdown--;
              if (countdown >= 0) {
                Swal.update({
                  html: `${result.data.message}<br><br>Redirecting to login page in ${countdown} seconds...`
                });
              } else {
                clearInterval(countdownTimer);
                Swal.close();
                navigate("/login");
              }
            }, 1000);
  
            Swal.fire({
              icon: 'success',
              title: 'Password Reset Successfully',
              html: `${result.data.message}<br><br>Redirecting to login page in 5 seconds...`,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 6000,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: result.data.message
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'warning',
            title: 'Warning',
            text: "Failed to reset password. Please try again."
          });
        }
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: "Invalid token. Please try resetting your password again."
        });
      }
    }
  };

  const pageStyle = {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f8f9fa',
  };

  const contentStyle = {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
  };

  const cardStyle = {
    width: '100%',
    maxWidth: '1000px',
    boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
    border: 'none',
    borderRadius: '0.5rem',
    overflow: 'hidden',
  };

  const leftColumnStyle = {
    backgroundColor: '#013e7d',
    color: '#fff',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  };

  const rightColumnStyle = {
    backgroundColor: '#fff',
    padding: '2rem',
  };

  const imageStyle = {
    maxWidth: '100%',
    height: 'auto',
    marginBottom: '2rem',
  };

  const footerStyle = {
    backgroundColor: '#f8f9fa',
    padding: '0.5rem 0',
    textAlign: 'center',
    borderTop: '1px solid #dee2e6',
  };

  

  return (
    <div style={pageStyle}>
      <div style={contentStyle}>
        <Card style={cardStyle}>
          <Row className="g-0">
            <Col md={5} style={leftColumnStyle}>
              <img src={whitelog} alt="HSSE Illustration" style={imageStyle} />
              <h1 style={{ marginBottom: '0.9rem', fontSize: '2rem', color: 'whitesmoke' }}>Reset Your Password</h1>
              <p style={{ fontSize: '0.7rem', marginBottom: '1rem', maxWidth: '80%' }}>
                Enter your new password to regain access to your account.
              </p>
            </Col>
            <Col md={7} style={rightColumnStyle}>
              <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                <Link to="/">
                  <img src={logodark} alt="" height="30" style={{ display: 'inline-block' }} />
                </Link>
              </div>
              <h4 style={{
                fontSize: '1.5rem',
                color: '#343a40',
                marginBottom: '1.5rem',
                textAlign: 'center'
              }}>Reset Your Password</h4>
              {error && (
                <Alert color="danger" style={{ marginTop: "13px" }}>
                  {error}
                </Alert>
              )}
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="password">New Password</Label>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    className="form-control-lg"
                    placeholder="Enter new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors.password && <span style={{ color: '#dc3545', fontSize: '0.875rem' }}>{errors.password}</span>}
                </FormGroup>
                <FormGroup className="mb-4">
                  <Label for="confirmPassword">Confirm New Password</Label>
                  <Input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    className="form-control-lg"
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {errors.confirmPassword && <span style={{ color: '#dc3545', fontSize: '0.875rem' }}>{errors.confirmPassword}</span>}
                </FormGroup>
                <div className="mb-3">
                  <Button color="primary" size="lg" block type="submit">
                    Reset Password
                  </Button>
                </div>
              </Form>
              <div style={{ textAlign: 'center', marginTop: '1.5rem' }}>
                <p style={{ color: '#6c757d', margin: 0 }}>
                  Remember your password?{" "}
                  <Link to="/login" style={{ fontWeight: 500, color: '#013e7d' }}>
                    Login here
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
      <footer style={footerStyle}>
        <p style={{ margin: 0, fontSize: '0.875rem' }}>
          © {new Date().getFullYear()} HSSE. Crafted with{" "}
          <i className="mdi mdi-heart" style={{ color: '#dc3545' }}></i> by M-SPHERE
        </p>
      </footer>
    </div>
  );
};

export default ResetPassword;