import React, {useState, useMemo, useRef, useEffect} from 'react';
import {Table, Input, Button, Row, Col, Card, CardBody, ButtonGroup} from 'reactstrap';
import {CSVLink} from "react-csv";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import {DownloadOutlined, FileExcelOutlined, FilePdfOutlined} from "@ant-design/icons";
import {AutoSizer} from 'react-virtualized';

const commonKeys = {
    "location": "Location",
    "detectedAt": "Detected At",
    "identifiedOther": "Identified Other",
    "actionRequired": "Action Required",
    "createdAt": "Created At",
    "updatedAt": "Updated At",
    "submittedAt": "Submitted At",
    "status": "Status",
    "sequenceNo": "Sequence Number",
    "l3Contractor": "L3 Contractor",
    "site": "Site",
    "project": "Project",
    "city": "City",
    "state": "State",
    "country": "Country",
    "businessUnit": "Business Unit",
    "actions": "Actions",
    "costs": "Costs",
    "files": "Files",
    "createdByUser": "Created By User",
    "updatedByUser": "Updated By User",
    "responsibleManager": "Responsible Manager",
    "closeoutManager": "Closeout Manager"
}
const uniqueKeys = {
    "hazardData": {
        "details": "Details",
        "immediateAction": "Immediate Action",
        "isImprovement": "Is Improvement",
        "factors": "Factors",
        "actualHazards": "Actual Hazards",
        "supervisor": "Supervisor"
    },
    "incidentReport": {
        "dhsIncident": "DHS Incident",
        "description": "Description",
        "wri": "WRI",
        "rni": "RNI",
        "ci": "CI",
        "ni": "NI",
        "secondaryHazards": "Secondary Hazards",
        "aCategory": "Actual Category",
        "pCategory": "Potential Category",
        "involved": "Involved",
        "witnesses": "Witnesses"
    },
    "nonConformity": {
        "ncAP": "NC AP",
        "summary": "Summary",
        "detail": "Detail",
        "contractors": "Contractors",
        "suppliers": "Suppliers",
        "logs": "Logs"
    },
    "improvementOpportunity": {
        "improvementcategory": "Improvement Category"
    },
    "complaints": {
        "significance": "Significance",
        "receiveType": "Receive Type",
        "issuedAt": "Issued At",
        "clientName": "Client Name",
        "complainantName": "Complainant Name",
        "address1": "Address 1",
        "address2": "Address 2",
        "suburb": "Suburb",
        "postalCode": "Postal Code",
        "contact1": "Contact 1",
        "contact2": "Contact 2",
        "source": "Source",
        "receivedByUser": "Received By User"
    }
}

const ReactStrapDataTable = ({dataSource, columns, title, rowKey = "id",exportCommon=true,exportFunction=null}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'ascending'});
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const formatDataForExport = () => {
        // Get all keys from the first data item
        const firstItem = filteredAndSortedData[0] || {};
        const dataKeys = Object.keys(firstItem);

        // Check if data matches any predefined structure
        let matchedType = null;
        let matchingKeys = {};

        // Check which type has the most matching keys
        Object.entries(uniqueKeys).forEach(([type, typeKeys]) => {
            const matchCount = Object.keys(typeKeys).filter(key =>
                dataKeys.includes(key)).length;

            if (matchCount > 0) {
                // Consider it a match if it has at least one unique key
                matchedType = type;
                matchingKeys = { ...commonKeys, ...typeKeys };
            }
        });

        if (matchedType && exportCommon) {
            return filteredAndSortedData.map(item => {
                const rowData = {};
                Object.entries(matchingKeys).forEach(([key, title]) => {
                    if (key !== 'files') {
                        let value;
                        if (Array.isArray(item[key])) {
                            value = item[key].filter(v => typeof v === 'string').join(', ');
                        } else if (typeof item[key] === 'boolean') {
                            value = item[key] ? 'Yes' : 'No';
                        } else {
                            value = item[key] || '';
                        }
                        rowData[title] = value;
                    }
                });
                return rowData;
            });
        } else {
            // Use columns structure with improved handling
            return filteredAndSortedData.map(item => {
                const rowData = {};
                columns.forEach(col => {
                    if (col.dataIndex !== 'files' && col.key !== 'files') {
                        if (col.render) {
                            // Handle custom renders
                            rowData[col.title] = col.render(item[col.dataIndex], item);
                        } else if (typeof item[col.dataIndex] === 'boolean') {
                            // Handle boolean values
                            rowData[col.title] = item[col.dataIndex] ? 'Yes' : 'No';
                        } else if (Array.isArray(item[col.dataIndex])) {
                            // Handle arrays
                            rowData[col.title] = item[col.dataIndex]
                                .filter(v => typeof v === 'string')
                                .join(', ');
                        } else {
                            // Handle regular values
                            rowData[col.title] = item[col.dataIndex] || item[col.key] || '';
                        }
                    }
                });
                return rowData;
            });
        }
    };
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };
    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };


    const filteredAndSortedData = useMemo(() => {
        if (!dataSource) return [];

        let processedData = [...dataSource];

        if (searchTerm && searchTerm.trim()) {
            const normalizedSearchTerm = searchTerm.trim().toLowerCase();

            processedData = processedData.filter(item => {
                // Only search through the columns that are specified in the columns prop
                return columns.some(column => {
                    const value = item[column.dataIndex || column.key];

                    if (value === null || value === undefined) {
                        return false;
                    }

                    if (typeof value === 'string') {
                        return value.toLowerCase().includes(normalizedSearchTerm);
                    }
                    if (typeof value === 'number') {
                        return value.toString().includes(normalizedSearchTerm);
                    }
                    if (typeof value === 'boolean') {
                        return value.toString().toLowerCase().includes(normalizedSearchTerm);
                    }
                    return false;
                });
            });
        }

        // Sort implementation
        if (sortConfig.key) {
            processedData.sort((a, b) => {
                const aValue = a[sortConfig.key];
                const bValue = b[sortConfig.key];

                // Handle null/undefined values in sorting
                if (aValue === null || aValue === undefined) return sortConfig.direction === 'ascending' ? -1 : 1;
                if (bValue === null || bValue === undefined) return sortConfig.direction === 'ascending' ? 1 : -1;

                // Handle different types of values
                if (typeof aValue === 'string') {
                    return sortConfig.direction === 'ascending'
                        ? aValue.localeCompare(bValue)
                        : bValue.localeCompare(aValue);
                }

                // Handle numeric and other types
                if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
                if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
                return 0;
            });
        }

        return processedData;
    }, [dataSource, searchTerm, sortConfig, columns]);

    const TooltipCell = ({ content, maxLines = 2 }) => {
        const [isHovered, setIsHovered] = useState(false);
        const contentRef = useRef(null);
        const [isOverflowing, setIsOverflowing] = useState(false);

        useEffect(() => {
            if (contentRef.current) {
                const element = contentRef.current;
                setIsOverflowing(element.scrollHeight > element.clientHeight);
            }
        }, [content]);

        return (
            <div
                className="position-relative"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div
                    ref={contentRef}
                    style={{
                        maxHeight: !isHovered ? `${maxLines * 1.5}em` : 'none',
                        overflow: 'hidden',
                        wordBreak: 'break-word',
                        transition: 'max-height 0.3s ease-in-out',
                        position: 'relative'
                    }}
                >
                    {content}
                    {isOverflowing && !isHovered && (
                        <span>"..."</span>
                    )}
                </div>

            </div>
        );
    };

    const pageCount = Math.ceil(filteredAndSortedData.length / itemsPerPage);
    const paginatedData = useMemo(() => {
        if (itemsPerPage === -1) return filteredAndSortedData;
        return filteredAndSortedData.slice(
            (currentPage - 1) * itemsPerPage,
            currentPage * itemsPerPage
        );
    }, [filteredAndSortedData, currentPage, itemsPerPage]);


    const paginationInfo = itemsPerPage === -1
        ? `Showing all ${filteredAndSortedData.length} entries`
        : `Showing ${(currentPage - 1) * itemsPerPage + 1} to ${Math.min(currentPage * itemsPerPage, filteredAndSortedData.length)} of ${filteredAndSortedData.length} entries`;

    const renderPaginationButtons = () => {
        const totalPages = pageCount;
        const maxButtons = 5; // Show max 5 numbered buttons
        let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
        let endPage = Math.min(totalPages, startPage + maxButtons - 1);

        // Adjust start page if we're near the end
        if (endPage - startPage + 1 < maxButtons) {
            startPage = Math.max(1, endPage - maxButtons + 1);
        }

        const pages = [];

        // Add first page and ellipsis if needed
        if (startPage > 1) {
            pages.push(
                <Button key={1} size="sm" color="secondary" onClick={() => setCurrentPage(1)} className="mx-1">
                    1
                </Button>
            );
            if (startPage > 2) {
                pages.push(<span key="ellipsis1" className="mx-2">...</span>);
            }
        }

        // Add numbered pages
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <Button
                    key={i}
                    size="sm"
                    color={currentPage === i ? 'primary' : 'secondary'}
                    onClick={() => setCurrentPage(i)}
                    className="mx-1"
                >
                    {i}
                </Button>
            );
        }

        // Add last page and ellipsis if needed
        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(<span key="ellipsis2" className="mx-2">...</span>);
            }
            pages.push(
                <Button
                    key={totalPages}
                    size="sm"
                    color="secondary"
                    onClick={() => setCurrentPage(totalPages)}
                    className="mx-1"
                >
                    {totalPages}
                </Button>
            );
        }

        return (
            <div className="d-flex align-items-center">
                <Button
                    size="sm"
                    color="secondary"
                    onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                    disabled={currentPage === 1}
                    className="me-2"
                >
                    Previous
                </Button>
                {pages}
                <Button
                    size="sm"
                    color="secondary"
                    onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                    disabled={currentPage === totalPages}
                    className="ms-2"
                >
                    Next
                </Button>
            </div>
        );
    };

    const exportToPDF = () => {
        // Get all keys from the first data item
        const firstItem = filteredAndSortedData[0] || {};
        const dataKeys = Object.keys(firstItem);

        // Check if data matches any predefined structure
        let matchedType = null;
        let matchingKeys = {};

        // Check which type has the most matching keys
        Object.entries(uniqueKeys).forEach(([type, typeKeys]) => {
            const matchCount = Object.keys(typeKeys).filter(key =>
                dataKeys.includes(key)).length;

            if (matchCount > 0) {
                // Consider it a match if it has at least one unique key
                matchedType = type;
                matchingKeys = { ...commonKeys, ...typeKeys };
            }
        });

        let pdfData;
        let headers;

        if (matchedType && exportCommon) {
            // Use predefined structure
            headers = Object.values(matchingKeys).filter(title => title !== 'Files');
            pdfData = filteredAndSortedData.map(item => {
                const rowData = [];
                Object.entries(matchingKeys).forEach(([key, title]) => {
                    if (key !== 'files') {
                        let value;
                        if (Array.isArray(item[key])) {
                            value = item[key].filter(v => typeof v === 'string').join(', ');
                        } else if (typeof item[key] === 'boolean') {
                            value = item[key] ? 'Yes' : 'No';
                        } else {
                            value = item[key] || '';
                        }
                        rowData.push(value);
                    }
                });
                return rowData;
            });
        } else {
            // Use columns structure with improved handling
            headers = columns
                .filter(col => col.dataIndex !== 'files' && col.key !== 'files')
                .map(col => col.title);

            pdfData = filteredAndSortedData.map(item => {
                return columns
                    .filter(col => col.dataIndex !== 'files' && col.key !== 'files')
                    .map(col => {
                        if (col.render) {
                            // Handle custom renders
                            return col.render(item[col.dataIndex], item);
                        } else if (typeof item[col.dataIndex] === 'boolean') {
                            // Handle boolean values
                            return item[col.dataIndex] ? 'Yes' : 'No';
                        } else if (Array.isArray(item[col.dataIndex])) {
                            // Handle arrays
                            return item[col.dataIndex]
                                .filter(v => typeof v === 'string')
                                .join(', ');
                        } else {
                            // Handle regular values
                            return item[col.dataIndex] || '';
                        }
                    });
            });
        }

        // Create PDF document
        const doc = new jsPDF({
            orientation: 'landscape'
        });

        // Add title if type is matched
        if (matchedType) {
            doc.setFontSize(16);
            doc.text(matchedType.toUpperCase(), 14, 15);
            doc.setFontSize(12); // Reset font size
        }

        // Configure table options
        const tableConfig = {
            head: [headers],
            body: pdfData,
            startY: matchedType ? 25 : 15,
            headStyles: {
                fillColor: [66, 66, 66],
                textColor: 255,
                fontStyle: 'bold'
            },
            theme: 'grid',
            styles: {
                fontSize: 10,
                cellPadding: 3,
                overflow: 'linebreak'
            },
            columnStyles: {
                // Add specific column styles if needed
                // index: { cellWidth: 30 }
            }
        };

        // Generate table
        doc.autoTable(tableConfig);

        // Generate filename
        const currentDate = new Date().toISOString().split('T')[0];
        const filename = `${matchedType ? matchedType.toUpperCase() + '_' : ''}${currentDate}.pdf`;

        // Save the PDF
        doc.save(filename);
    };

    const exportToExcel = () => {
        // Get all keys from the first data item

        if (exportFunction) {
            exportFunction(filteredAndSortedData);
            return;
        }

        const firstItem = filteredAndSortedData[0] || {};
        const dataKeys = Object.keys(firstItem);

        // Check if data matches any predefined structure
        let matchedType = null;
        let matchingKeys = {};

        // Check which type has the most matching keys
        Object.entries(uniqueKeys).forEach(([type, typeKeys]) => {
            const matchCount = Object.keys(typeKeys).filter(key =>
                dataKeys.includes(key)).length;

            if (matchCount > 0) {
                // Consider it a match if it has at least one unique key
                matchedType = type;
                matchingKeys = { ...commonKeys, ...typeKeys };
            }
        });

        let excelData;
        if (matchedType && exportCommon) {
            // Use predefined structure
            excelData = filteredAndSortedData.map(item => {
                const rowData = {};
                Object.entries(matchingKeys).forEach(([key, title]) => {
                    if (key !== 'files') {
                        const value = Array.isArray(item[key])
                            ? item[key].filter(v => typeof v === 'string').join(', ')
                            : item[key];
                        rowData[title] = value || '';
                    }
                });
                return rowData;
            });
        } else {
            // Use columns structure with improved handling
            excelData = filteredAndSortedData.map(item => {
                const row = {};
                columns.forEach(col => {
                    if (col.dataIndex !== 'files' && col.key !== 'files') {
                        if (col.render) {
                            // Handle custom renders
                            row[col.title] = col.render(item[col.dataIndex], item);
                        } else if (typeof item[col.dataIndex] === 'boolean') {
                            // Handle boolean values
                            row[col.title] = item[col.dataIndex] ? 'Yes' : 'No';
                        } else if (Array.isArray(item[col.dataIndex])) {
                            // Handle arrays
                            row[col.title] = item[col.dataIndex]
                                .filter(v => typeof v === 'string')
                                .join(', ');
                        } else {
                            // Handle regular values
                            row[col.title] = item[col.dataIndex] || '';
                        }
                    }
                });
                return row;
            });
        }

        // Create workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(excelData);

        // Set column widths based on content
        const headers = Object.keys(excelData[0] || {});
        const colWidths = headers.map(header => ({wch: Math.max(String(header).length, 15)}));
        ws['!cols'] = colWidths;

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Generate filename
        const currentDate = new Date().toISOString().split('T')[0];
        const filename = `${matchedType ? matchedType.toUpperCase() + '_' : ''}${currentDate}.xlsx`;

        // Create Excel file and trigger download
        XLSX.writeFile(wb, filename);
    };

    return (
        <Card>
            <CardBody>
                <Row className="mb-3">
                    <Col sm="4">
                        <Input
                            type="text"
                            className="form-control search"
                            placeholder="Search..."
                            onChange={handleSearch}
                        />
                    </Col>
                    <Col sm="4">
                        <Input
                            type="select"
                            id="recordsPerPage"
                            name="recordsPerPage"
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(Number(e.target.value))}
                        >
                            <option value="10">10 per page</option>
                            <option value="20">20 per page</option>
                            <option value="30">30 per page</option>
                            <option value="40">40 per page</option>
                            <option value="50">50 per page</option>
                            <option value="100">100 per page</option>
                            <option value="-1">All</option>
                        </Input>
                    </Col>
                    <Col sm="4" className="text-sm-end">
                        <ButtonGroup>
                            {/*<Button color="primary" onClick={exportToPDF}>*/}
                            {/*    <FilePdfOutlined/> PDF*/}
                            {/*</Button>*/}
                            {/*<CSVLink*/}
                            {/*    data={formatDataForExport()}*/}
                            {/*    filename={`${new Date().toISOString().split('T')[0]}.csv`}*/}
                            {/*    className="btn btn-primary"*/}
                            {/*>*/}
                            {/*    <DownloadOutlined/> CSV*/}
                            {/*</CSVLink>*/}
                            <Button color="primary" onClick={exportToExcel}>
                                <FileExcelOutlined/> Export Excel
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                <AutoSizer disableHeight>
                    {({width}) => (
                        <div style={{width, overflowX: 'auto', overflowY: 'auto', maxHeight: '500px'}}>
                            <Table responsive striped style={{width}}>
                                <thead>
                                <tr>
                                    {columns.map(column => (
                                        <th
                                            key={column.key}
                                            onClick={() => requestSort(column.dataIndex || column.key)}
                                            style={{
                                                cursor: 'pointer',
                                                position: 'sticky',
                                                top: 0,
                                                backgroundColor: 'white',
                                                zIndex: 1
                                            }}
                                        >
                                            {column.title}
                                            {sortConfig.key === (column.dataIndex || column.key) && (
                                                <span className="ms-1">
                                                        {sortConfig.direction === 'ascending' ? '▲' : '▼'}
                                                    </span>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {paginatedData.map((item) => (
                                    <tr key={item[rowKey]}>
                                        {columns.map(column => (
                                            <td key={column.key}>
                                                {column.showTooltip ? (
                                                    <TooltipCell
                                                        content={column.render
                                                            ? column.render(item[column.dataIndex || column.key], item)
                                                            : item[column.dataIndex || column.key]
                                                        }
                                                        maxLines={column.maxLines || 2}
                                                    />
                                                ) : (
                                                    column.render
                                                        ? column.render(item[column.dataIndex || column.key], item)
                                                        : item[column.dataIndex || column.key]
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </AutoSizer>

                {paginatedData.length === 0 && (
                    <div className="text-center my-3">
                        <h5>Sorry! No Result Found</h5>
                        {/*<p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any orders for your search.</p>*/}
                    </div>
                )}

                <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="text-muted">
                        {paginationInfo}
                    </div>
                    {renderPaginationButtons()}
                </div>
            </CardBody>
        </Card>
    );
};

export default ReactStrapDataTable;