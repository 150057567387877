import axios from "../../axios";
import {ADD_ORGANIZATION,GET_ACTIVE_ORGANIZATION, DELETE_SITE, GET_ORGANIZATION_VALUES, GET_ORGANIZATIONS, UPDATE_ORGANIZATION} from "../types";
import {setAlert} from "./alert";
import {message} from "antd";
import {showWarningDialog} from "../../utils/dialogs";


export const getOrganizations = () => async (dispatch) => {
    try {
        const res = await axios.post("organization/active");
        if (res.data.code === 1) {
            console.log("RESPONSE")
            console.log(res.data.data)
            dispatch({type: GET_ACTIVE_ORGANIZATION, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getAllOrganizations = (object) => async (dispatch) => {
    try {
        const res = await axios.post("organization/user",object);
        if (res.data.code === 1) {
            dispatch({type: GET_ORGANIZATIONS, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};

export const getOrganizationValues = () => async (dispatch) => {
    try {
        const res = await axios.get("organization/values");
        if (res.data.code === 1) {
            dispatch({type: GET_ORGANIZATION_VALUES, payload: res.data.data});
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};
export const addOrganization=(formData)=> async (dispatch) => {
    try {
        const res = await axios.post("organization/add",formData);
        if (res.data.code === 1) {
            dispatch({type: ADD_ORGANIZATION, payload: res.data.data});
            return
        }
        showWarningDialog(res.data.message, "Warning");
    } catch (err) {
        message.warning("CONTACT TO ADMIN");
        dispatch(setAlert(err.message, "danger"));
    }
}
export const updateOrganization=(formData)=> async (dispatch) => {
    try {
        const res = await axios.post("organization/update",formData);
        if (res.data.code === 1) {
            message.success(res.data.message);
            dispatch({type: UPDATE_ORGANIZATION, payload: res.data.data});
            return
        }
        showWarningDialog(res.data.message, "Warning");
    } catch (err) {
        message.warning("CONTACT TO ADMIN");
        dispatch(setAlert(err.message, "danger"));
    }
}

export const deleteOrganization = (object) => async (dispatch) => {
    try {
        const res = await axios.post(`organization/delete/`,object);
        if (res.data.code === 1) {
            message.success(res.data.message);
        } else {
            message.warning(res.data.message);
            dispatch(setAlert(res.data.message, "warning"));
        }
    } catch (err) {
        dispatch(setAlert(err.message, "danger"));
    }
};