import {GET_ROLE_MODULES, GET_ROLES, GET_ROLES_VALUES, GET_SPECIFIC_ROLE_MODULES} from "../types";

const initialState = {list: {}, modules: [], specificModules: [], single: null,values: {}};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_ROLES:
            return {...state, list: payload};
        case GET_ROLES_VALUES:
            return {...state, values: payload};
        case GET_ROLE_MODULES:
            return {...state, modules: payload};
        case GET_SPECIFIC_ROLE_MODULES:
            return {...state, specificModules: payload};


        default:
            return state;
    }
};

export default reducer;
