import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import {
    Row, Col, Card, Form,
    FormGroup, Label, Input, Button
} from "reactstrap";
import withRouter from "../../components/Common/withRouter";
import { getOrganizations } from "../../store/actions/organization";
import whitelog from "../../assets/images/whitelog.png";
import { registerUser } from "../../store/actions/user";
import { closeProgressDialog, showProgressDialog, showSuccessDialog } from "../../utils/dialogs";
import Select from "react-select";
import {MdEmail} from "react-icons/md";

const Register = (props) => {
    document.title = "Register | M-SPHERE";
    const dispatch = useDispatch();
    const organizations = useSelector((state) => state.organizations.activeOrganizations);

    const [formData, setFormData] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        organization: null,
        agreeTerms: false
    });

    const [errors, setErrors] = useState({});
    const [organizationOptions, setOrganizationOptions] = useState([]);

    useEffect(() => {
        dispatch(getOrganizations());
    }, [dispatch]);

    useEffect(() => {
        setOrganizationOptions(organizations.map(org => ({
            value: org.id,
            label: org.name
        })));
    }, [organizations]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleOrganizationChange = (selectedOption, actionMeta) => {
        if (actionMeta.action === "create-option") {
            setFormData(prevState => ({
                ...prevState,
                organization: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    isNew: true
                }
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                organization: selectedOption
            }));
        }
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.firstName) formErrors.firstName = "First Name is required";
        if (!formData.lastName) formErrors.lastName = "Last Name is required";
        if (!formData.email) formErrors.email = "Email is required";
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) 
            formErrors.email = "Invalid email address";
        if (!formData.password) formErrors.password = "Password is required";
        else if (formData.password.length < 8) 
            formErrors.password = "Password must be at least 8 characters long";
        if (formData.password !== formData.confirmPassword) 
            formErrors.confirmPassword = "Passwords do not match";
        if (!formData.organization) formErrors.organization = "Organization is required";
        if (!formData.agreeTerms) formErrors.agreeTerms = "You must agree to the terms";
        
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();
        //console.log("Form submit attempted");
        
        if (!validateForm()) {
            //console.log("Form validation failed");
            return;
        }

        //console.log("Form submitted with data:", formData);
        try {
            let payload = {
                fName: formData.firstName,
                mName: formData.middleName,
                lName: formData.lastName,
                email: formData.email,
                password: formData.password,
            }
    
            if (formData.organization?.value && !formData.organization.isNew) {
                payload.organizationId = formData.organization.value;
            } else {
                payload.newOrganization = formData.organization?.label || "";
            }
    
            if (formData.middleName === "" || formData.middleName === undefined || formData.middleName === null) {
                delete payload.mName;
            }

            showProgressDialog('Please wait','Registering User ....');
            const result = await dispatch(registerUser(payload));
            //console.log("Registration result:", result);
            closeProgressDialog();


            if (result.success) {
                dispatch(getOrganizations())
                showSuccessDialog(result.message)
                setFormData({
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                    organization: null,
                    agreeTerms: false
                });
            } else {
                //showWarningDialog(result.message || "Registration failed. Please try again.")
            }
        } catch (error) {
            console.error("Registration error:", error);
            //showWarningDialog("An error occurred during registration. Please try again.")
        }
    };

    const pageStyle = {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f8f9fa',
    };

    const contentStyle = {
        flex: '1 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
    };

    const cardStyle = {
        width: '100%',
        maxWidth: '1000px',
        boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
        border: 'none',
        borderRadius: '0.5rem',
        overflow: 'hidden',
    };

    const leftColumnStyle = {
        backgroundColor: '#013e7d',
        color: '#fff',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    };

    const rightColumnStyle = {
        backgroundColor: '#fff',
        padding: '2rem',
        maxHeight: '90vh',
        overflowY: 'auto',
    };

    const imageStyle = {
        maxWidth: '100%',
        height: 'auto',
        marginBottom: '2rem',
    };

    const footerStyle = {
        backgroundColor: '#f8f9fa',
        padding: '0.5rem 0',
        textAlign: 'center',
        borderTop: '1px solid #dee2e6',
    };

    return (
        <div style={pageStyle}>
            <div style={contentStyle}>
                <Card style={cardStyle}>
                    <Row className="g-0">
                        <Col md={5} style={leftColumnStyle}>
                            <img src={whitelog} alt="HSSE Illustration" style={imageStyle}/>
                            <h1 style={{marginBottom: '1rem', fontSize: '2rem', color: 'whitesmoke'}}>Join M-SPHERE</h1>
                            <p style={{fontSize: '1rem', marginBottom: '1rem', maxWidth: '80%'}}>
                                Register now to access our comprehensive HSSE management platform and enhance your
                                organization's safety practices.
                            </p>
                        </Col>
                        <Col md={7} style={rightColumnStyle}>
                            {/*<div style={{textAlign: 'center', marginBottom: '1rem'}}>*/}
                            {/*    <Link to="/">*/}
                            {/*        <img src={logodark} alt="" height="24" style={{display: 'inline-block'}}/>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                            <h2 style={{
                                fontSize: '1.5rem',
                                color: '#013e7d',
                                marginBottom: '1.5rem',
                                textAlign: 'center'
                            }}>M-SPHERE | HSE SYSTEM</h2>
                            <h3 style={{
                                fontSize: '1.5rem',
                                color: '#343a40',
                                marginBottom: '1rem',
                                textAlign: 'center'
                            }}>Register a new membership</h3>
                            <Form onSubmit={onFormSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>First Name</Label>
                                            <Input
                                                type="text"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleInputChange}
                                                placeholder="First Name"
                                            />
                                            {errors.firstName && <span style={{
                                                color: '#dc3545',
                                                fontSize: '0.75rem'
                                            }}>{errors.firstName}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Last Name</Label>
                                            <Input
                                                type="text"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleInputChange}
                                                placeholder="Last Name"
                                            />
                                            {errors.lastName && <span style={{
                                                color: '#dc3545',
                                                fontSize: '0.75rem'
                                            }}>{errors.lastName}</span>}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <Label>Middle Name (Optional)</Label>
                                    <Input
                                        type="text"
                                        name="middleName"
                                        value={formData.middleName}
                                        onChange={handleInputChange}
                                        placeholder="Middle Name"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Email</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder="Email"
                                    />
                                    {errors.email &&
                                        <span style={{color: '#dc3545', fontSize: '0.75rem'}}>{errors.email}</span>}
                                </FormGroup>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Password</Label>
                                            <Input
                                                type="password"
                                                name="password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                placeholder="Password"
                                            />
                                            {errors.password && <span style={{
                                                color: '#dc3545',
                                                fontSize: '0.75rem'
                                            }}>{errors.password}</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Confirm Password</Label>
                                            <Input
                                                type="password"
                                                name="confirmPassword"
                                                value={formData.confirmPassword}
                                                onChange={handleInputChange}
                                                placeholder="Confirm password"
                                            />
                                            {errors.confirmPassword && <span style={{
                                                color: '#dc3545',
                                                fontSize: '0.75rem'
                                            }}>{errors.confirmPassword}</span>}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    <Label>Organization</Label>
                                    <Select
                                        isClearable
                                        options={organizationOptions}
                                        value={formData.organization}
                                        onChange={handleOrganizationChange}
                                        placeholder="Select or type to create new"
                                        formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
                                    />
                                    {errors.organization && <span
                                        style={{color: '#dc3545', fontSize: '0.75rem'}}>{errors.organization}</span>}
                                </FormGroup>

                                <FormGroup className="mb-3">
                                    <div className="d-flex align-items-center">
                                        <Input
                                            type="checkbox"
                                            id="agreeTerms"
                                            name="agreeTerms"
                                            checked={formData.agreeTerms}
                                            onChange={handleInputChange}
                                            className="form-check-input me-2"
                                        />
                                        <Label check for="agreeTerms" className="mb-0" style={{fontSize: '0.875rem'}}>
                                            I agree to the <Link to="/terms">terms</Link>
                                        </Label>
                                    </div>
                                    {errors.agreeTerms && <span style={{
                                        color: '#dc3545',
                                        fontSize: '0.75rem',
                                        display: 'block',
                                        marginTop: '0.25rem'
                                    }}>{errors.agreeTerms}</span>}
                                </FormGroup>

                                <Button
                                    color="primary"
                                    block
                                    type="submit"
                                    style={{height: 'calc(2.5rem + 2px)', fontSize: '1rem'}}
                                >
                                    Register
                                </Button>
                            </Form>
                            <p style={{textAlign: 'center', marginTop: '1rem', color: '#6c757d', fontSize: '0.875rem'}}>
                                Already have an account? <Link to="/login"
                                                               style={{fontWeight: 500, color: '#013e7d'}}>Login</Link>
                            </p>
                        </Col>
                    </Row>
                </Card>
            </div>
            <footer style={footerStyle}>
                <p style={{margin: 0, fontSize: '0.875rem', color: '#6c757d'}}>
                    © {new Date().getFullYear()} HSSE. Crafted with{" "}
                    <i className="mdi mdi-heart" style={{color: '#dc3545', margin: '0 0.2rem'}}></i> by M-SPHERE
                </p>
                <p style={{color: '#013e7d', textDecoration: 'none', fontSize: '0.875rem'}}>
                   <MdEmail/>hsse.support@meinhardtepcm.com
                </p>
            </footer>
        </div>
    );
};

export default withRouter(Register);