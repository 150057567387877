import {
    GENERATE_SSUC,
    SSUC_VALUES,
    GET_USER_SSUC,
    GET_SINGLE_SSUC,
    ADD_SSUC,
    UPDATE_SSUC,
} from "../../../types";

const initialState = {list: [], single: null, userSSUC: [], values: [], loading: true};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GENERATE_SSUC:
            return {
                ...state,
                userSSUC: payload
            };
        case SSUC_VALUES:
            return {...state, values: payload};
        case GET_USER_SSUC:
            return {...state, userSSUC: payload, loading: false};
        case GET_SINGLE_SSUC:
            return {...state, single: payload};
        case ADD_SSUC:
            return {...state, userSSUC: payload};
        case UPDATE_SSUC:
            return {
                ...state,
                userSSUC: payload
            };
        default:
            return state;
    }
};

export default reducer;