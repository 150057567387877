import {
    ADD_ORGANIZATION,
    GET_ACTIVE_ORGANIZATION,
    GET_ORGANIZATION_VALUES,
    GET_ORGANIZATIONS,
    UPDATE_ORGANIZATION
} from "../types";


const initialState = {list: {}, single: null,values:[],activeOrganizations:[]};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_ACTIVE_ORGANIZATION:
            return {...state, activeOrganizations: payload};
        case GET_ORGANIZATIONS:
            return {...state, list: payload};
        case GET_ORGANIZATION_VALUES:
            return {...state, values: payload};
        case ADD_ORGANIZATION:
            return {...state, list: payload};
        case UPDATE_ORGANIZATION:
            return {...state, list: payload};
        default:
            return state;
    }
};

export default reducer;
