import config from "./config";
import axios from "axios";
import { message } from "antd";



const baseURL = config.API_URL;

const publicUrls = [
    "user/forgot-password",
    "user/login",
    "user/signup",
    "user/send-verification",
    "user/verify",
    "organization/active",
    "user/reset-password",
    "course/check",
    "/^user\\/verify(\\?token=.+)?$/",
    "/^learn\\/[A-Z0-9-]+$/",
    "/^course\\/enrolled\\/user\\/details\\/[A-Z0-9-]+$/"
];

const axiosInstance = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json",
    },
});


axiosInstance.interceptors.request.use(
    (config) => {
        if (publicUrls.includes(config.url)) {
            if (/^user\/verify/.test(config.url)) {
                const match = config.url.match(/^user\/verify\/(.+)$/);
                if (match) {
                    const token = match[1];
                    config.headers["authorization"] = `Bearer ${token}`;
                } else {
                    config.headers["authorization"] = "Bearer essential";
                }
            } else {
                config.headers["authorization"] = "Bearer essential";
            }
        } else if (["refresh/access"].includes(config.url)) {
            const refreshToken=JSON.parse(localStorage.getItem('auth0'))?.refresh_token;
            config.headers["authorization"] = `Bearer ${refreshToken}`;
        } else {
            const accessToken = JSON.parse(localStorage.getItem('auth0'))?.access_token;
            if (accessToken) {
                config.headers['authorization'] = `Bearer ${accessToken}`;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        console.log(error)

        const originalRequest = error.config;

        // Create a function to handle error messages
        const handleErrorMessage = (msg) => {
            setTimeout(() => {
                //showWarningDialog(msg)
                message.error(msg);
            }, 0);
        };

        if (error.response) {
            switch (error.response.status) {
                case 400:
                    // Handle 400 Bad Request
                    const errorMessage = error.response.data.message || error.response.data || "Bad Request: The server could not understand the request";
                    handleErrorMessage(errorMessage);
                    break;
                case 401:
                    // Handle 401 Unauthorized
                    localStorage.removeItem("auth");
                    localStorage.removeItem("auth0");
                    handleErrorMessage("Session expired. Please log in again.");
                    window.location.href = '/login';
                    break;
                default:
                    // Handle other errors
                    handleErrorMessage(error.response.data || "An error occurred");
            }
        } else if (error.request) {
            // The request was made but no response was received
            handleErrorMessage("No response received from server");
        } else {
            // Something happened in setting up the request that triggered an Error
            handleErrorMessage("Error setting up the request");
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;