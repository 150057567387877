import React, {lazy, startTransition, Suspense, useEffect, useState} from 'react';
import {Alert, Card, CardBody, CardHeader, CardTitle, Spinner} from "reactstrap";
import { FiAlertTriangle } from 'react-icons/fi';
import { trim } from "lodash";


// Loading Component
const LoadingSpinner = () => {
    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="text-center">
                <Spinner color="primary" size="lg">
                    Loading...
                </Spinner>
                <p className="mt-2 text-gray-600">Please wait</p>
            </div>
        </div>
    );
};

// Lazy loaded components grouped by feature
const incidentComponents = {
    'hazard': lazy(() => import('../../Pages/Incident/Hazard')),
    'incident/report': lazy(() => import('../../Pages/Incident/IncidentReport')),
    'nonconformity': lazy(() => import('../../Pages/Incident/NonConformity')),
    'improvement-opportunity': lazy(() => import('../../Pages/Incident/ImprovementOppourtionity')),
    'complaint': lazy(() => import('../../Pages/Incident/Complaint/Complaint')),
    'list': lazy(() => import('../../Pages/Incident/Action')),
    'status': lazy(() => import('../../Pages/Incident/IncidentGlobalModule')),
};

const adminComponents = {
    'systemroles/access': lazy(() => import('../../Pages/System-admin/Roles')),
    'permission/access': lazy(() => import('../../Pages/System-admin/SystemRoles/PermissionAccess')),
    'systemroles': lazy(() => import('../../Pages/System-admin/SystemRoles/SystemRoles')),
    'manage': lazy(() => import('../../Pages/System-admin/Master')),
    'user/access': lazy(() => import('../../Pages/System-admin/SystemRoles/UserRolesAccess')),
    'user': lazy(() => import('../../Pages/System-admin/User')),
    'user/group': lazy(() => import('../../Pages/System-admin/UserGroup')),
    'cabinet': lazy(() => import('../../Pages/System-admin/FileCabinet')),
    'templates': lazy(() => import('../../Pages/System-admin/Template')),
    'register': lazy(() => import('../../Pages/System-admin/Risk')),
    'feedback': lazy(() => import('../../Pages/System-admin/Feedback')),
    'news/my': lazy(() => import('../../Pages/System-admin/News')),
};

const informComponents = {
    'gsp': lazy(() => import('../../Pages/Inform/Audits/GoSeeProgram')),
    'faudit': lazy(() => import('../../Pages/Inform/Audits/HSSEAudit')),
    'iaudit': lazy(() => import('../../Pages/Inform/Audits/HSSEIndustrialAudit')),
    'tbt': lazy(() => import('../../Pages/Inform/Audits/ToolBoxTalk')),
    'tnt': lazy(() => import('../../Pages/Inform/Audits/ToolNotTick')),
    'ssc': lazy(() => import('../../Pages/Inform/Audits/SiteStartUpCheckList')),
    'rhpw': lazy(() => import('../../Pages/Inform/HSSEReports/ProjectWeekly')),
    'rpmw': lazy(() => import('../../Pages/Inform/HSSEReports/L2PMWeeklyReporting')),
    'ldo': lazy(() => import('../../Pages/Inform/Audits/LDO1')),
    'lopc': lazy(() => import('../../Pages/Inform/Audits/LOPCAssurance')),
    'inform/list': lazy(() => import('../../Pages/Inform/Action')),
    'inform/status': lazy(() => import('../../Pages/Inform/InformGlobalModule')),
    'inform/qc': lazy(() => import('../../Pages/Inform/ProjectMonitoring/QualityCheck')),
    'ncr': lazy(() => import('../../Pages/Inform/ProjectMonitoring/NCR')),
    'incr': lazy(() => import('../../Pages/Inform/ProjectMonitoring/INCR')),
};

const businessComponents = {
    'business/group': lazy(() => import('../../Pages/System-admin/Business/BusinessGroups')),
    'business/unit': lazy(() => import('../../Pages/System-admin/Business/BusinessUnit')),
    'project': lazy(() => import('../../Pages/System-admin/Business/Project')),
    'project/client': lazy(() => import('../../Pages/System-admin/Business/ProjectClient')),
    'site': lazy(() => import('../../Pages/System-admin/Business/Site')),
    'organization': lazy(() => import('../../Pages/System-admin/Business/Organization')),
};

const regionComponents = {
    'country': lazy(() => import('../../Pages/Region/Country')),
    'state': lazy(() => import('../../Pages/Region/State')),
    'city': lazy(() => import('../../Pages/Region/City')),
};

const competencyComponents = {
    'course': lazy(() => import('../../Pages/Competancy/Courses')),
    'question': lazy(() => import('../../Pages/Competancy/Question')),
    'assign': lazy(() => import('../../Pages/Competancy/AssignedCourses')),
    'enrolled': lazy(() => import('../../Pages/Competancy/Enrolled')),
    'dashboard': lazy(() => import('../../Pages/Competancy/Dashboard')),
    'competency/filter': lazy(() => import('../../Pages/Competancy/CompetencyFilter')),
    'key':lazy(() => import('../../Pages/Competancy/PurchaseKey')),
    'key/request':lazy(() => import('../../Pages/Competancy/RequestedPurchase')),
    'key/assign':lazy(() => import('../../Pages/Competancy/AssignKey')),
};

const dashboardComponents = {
    'dashboard/global': lazy(() => import('../../Pages/System-admin/Dashboard/Global')),
    'dashboard/country': lazy(() => import('../../Pages/System-admin/Dashboard/Country')),
    'dashboard/my': lazy(() => import('../../Pages/System-admin/UserDashboard')),
};

const permitComponents = {
    'permit': lazy(() => import('../../Pages/PermitToWork')),
    'user/review': lazy(() => import('../../Pages/PermitToWork/Receiver')),
    'user/issue': lazy(() => import('../../Pages/PermitToWork/Issuer')),
    'ptw/filter': lazy(() => import('../../Pages/PermitToWork/Report')),
};

const reportComponents = {
    'irc': lazy(() => import('../../Pages/Incident/Reports/IncidentReportCategory')),
    'incident/reports': lazy(() => import('../../Pages/Incident/Reports/IncidentFilter')),
    'inform/reports': lazy(() => import('../../Pages/Inform/HSSEReports/InformFilter')),
    'cfr': lazy(() => import('../../Pages/Incident/Reports/ContributingFactorReport')),
    'cir': lazy(() => import('../../Pages/Incident/Reports/ContractorInvolvmentReport')),
    'is': lazy(() => import('../../Pages/Incident/Reports/IssueSummary')),
    'io': lazy(() => import('../../Pages/Incident/Reports/IssueOverview')),
    'pdr': lazy(() => import('../../Pages/Incident/Reports/PlantDamageReport')),
    'csr': lazy(() => import('../../Pages/Incident/Reports/CostSummaryReport')),
    'ier': lazy(() => import('../../Pages/Incident/Reports/IncidentEnvironmentReport')),
    'iar': lazy(() => import('../../Pages/Incident/Reports/IssueActionReport')),
};

// Combine all component groups
export const componentMap = {
    ...incidentComponents,
    ...adminComponents,
    ...informComponents,
    ...businessComponents,
    ...regionComponents,
    ...competencyComponents,
    ...dashboardComponents,
    ...permitComponents,
    ...reportComponents,
};

const ModuleNotFoundCard = () => (
    <div className="d-flex justify-content-center align-items-center min-h-screen p-5">
        <Card className="text-center shadow-lg max-w-2xl w-full">
            <CardHeader className="bg-warning py-4">
                <CardTitle tag="h2" className="text-dark mb-0 d-flex items-center justify-center">
                    <FiAlertTriangle className="mr-3" size={36} />
                    <span>Module Not Found</span>
                </CardTitle>
            </CardHeader>
            <CardBody className="py-5">
                <Alert color="info" className="text-lg">
                    The requested module couldn't be located.
                </Alert>
            </CardBody>
        </Card>
    </div>
);

export const GlobalComponent = ({ submenuRoute, navigation }) => {


    const [currentRoute, setCurrentRoute] = useState(submenuRoute);

    // Update current route with startTransition when submenuRoute changes
    useEffect(() => {
        startTransition(() => {
            setCurrentRoute(submenuRoute);
        });
    }, [submenuRoute]);

    const moduleRouteToId = (route) => {
        const module = navigation.find(item => item.module_route === route);
        return module?.role_json || null;
    };

    const selectedModule = moduleRouteToId(currentRoute);
    const trimmedRoute = trim(currentRoute);
    const Component = componentMap[trimmedRoute];



    return (
        <Suspense fallback={<LoadingSpinner />}>
            {trimmedRoute && !Component ? (
                <ModuleNotFoundCard />
            ) : (
                Component && <Component access={selectedModule} />
            )}
        </Suspense>
    );
};

export default GlobalComponent;