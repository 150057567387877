import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
    completeCourse,
    updateCourseHistoryTopic,
} from "../../../store/actions/competancy/courses";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Progress,
    Row,
    Alert
} from "reactstrap";
import { getTopicsByEnrolled } from "../../../store/actions/competancy/topics";
import {
    CalendarOutlined,
    CheckCircleOutlined,
    CloseCircleFilled,
    DownloadOutlined,
    DownOutlined,
    ExclamationCircleOutlined,
    FileTextOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined,
    LockOutlined,
    PauseCircleFilled,
    PlayCircleFilled,
    PlayCircleOutlined,
    SoundFilled,
    SoundOutlined,
    UpOutlined,
} from "@ant-design/icons";
import { BookOpen } from 'lucide-react';
import { FaChevronLeft, FaChevronRight, FaCheck, FaMinus, FaPlus, FaCompress, FaExpand } from 'react-icons/fa';
import ReactPlayer from "react-player";
import { getGivenTests, getQuestionQuiz, submitQuiz } from "../../../store/actions/competancy/question";
import { buttonCardStyle, cardStyle } from "../../../constants/layout";
import UnRegisteredCertificate from "../Certificate/UnRegisteredCertificate";
import { PDFReader } from 'reactjs-pdf-reader';
import {getUREnrolledCourses} from "../../../store/actions/learning";


const spinnerStyle = {
    display: "inline-block",
    width: "80px",
    height: "80px",
};
const spinnerDivStyle = {
    boxSizing: "border-box",
    display: "block",
    width: "64px",
    height: "64px",
    margin: "8px",
    border: "8px solid #ccc",
    borderRadius: "50%",
    borderTopColor: "#1d3f72",
    animation: "spin 1s ease-in-out infinite",
};
const loaderContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    background: "#f9f9f9",
};

// Add keyframes for the spinner animation
const spinnerKeyframes = `
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`;



const PDFViewer = ({
                       pdfUrl,
                       currentTopicId,
                       updateCourseHistory,
                       moveToNextTopic,
                       chapterState,
                       topicChanged,
                       previousTopic,
                       currentTopic,
                       topics,
                       isFullscreen, // passed from parent
                       toggleFullscreen, // passed from parent,
                       toggle={toggle}
}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    const [pdfFile, setPdfFile] = useState(null);
    const [error, setError] = useState(null);

    // Reset PDF state when the URL or topic changes
    useEffect(() => {
        setPageNumber(1);
        setPdfFile(null);
    }, [pdfUrl, currentTopicId]);

    useEffect(() => {
        if (chapterState.currentChapter && chapterState.isNewChapter) {
            setPageNumber(1);
        }
    }, [chapterState]);

    useEffect(() => {
        if (topicChanged) {
            setPageNumber(1);
        }
    }, [topicChanged, previousTopic, currentTopicId]);

    // Fetch the PDF as a blob and create an object URL
    useEffect(() => {
        const fetchPdf = async () => {
            try {
                const response = await fetch(pdfUrl, {
                    headers: { Accept: "application/pdf" },
                });
                if (!response.ok)
                    throw new Error(`HTTP error! status: ${response.status}`);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                setPdfFile(url);
                setError(null);
            } catch (error) {
                setError("Failed to load PDF. Please try again later.");
            }
        };

        fetchPdf();
        return () => {
            if (pdfFile) URL.revokeObjectURL(pdfFile);
        };
    }, [pdfUrl]);

    const onDocumentLoad = (totalPages) => {
        setNumPages(totalPages);
        if (totalPages === 1) handleLastPage();
    };

    const goToPrevPage = () => setPageNumber((prev) => Math.max(prev - 1, 1));

    const goToNextPage = () => {
        setPageNumber((prev) => {
            const nextPage = Math.min(prev + 1, numPages);
            if (nextPage === numPages) handleLastPage();
            return nextPage;
        });
    };

    const handleLastPage = async () => {
        if (currentTopicId) {
            await updateCourseHistory(currentTopicId);
        }
    };

    const handleComplete = async () => {
        await updateCourseHistory(currentTopicId);

        const currentIndex = topics.findIndex((topic) => topic.id === currentTopicId);
        if (currentIndex < topics.length - 1) {
            moveToNextTopic();
        } else {
            toggle();
        }
    };

    const zoomIn = () => setScale((prev) => Math.min(prev + 0.1, 2));
    const zoomOut = () => setScale((prev) => Math.max(prev - 0.1, 0.5));

    // Styles based on fullscreen mode
    const containerStyle = isFullscreen
        ? {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
        }
        : { display: "flex", flexDirection: "column", height: "100%", width: "100%" };

    const pdfContainerStyle = isFullscreen
        ? { flex: 1, overflow: "auto" }
        : { height: "calc(100vh - 80px)", overflow: "auto" };

    const contentStyle = isFullscreen
        ? {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }
        : {
            transform: `scale(${scale})`,
            transformOrigin: "top center",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        };

    // If error, display error message
    if (error) return <div className="text-danger">{error}</div>;

    // Instead of a plain text loading, show a spinner with a nice message
    if (!pdfFile)
        return (
            <div style={loaderContainerStyle}>
                <style>{spinnerKeyframes}</style>
                <div style={spinnerStyle}>
                    <div style={spinnerDivStyle}></div>
                </div>
                <p style={{ marginTop: "10px", fontSize: "1.2rem", color: "#555" }}>
                    Loading PDF...
                </p>
            </div>
        );

    const getCurrentChapter = () => {
        if (!currentTopic || !topics) return "";
        const topic = topics.find((t) => t.id === currentTopic.id);
        return topic ? topic.chapter : "";
    };

    return (
        <div style={containerStyle}>
            <Container fluid className="p-0 m-0">
                {isFullscreen && currentTopic && (
                    <Row className="bg-light p-3 border-bottom m-0">
                        <Col className="p-0">
                            <h4 className="mb-0">Chapter: {getCurrentChapter()}</h4>
                            <medium className="text">Topic: {currentTopic.name}</medium>
                        </Col>
                    </Row>
                )}

                <Row className="m-0" style={{ flexGrow: 1 }}>
                    <Col className="p-0" style={pdfContainerStyle}>
                        <div style={contentStyle}>
                            <PDFReader
                                url={pdfFile}
                                page={pageNumber}
                                onDocumentComplete={onDocumentLoad}
                                scale={isFullscreen ? 1.5 : scale}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                    margin: 0,
                                    padding: 0,
                                }}
                            />
                        </div>
                    </Col>
                </Row>

                <Row
                    className="justify-content-center py-3 bg-light m-0"
                    style={{
                        position: isFullscreen ? "fixed" : "relative",
                        bottom: isFullscreen ? 0 : undefined,
                        left: 0,
                        right: 0,
                        zIndex: 9998,
                    }}
                >
                    <Col xs="auto" className="d-flex align-items-center justify-content-center gap-2 p-0">
                        <Button color="primary" onClick={goToPrevPage} disabled={pageNumber <= 1}>
                            <FaChevronLeft className="mr-1" />
                            Previous
                        </Button>

                        <Button color="primary" onClick={goToNextPage} disabled={pageNumber >= numPages}>
                            Next
                            <FaChevronRight className="ml-1" />
                        </Button>

                        <span>
              Page {pageNumber} of {numPages}
            </span>

                        <Button color="success" onClick={handleComplete} disabled={pageNumber !== numPages}>
                            <FaCheck className="mr-1" />
                            Complete
                        </Button>

                        {!isFullscreen && (
                            <>
                                <Button color="secondary" onClick={zoomOut}>
                                    <FaMinus />
                                </Button>
                                <Button color="secondary" onClick={zoomIn}>
                                    <FaPlus />
                                </Button>
                            </>
                        )}

                        <Button color="secondary" onClick={toggleFullscreen}>
                            {isFullscreen ? (
                                <>
                                    <FaCompress className="mr-1" />
                                    Exit Fullscreen
                                </>
                            ) : (
                                <>
                                    <FaExpand className="mr-1" />
                                    Fullscreen
                                </>
                            )}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};




const CourseContentModal = ({
                                title,
                                isOpen,
                                toggle,
                                courseData,
                                topics,
                                updateCourseHistory,
                            }) => {
    // States for chapters, topics, video, progress and fullscreen
    const [expandedChapters, setExpandedChapters] = useState({});
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [progress, setProgress] = useState(0);
    const [currentTopicId, setCurrentTopicId] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const playerRef = useRef(null);

    const [chapterState, setChapterState] = useState({
        currentChapter: null,
        isNewChapter: false,
        visitedChapters: new Set(),
    });

    const [previousTopic, setPreviousTopic] = useState(null);
    const [topicChanged, setTopicChanged] = useState(false);

    // When the selected topic changes, mark it as a new topic
    useEffect(() => {
        if (selectedTopic !== previousTopic) {
            setPreviousTopic(selectedTopic);
            setTopicChanged(true);
        } else {
            setTopicChanged(false);
        }
    }, [selectedTopic, previousTopic]);

    const toggleChapter = (chapterId) => {
        setExpandedChapters((prev) => ({
            ...prev,
            [chapterId]: !prev[chapterId],
        }));

        setChapterState((prevState) => {
            const isNewChapter = !prevState.visitedChapters.has(chapterId);
            return {
                currentChapter: chapterId,
                isNewChapter,
                visitedChapters: new Set([...prevState.visitedChapters, chapterId]),
            };
        });
    };

    const groupTopicsByChapter = () => {
        return topics.reduce((acc, topic) => {
            if (!acc[topic.chapterId]) {
                acc[topic.chapterId] = {
                    chapterName: topic.chapter,
                    chapterNo: topic.chapterNo,
                    topics: [],
                };
            }
            acc[topic.chapterId].topics.push(topic);
            return acc;
        }, {});
    };

    const chaptersWithTopics = groupTopicsByChapter();

    const isTopicEnabled = (topic) => {
        const topicIndex = topics.findIndex((t) => t.id === topic.id);
        if (topicIndex === 0) return true;
        const previousTopics = topics.slice(0, topicIndex);
        const lastWatchedIndex = previousTopics.findLastIndex((t) => t.watched);
        return (
            topicIndex === lastWatchedIndex + 1 ||
            previousTopics.every((t) => t.watched)
        );
    };

    const handleVideoSelect = (topic) => {
        if (isTopicEnabled(topic)) {
            setSelectedVideo(topic.fullpath);
            setSelectedTopic(topic);
            setCurrentTopicId(topic.id);
            setIsPlaying(true);
            setCurrentTime(0);
        }
    };

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    const handleMuteUnmute = () => {
        setIsMuted(!isMuted);
    };

    // Single fullscreen toggle for both PDF and video content
    const handleFullscreenToggle = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen?.();
            setIsFullscreen(true);
        } else {
            document.exitFullscreen?.();
            setIsFullscreen(false);
        }
        if (playerRef.current) {
            setCurrentTime(playerRef.current.getCurrentTime());
        }
    };

    const handleVideoProgress = (state) => {
        setCurrentTime(state.playedSeconds);
    };

    useEffect(() => {
        const watchedCount = topics.filter((topic) => topic.watched).length;
        const newProgress = (watchedCount / topics.length) * 100;
        setProgress(newProgress);
    }, [topics]);

    const handleVideoEnd = async () => {
        if (currentTopicId) {
            await updateCourseHistory(currentTopicId);

            // Check if this is the last topic before moving to the next
            const currentIndex = topics.findIndex((topic) => topic.id === currentTopicId);
            if (currentIndex < topics.length - 1) {
                moveToNextVideo();
            }
            else {
                toggle()
            }
            // If it's the last topic, we don't do anything that would close the modal
        }
    };

    const moveToNextVideo = () => {
        const currentIndex = topics.findIndex((topic) => topic.id === currentTopicId);
        if (currentIndex < topics.length - 1) {
            const nextTopic = topics[currentIndex + 1];
            setSelectedVideo(nextTopic.fullpath);
            setSelectedTopic(nextTopic);
            setCurrentTopicId(nextTopic.id);
            setCurrentTime(0);
        } else {
            // We're at the last topic, don't do anything that would close the modal
            // Just mark it as completed
            if (currentTopicId) {
                updateCourseHistory(currentTopicId);
            }
        }
    };

    const renderVideoPlayer = () => (
        <ReactPlayer
            ref={playerRef}
            url={selectedVideo}
            width="100%"
            height="100%"
            playing={isPlaying}
            volume={isMuted ? 0 : 0.5}
            controls={selectedTopic?.watched}
            onEnded={handleVideoEnd}
            onProgress={handleVideoProgress}
            progressInterval={1000}
            config={{
                file: {
                    attributes: {
                        controlsList: "nodownload",
                        playsInline: true,
                    },
                },
            }}
            key={`${selectedVideo}-${isFullscreen}`}
            onStart={() =>
                playerRef.current && playerRef.current.seekTo(currentTime)
            }
        />
    );

    const renderCustomControls = () => (
        <div className="custom-controls mb-3 d-flex justify-content-center">
            <Button
                className={`control-button ${isPlaying ? "pause" : "play"}`}
                onClick={handlePlayPause}
            >
                {isPlaying ? <PauseCircleFilled /> : <PlayCircleFilled />}
            </Button>
            <Button
                className={`control-button ms-3 ${isMuted ? "unmute" : "mute"}`}
                onClick={handleMuteUnmute}
            >
                {isMuted ? <SoundOutlined /> : <SoundFilled />}
            </Button>
            <Button onClick={handleFullscreenToggle} className="ms-3">
                {isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            </Button>
        </div>
    );

    // Render content based on the selected topic type
    const renderContent = () => {
        if (!selectedTopic) return null;
        if (selectedTopic.path.endsWith(".pdf")) {
            return (
                <PDFViewer
                    pdfUrl={selectedTopic.fullpath}
                    currentTopicId={currentTopicId}
                    updateCourseHistory={updateCourseHistory}
                    moveToNextTopic={moveToNextVideo}
                    chapterState={chapterState}
                    topicChanged={topicChanged}
                    previousTopic={previousTopic}
                    currentTopic={selectedTopic}
                    topics={topics}
                    isFullscreen={isFullscreen}
                    toggleFullscreen={handleFullscreenToggle}
                    toggle={toggle}
                />
            );
        } else {
            return renderVideoPlayer();
        }
    };

    // Reset video state when the modal is closed
    useEffect(() => {
        if (!isOpen) {
            setProgress(0);
            setIsPlaying(false);
        }
    }, [isOpen]);

    return (
        <>
            {/* Regular Mode Modal */}
            <Modal
                isOpen={isOpen && !isFullscreen}
                toggle={toggle}
                size="xl"
                className="course-content-modal"
                fullscreen={true}
            >
                <ModalHeader
                    toggle={toggle}
                    style={{
                        borderBottom: "none",
                        padding: "20px",
                        backgroundColor: "#f8f9fa",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <h4 style={{ marginBottom: "0", fontWeight: "bold" }}>
                        Course: {title || "Course Content"}
                    </h4>
                </ModalHeader>
                <ModalBody style={{ padding: "20px" }}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <Card style={{ marginBottom: "20px" }}>
                                <CardBody>
                                    <h6 style={{ marginBottom: "10px" }}>Chapter Progress</h6>
                                    <Progress
                                        value={progress}
                                        style={{
                                            height: "12px",
                                            marginBottom: "10px",
                                            striped: true,
                                        }}
                                    />
                                    <small style={{ color: "#888" }}>
                                        {progress.toFixed(0)}% Complete
                                    </small>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="row" style={{ height: "calc(100vh - 200px)" }}>
                        <div className="col-lg-8" style={{ height: "100%" }}>
                            <Card
                                className="w-100 h-100"
                                style={{ display: "flex", flexDirection: "column" }}
                            >
                                <CardBody className="flex-grow-1 p-0">
                                    <div
                                        className="relative w-100 h-100"
                                        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                                    >
                                        {selectedTopic && renderContent()}
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-lg-4">
                            <div
                                style={{
                                    maxHeight: "600px",
                                    overflowY: "auto",
                                    backgroundColor: "#f1f3f4",
                                }}
                            >
                                <ListGroup flush>
                                    {Object.entries(chaptersWithTopics).map(
                                        ([chapterId, chapter]) => (
                                            <React.Fragment key={chapterId}>
                                                <ListGroupItem
                                                    className="d-flex justify-content-between align-items-center chapter-header"
                                                    onClick={() => toggleChapter(chapterId)}
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: "bold",
                                                        backgroundColor: expandedChapters[chapterId]
                                                            ? "#e0e8ef"
                                                            : "#fff",
                                                        padding: "10px 15px",
                                                    }}
                                                >
                                                    <span>{`${chapter.chapterNo}. ${chapter.chapterName}`}</span>
                                                    {expandedChapters[chapterId] ? (
                                                        <UpOutlined />
                                                    ) : (
                                                        <DownOutlined />
                                                    )}
                                                </ListGroupItem>
                                                {expandedChapters[chapterId] &&
                                                    chapter.topics.map((topic) => (
                                                        <ListGroupItem
                                                            key={topic.id}
                                                            className={`pl-4 topic-item ${
                                                                isTopicEnabled(topic)
                                                                    ? "cursor-pointer"
                                                                    : "disabled"
                                                            }`}
                                                            onClick={() => handleVideoSelect(topic)}
                                                            style={{
                                                                cursor: isTopicEnabled(topic)
                                                                    ? "pointer"
                                                                    : "default",
                                                                backgroundColor: "#fff",
                                                                padding: "10px 15px",
                                                                borderBottom: "1px solid #eee",
                                                            }}
                                                        >
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div style={{ marginRight: "10px" }}>
                                                                    {topic.watched ? (
                                                                        <CheckCircleOutlined
                                                                            style={{ color: "green" }}
                                                                        />
                                                                    ) : isTopicEnabled(topic) ? (
                                                                        <PlayCircleOutlined
                                                                            style={{ color: "blue" }}
                                                                        />
                                                                    ) : (
                                                                        <LockOutlined style={{ color: "#ccc" }} />
                                                                    )}
                                                                </div>
                                                                <span>{topic.name}</span>
                                                            </div>
                                                        </ListGroupItem>
                                                    ))}
                                            </React.Fragment>
                                        )
                                    )}
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* Fullscreen Modal for PDF or Video */}
            <Modal
                isOpen={isFullscreen}
                toggle={handleFullscreenToggle}
                size="xl"
                fullscreen={true}
            >
                <ModalBody className="p-0" style={{ padding: 0 }}>
                    {selectedTopic && selectedTopic.path.endsWith(".pdf") ? (
                        // For PDF content, keep the existing styling
                        <div style={{ height: "50vh", position: "relative" }}>
                            {renderContent()}
                        </div>
                    ) : (
                        // For video content, let the video take the full viewport
                        <div style={{ height: "100vh", position: "relative" }}>
                            {renderContent()}
                            {selectedTopic &&
                                !selectedTopic.watched &&
                                !selectedTopic.path.endsWith(".pdf") && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: "20px",
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                        }}
                                    >
                                        {renderCustomControls()}
                                    </div>
                                )}
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

const ResultModal = ({isOpen, toggle, examData}) => {
    const [selectedExamIndex, setSelectedExamIndex] = useState(0);

    if (!examData || examData.length === 0) {
        return null;
    }

    const renderDateList = () => {
        console.log('ResultModal: Rendering date list');
        return (
            <Card className="mb-3">
                <CardBody>
                    <h5 className="mb-3">Exam Dates</h5>
                    {examData.map((exam, index) => {
                        return (
                            <Button
                                key={exam.id}
                                color={index === selectedExamIndex ? "primary" : "secondary"}
                                className="mb-2 d-flex align-items-center"
                                onClick={() => {
                                    setSelectedExamIndex(index);
                                }}
                            >
                                <CalendarOutlined className="mr-2"/>
                                {new Date(exam.createdAt).toLocaleDateString()}
                            </Button>
                        );
                    })}
                </CardBody>
            </Card>
        );
    };

    const renderQuestionNavigator = () => {
        const currentExam = examData[selectedExamIndex];
        return (
            <Card className="mb-3">
                <CardBody>
                    <h5 className="mb-3">Question Navigator</h5>
                    <div className="d-flex flex-wrap">
                        {currentExam.quiz.map((question, index) => {
                            return (
                                <Button
                                    key={index}
                                    color={
                                        question.answer === question.myAnswer ? "success" : "danger"
                                    }
                                    className="m-1"
                                >
                                    {index + 1}
                                </Button>
                            );
                        })}
                    </div>
                </CardBody>
            </Card>
        );
    };

    const renderExamSummary = () => {
        const currentExam = examData[selectedExamIndex];
        return (
            <Card>
                <CardBody>
                    <h5 className="mb-3">Exam Summary</h5>
                    <p>Total Questions: {currentExam.totalMarks}</p>
                    <p>Correct Answers: {currentExam.myMarks}</p>
                    <p>
                        Score:{" "}
                        {((currentExam.myMarks / currentExam.totalMarks) * 100).toFixed(2)}%
                    </p>
                </CardBody>
            </Card>
        );
    };

    const renderQuestions = () => {
        const currentExam = examData[selectedExamIndex];
        return currentExam.quiz.map((question, index) => {
            if (question.answer === question.myAnswer) {
                return null; // Skip correct attempts
            }
            return (
                <Card key={index} className="mb-3">
                    <CardBody>
                        <h5 className="mb-3 mr-3">Question {index + 1}<CloseCircleFilled className="ml-2 text-danger"/>
                        </h5>
                        <p>{question.question}</p>
                        {["A", "B", "C", "D"].map((option) => (
                            <div
                                key={option}
                                className={`mb-2 ${question.myAnswer === option ? "font-weight-bold" : ""}`}
                            >
                                {option}: {question[option]}

                            </div>
                        ))}
                    </CardBody>
                </Card>
            );
        }).filter(Boolean); // Remove null elements (correct attempts)
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" fullscreen={true}>
            <ModalHeader toggle={toggle}>Exam Results</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={3}>
                        {renderDateList()}
                        {/*{renderQuestionNavigator()}*/}
                        {renderExamSummary()}
                    </Col>

                    <Col md={9}>{renderQuestions()}</Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};
const ExamModal = ({
                       isOpen,
                       toggle,
                       questions,
                       courseId,
                       courseName,
                       dispatch,
                       setResult,
                       certId
                   }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);
    const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
    const [showResultModal, setShowResultModal] = useState(false);
    const [examResult, setExamResult] = useState(null);

    if (!questions || questions.length === 0) {
        return null;
    }

    const handleAnswerSelect = (questionIndex, answer) => {
        setAnswers({...answers, [questionIndex]: answer});
    };

    const renderQuestion = (question, index) => (
        <Card className="mb-4 p-3" style={cardStyle}>
            <h4 className="mb-4">{`Question ${index + 1}: ${question.question_text}`}</h4>
            {["A", "B", "C", "D"].map((option) => (
                <Button
                    key={option}
                    color={answers[index] === option ? "primary" : "secondary"}
                    className="mb-2 d-flex align-items-center"
                    style={{
                        width: "100%",
                        justifyContent: "flex-start",
                        textAlign: "left",
                    }}
                    onClick={() => handleAnswerSelect(index, option)}
                >
                    <span style={{minWidth: "30px"}}>{option}:</span>
                    <span>{question[`option_${option.toLowerCase()}`]}</span>
                </Button>
            ))}
        </Card>
    );
    const renderQuestionGrid = () => (
        <Card className="p-3">
            <div className="d-flex flex-wrap justify-content-center">
                {questions.map((_, index) => (
                    <Button
                        key={index}
                        color={answers[index] ? "success" : "danger"}
                        className="m-1"
                        onClick={() => setCurrentQuestionIndex(index)}
                    >
                        {index + 1}
                    </Button>
                ))}
            </div>
        </Card>
    );
    const handleSubmit = async () => {
        const totalQuestions = questions.length;
        const attemptedQuestions = Object.keys(answers).length;
        const correctAnswers = questions.reduce((count, question, index) => {
            return count + (answers[index] === question.correct_answer ? 1 : 0);
        }, 0);

        const payload = {
            type: "COURSE",
            quiz: JSON.stringify(
                questions.map((question, index) => ({
                    id: Date.now() + index,
                    question: question.question_text,
                    A: question.option_a,
                    B: question.option_b,
                    C: question.option_c,
                    D: question.option_d,
                    answer: question.correct_answer,
                    myAnswer: answers[index] || "",
                    mandatory: question.is_mandatory,
                })),
            ),
            totalMarks: totalQuestions,
            myMarks: correctAnswers,
            certId:certId
        };

        console.log(payload)

        const result = await dispatch(submitQuiz(payload));
        if (result.success) {
            setResult(result.data);
            setExamResult({
                totalQuestions,
                attemptedQuestions,
                correctAnswers,
            });
            setShowResultModal(true);
        }
    };
    const handleClose = () => {
        setIsCloseDialogOpen(false);
        toggle();
    };
    const closeResultModal = () => {
        setShowResultModal(false);
        setCurrentQuestionIndex(0);
        setAnswers({});
        setIsSubmitDialogOpen(false);
        setIsCloseDialogOpen(false);
        toggle();
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => setIsCloseDialogOpen(true)}
                size="xl"
                fullscreen={true}
            >
                <ModalHeader
                    toggle={() => setIsCloseDialogOpen(true)}
                    style={{
                        borderBottom: "none",
                        padding: "20px",
                        backgroundColor: "#f8f9fa", // Light gray background
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <h4 style={{marginBottom: "0", fontWeight: "bold"}}>
                        Assesment of: {courseName || "Exam"}
                    </h4>
                </ModalHeader>
                <div className="p-4">
                    <Row>
                        <Col md={8}>
                            {renderQuestion(
                                questions[currentQuestionIndex],
                                currentQuestionIndex,
                            )}
                            <div className="d-flex justify-content-between">
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        setCurrentQuestionIndex((prev) => Math.max(0, prev - 1))
                                    }
                                    disabled={currentQuestionIndex === 0}
                                >
                                    Previous
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        setCurrentQuestionIndex((prev) =>
                                            Math.min(questions.length - 1, prev + 1),
                                        )
                                    }
                                    disabled={currentQuestionIndex === questions.length - 1}
                                >
                                    Next
                                </Button>
                            </div>
                        </Col>
                        <Col md={4}>
                            <Card className="p-3 mb-4 mt-4" style={cardStyle}>
                                <h4>Exam Summary</h4>
                                <p>Total Questions: {questions.length}</p>
                                <p>Attempted: {Object.keys(answers).length}</p>
                                <p>
                                    Remaining: {questions.length - Object.keys(answers).length}
                                </p>
                            </Card>
                            <Card className="p-3 mb-4" style={cardStyle}>
                                <h4>Question Navigator</h4>
                                {renderQuestionGrid()}
                            </Card>
                            <Button
                                style={buttonCardStyle}
                                color="success"
                                block
                                onClick={() => setIsSubmitDialogOpen(true)}
                            >
                                Submit Exam
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>

            {/* Close Confirmation Dialog */}
            <Modal
                isOpen={isCloseDialogOpen}
                toggle={() => setIsCloseDialogOpen(false)}
            >
                <ModalHeader toggle={() => setIsCloseDialogOpen(false)}>
                    Confirm Close
                </ModalHeader>
                <ModalBody>Are you sure you want to close the exam?</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setIsCloseDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleClose}>
                        Close Exam
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Submit Confirmation Dialog */}
            <Modal
                isOpen={isSubmitDialogOpen}
                toggle={() => setIsSubmitDialogOpen(false)}
            >
                <ModalHeader toggle={() => setIsSubmitDialogOpen(false)}>
                    Confirm Submission
                </ModalHeader>
                <ModalBody>Are you sure you want to submit this exam?</ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={() => setIsSubmitDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSubmit}>
                        Submit Exam
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={showResultModal} toggle={closeResultModal}>
                <ModalHeader toggle={closeResultModal}>Exam Results</ModalHeader>
                <ModalBody>
                    <Card body style={cardStyle}>
                        <h4 className="mb-3">Exam Summary</h4>
                        <div className="d-flex justify-content-between">
                            <p>Total Questions:</p>
                            <p style={{color: "#3498db"}}>{examResult?.totalQuestions}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p>Attempted Questions:</p>
                            <p style={{color: "#2ecc71"}}>
                                {examResult?.attemptedQuestions}
                            </p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p>Correct Answers:</p>
                            <p style={{color: "#f1c40f"}}>{examResult?.correctAnswers}</p>
                        </div>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={closeResultModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};


const Learning = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { courseKey } = useParams();

    // Redux state for unregistered user
    const course = useSelector((state) => state.course.urRegistered);
    const questions = useSelector((state) => state.question.questions);
    const topics = useSelector((state) => state.topic.list);

    // Local state
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [examModalOpen, setExamModalOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [result, setResult] = useState(null);
    const [resultModalOpen, setResultModalOpen] = useState(false);
    const [examData, setExamData] = useState([]);
    const [certificateModalOpen, setCertificateModalOpen] = useState(false);

    // Fetch course data on component mount
    useEffect(() => {
        if (!courseKey) {
            setError("No course key provided");
            setLoading(false);
            return;
        }

        const fetchCourseData = async () => {
            try {
                setLoading(true);
                await dispatch(getUREnrolledCourses(courseKey));
                setLoading(false);
            } catch (err) {
                console.error("Error fetching course:", err);
                setError("Failed to load course data");
                setLoading(false);
            }
        };

        fetchCourseData();
    }, [dispatch, courseKey]);

    // Watch for questions update to open exam modal
    useEffect(() => {
        if (questions && questions.length > 0 && course) {
            setExamModalOpen(true);
        }
    }, [questions, course]);

    // Refresh data after exam completion
    useEffect(() => {
        async function fetchUpdatedCourse() {
            if (result !== null) {
                await dispatch(getUREnrolledCourses(courseKey));
                setExamModalOpen(false);
                setConfirmDialogOpen(false);
            }
        }

        fetchUpdatedCourse();
    }, [result, dispatch, courseKey]);

    const handlePlayCourse = async () => {
        if (!course) return;

        await dispatch(getTopicsByEnrolled(course.certId));
        setTitle(course.name || "");
        setModalOpen(true);
    };

    const handleStartExam = async () => {
        if (!course) return;

        setConfirmDialogOpen(true);
    };

    const handleContactAdmin = () => {
        console.log("Contact admin for course:", course.id);
    };

    const toggleModal = () => setModalOpen(!modalOpen);

    const confirmStartExam = async () => {
        setConfirmDialogOpen(false);
        const payload = {
            id: course.id,
            type: "COURSE",
        };
        await dispatch(getQuestionQuiz(payload));
    };

    const viewResult = async () => {
        if (!course) return;

        const payload = {
            id: course.certId,
        };
        const result = await dispatch(getGivenTests(payload));
        if (result.success && Array.isArray(result.data)) {
            setExamData(result.data);
            setResultModalOpen(true);
        } else {
            console.error("Invalid exam data received:", result.data);
        }
    };

    const downloadCertificate = async () => {
        if (!course) return;

        setCertificateModalOpen(true);
    };

    const handleCertificateDownload = async () => {
        const certificateElement = document.getElementById('certificate-container');

        try {
            // Store original styles
            const originalStyle = certificateElement.style.cssText;

            // Apply new styles for centering and removing padding/margin
            certificateElement.style.cssText = `
                padding: 0;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            `;

            // If there's a direct child element that contains all the content, center that as well
            const contentElement = certificateElement.firstElementChild;
            if (contentElement) {
                contentElement.style.cssText = `
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                `;
            }

            // Adjust the container size to match A3 landscape aspect ratio
            const aspectRatio = 420 / 297; // A3 landscape aspect ratio
            const width = certificateElement.offsetWidth;
            const height = width / aspectRatio;
            certificateElement.style.height = `${height}px`;

            // Render the certificate at a higher resolution
            const canvas = await html2canvas(certificateElement, {
                scale: 2, // Increase scale for better quality on larger A3 size
                useCORS: true,
                logging: false,
                backgroundColor: '#ffffff'
            });

            // Restore original styles
            certificateElement.style.cssText = originalStyle;
            if (contentElement) {
                contentElement.style.cssText = '';
            }

            // Compress the image
            const compressedImage = await new Promise((resolve) => {
                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg', 0.95); // Use JPEG format with 95% quality for A3 size
            });

            // Convert blob to base64
            const base64data = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(compressedImage);
            });

            // Create PDF
            const pdf = new jsPDF({
                orientation: 'landscape',
                unit: 'mm',
                format: 'a3'
            });

            const imgWidth = 420; // A3 width in mm (landscape)
            const imgHeight = 297; // A3 height in mm (landscape)

            pdf.addImage(base64data, 'JPEG', 0, 0, imgWidth, imgHeight, '', 'FAST');

            // Save the PDF
            pdf.save(`${course.name}_certificate.pdf`);

        } catch (error) {
            console.error('Error generating PDF:', error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    const updateCourseHistory = async (topicId) => {
        if (!topics || !course) return;

        const topic = topics.find(t => t.id === topicId);
        if (!topic) {
            console.error("Topic not found");
            return;
        }

        const courseId = topic.courseId;
        const isFirstTopic = topics.findIndex(t => t.id === topicId) === 0;
        const isLastTopic = topics.findIndex(t => t.id === topicId) === topics.length - 1;

        let object = {
            topicId: parseInt(topicId),
            certId: course.certId,
        };

        console.log(object);

        if (isLastTopic && isFirstTopic) {
            let result = await dispatch(updateCourseHistoryTopic(object));
            if (result && result.success) {
                await dispatch(completeCourse({certId: course.certId, topicId: parseInt(topicId)}));
                setModalOpen(false);
                await dispatch(getUREnrolledCourses(courseKey));
            }
        }

        if (isLastTopic) {
            await dispatch(completeCourse({courseId, topicId: parseInt(topicId)}));
            await dispatch(getUREnrolledCourses(courseKey));
            //setModalOpen(false);
        }

        await dispatch(updateCourseHistoryTopic(object));
    };

    // Loading state
    if (loading) {
        return (
            <Container className="page-content py-5">
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "50vh" }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </Container>
        );
    }

    // Error state
    if (error) {
        return (
            <Container className="page-content py-5">
                <Alert color="danger" className="my-4">
                    {error}
                </Alert>
                <Button color="primary" onClick={() => navigate("/")}>
                    Return to Dashboard
                </Button>
            </Container>
        );
    }

    // No course found
    if (!course) {
        return (
            <Container className="page-content py-5">
                <Col xs="12" className="d-flex justify-content-center align-items-center" style={{ minHeight: "50vh" }}>
                    <style>
                        {`
                          @keyframes pulse {
                            0%, 100% { opacity: 1; }
                            50% { opacity: 0.5; }
                          }
                          @keyframes ping {
                            0% { transform: scale(0.2); opacity: 0.8; }
                            80%, 100% { transform: scale(2); opacity: 0; }
                          }
                        `}
                    </style>
                    <Card className="text-center p-4 shadow" style={{ maxWidth: '400px' }}>
                        <CardBody>
                            <CardTitle tag="h2" className="mb-3">Course Not Found</CardTitle>
                            <div className="position-relative mb-4"
                                 style={{ width: '128px', height: '128px', margin: '0 auto' }}>
                                <BookOpen size={128} className="text-primary"
                                          style={{ animation: 'pulse 2s infinite' }}/>
                                <div
                                    className="position-absolute"
                                    style={{
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: 'rgba(0, 123, 255, 0.1)',
                                        borderRadius: '50%',
                                        animation: 'ping 3s infinite'
                                    }}
                                />
                            </div>
                            <CardText className="mb-4">The course you're looking for could not be found.</CardText>
                            <CardText className="text-muted mb-4">
                                The course link may be expired or invalid.
                            </CardText>
                            <Button color="primary" className="rounded-pill px-4" onClick={() => navigate("/")}>
                                Return Home
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
            </Container>
        );
    }

    // Course Progress Bar Component (reused from EnrolledCourseCard)
    const CourseProgressBar = ({ totalTopics, completedTopics }) => {
        const progressPercentage = Math.round((completedTopics / totalTopics) * 100);

        return (
            <Card className="w-100 mt-4">
                <CardBody className="pt-3">
                    <div className="d-flex justify-content-between mb-2">
                        <span className="text-sm font-semibold text-gray-700">Course Progress</span>
                    </div>
                    <Progress value={progressPercentage} className="w-100 h-2"/>
                    <div className="d-flex justify-content-between mt-2">
                        <span className="text-xs font-semibold text-primary">{progressPercentage}% Complete</span>
                    </div>
                </CardBody>
            </Card>
        );
    };

   // const attemptsLeft = (course.totalLimit - course.attempts) || 0 ;
    const attemptsLeft = course.totalLimit - course.attempts;


    console.log(course)


    console.log({
        attemptsLeft,
        hasAssessment: course?.hasAssessment,
        completed: course?.completed
    });



    // Main content when course is loaded
    return (
        <Container className="page-content py-5">
            <h1 className="h4 mb-4" style={{color: "#013e7d"}}>
                {courseKey}
            </h1>

            <Row>
                <Col sm="12" className="mb-4">
                    <Card className="shadow-sm">
                        <CardBody>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div>
                                    <h2 className="h5 mb-0">{course.name}</h2>
                                    {course.duration && (
                                        <div className="text-muted small">
                                            Duration: {course.duration}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {course.hasAssessment && !course.passed && (attemptsLeft > 0) && (
                                        <Col xs={12}>
                                            <Badge
                                                color="primary"
                                                className="d-flex align-items-center justify-content-center p-2 w-100"
                                                style={{fontSize: "1rem"}}
                                            >
                                                <CheckCircleOutlined className="me-2"/>
                                                <span>Assessment Available</span>
                                            </Badge>
                                        </Col>
                                    )}
                                    {
                                        !course.passed && <div className="mt-2 text-center">
                                            {attemptsLeft > 0
                                                ? `${attemptsLeft} attempt${attemptsLeft === 1 ? "" : "s"} left`
                                                : "No attempts left"
                                            }
                                        </div>
                                    }
                                </div>
                            </div>

                            {course.description && (
                                <div className="mb-4">
                                    <div dangerouslySetInnerHTML={{__html: course.description}}/>
                                </div>
                            )}

                            <CourseProgressBar
                                totalTopics={course.totalTopics || 0}
                                completedTopics={course.completedTopics || 0}
                            />

                            <div className="d-flex flex-wrap gap-2 mt-4">
                                <Button color="primary" onClick={handlePlayCourse}>
                                    {course.status === 'inprogress' ? 'Resume Learning' : 'Start Learning'}
                                </Button>

                                {(!course.passed && course.completed && course.hasAssessment && attemptsLeft > 0) && (
                                    <Button color="info" onClick={handleStartExam}>
                                        Take Exam
                                    </Button>
                                )}

                                {course.passed && (
                                    <Button color="secondary" onClick={viewResult}>
                                        View Results
                                    </Button>
                                )}

                                {course.passed && (
                                    <Button color="success" onClick={downloadCertificate}>
                                        Download Certificate
                                    </Button>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* Modals */}
            <CourseContentModal
                title={title}
                isOpen={modalOpen}
                toggle={toggleModal}
                courseData={course}
                topics={topics}
                updateCourseHistory={updateCourseHistory}
            />

            <ExamModal
                isOpen={examModalOpen}
                toggle={() => setExamModalOpen(false)}
                questions={questions ?? []}
                courseId={course?.id}
                courseName={course?.name}
                dispatch={dispatch}
                setResult={setResult}
                certId={course?.certId}
            />

            <ResultModal
                isOpen={resultModalOpen}
                toggle={() => setResultModalOpen(false)}
                examData={examData ?? []}
            />

            <Modal
                isOpen={confirmDialogOpen}
                toggle={() => setConfirmDialogOpen(false)}
                size="xl"
                fullscreen={true}
            >
                <ModalHeader toggle={() => setConfirmDialogOpen(false)}>
                    Start Exam
                </ModalHeader>
                <ModalBody>
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                course?.examDescription ||
                                "Do you agree to start the exam?",
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={confirmStartExam}>
                        I Accept
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={certificateModalOpen}
                toggle={() => setCertificateModalOpen(false)}
                size="xl"
                fullscreen
            >
                <ModalHeader toggle={() => setCertificateModalOpen(false)}>
                    Course Certificate
                </ModalHeader>
                <ModalBody>
                    <div id="certificate-container">
                        <UnRegisteredCertificate course={course}/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleCertificateDownload}>
                        Download Certificate
                    </Button>
                    <Button color="secondary" onClick={() => setCertificateModalOpen(false)}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};

export default Learning;


