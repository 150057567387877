import {
    GENERATE_IAUDIT,
    IAUDIT_VALUES,
    GET_USER_IAUDIT,
    GET_SINGLE_IAUDIT,
    ADD_IAUDIT,
    UPDATE_IAUDIT,
    DELETE_IAUDIT
} from "../../../types";

const initialState = {list: [], single: null, userIAUDIT: {}, values: [], loading: true};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GENERATE_IAUDIT:
            return {
                ...state,
                userIAUDIT: payload
            };
        case IAUDIT_VALUES:
            return {...state, values: payload};
        case GET_USER_IAUDIT:
            return {...state, userIAUDIT: payload, loading: false};
        case GET_SINGLE_IAUDIT:
            return {...state, single: payload};
        case ADD_IAUDIT:
            return {...state, userIAUDIT: payload};
        case UPDATE_IAUDIT:
            return {
                ...state,
                userIAUDIT: payload
            };
        case DELETE_IAUDIT:
            return {
                ...state,
                userIAUDIT: state.userIAUDIT.user.filter(object => object.id !== payload)
            };
        default:
            return state;
    }
};

export default reducer;