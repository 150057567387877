import {
    GET_COMPLAINT_VALUES,
    GET_SINGLE_USER_COMPLAINT,
    GET_USER_COMPLAINT,
    ADD_COMPLAINT,
    UPDATE_COMPLAINT,
    GENERATE_COMPLAINT,
    UPDATE_COMPLAINT_ACTION,
    DELETE_COMPLATIN_ACTION,
    SET_COMPLAINTS_LOADING,
    CLEAR_COMPLAINTS_LOADING
} from "../../types";

const initialState = { 
    list: [], 
    single: null, 
    userComplaint: {},
    loading: false 
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_COMPLAINTS_LOADING:
            return { ...state, loading: true };
        case CLEAR_COMPLAINTS_LOADING:
            return { ...state, loading: false };
        case GET_COMPLAINT_VALUES:
            return { ...state, list: payload, loading: false };
        case GET_USER_COMPLAINT:
            return { ...state, userComplaint: payload, loading: false };
        case GET_SINGLE_USER_COMPLAINT:
            return { ...state, single: payload, loading: false };
        case ADD_COMPLAINT:
            return { ...state, userComplaint: payload, loading: false };
        case UPDATE_COMPLAINT:
            return {
                ...state,
                userComplaint: payload,
                loading: false
            };
        case GENERATE_COMPLAINT:
            return {
                ...state,
                userComplaint: payload,
                loading: false
            };
        case UPDATE_COMPLAINT_ACTION:
            return {
                ...state,
                userComplaint: state.userComplaint.map(complaint =>
                    complaint.id === payload.complaintId
                        ? {
                            ...complaint,
                            actions: complaint.actions.map(action =>
                                action.id === payload.updatedAction.id
                                    ? payload.updatedAction
                                    : action
                            )
                        }
                        : complaint
                ),
                loading: false
            };
        case DELETE_COMPLATIN_ACTION:
                return {
                    ...state,
                    userComplaint: state.userComplaint.user.filter(object => object.id !== payload),
                    loading: false
                };
        default:
            return state;
    }
};

export default reducer;