import { ADD_USER_DASHBOARD, GET_USER_DASHBOARDS, UPDATE_USER_DASHBOARD, GET_DASHBOARD_VALUES,GET_DASHBOARD_COUNTRY } from "../../types";


const initialState = { list: [], single: null, values: [],country:[] };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_DASHBOARD_VALUES:
            return { ...state, values: payload };
        case GET_DASHBOARD_COUNTRY:
            return { ...state, country: payload };
        case GET_USER_DASHBOARDS:
            return { ...state, list: payload };

        case ADD_USER_DASHBOARD:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case UPDATE_USER_DASHBOARD:
            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };
        default:
            return state;
    }
};

export default reducer;
