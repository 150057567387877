import {ADD_L2PM, GENERATE_L2PM, GET_SINGLE_L2PM, GET_USER_L2PM, L2PM_VALUES, UPDATE_L2PM,DELETE_L2PM} from "../../../types";

const initialState = { list: [], single: null, userL2PM: {},values:[],loading:true};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENERATE_L2PM:
            return {
                ...state,
                userL2PM: payload
            };
        case L2PM_VALUES:
            return { ...state, values: payload };
        case GET_USER_L2PM:
            return { ...state, userL2PM: payload,loading:false};
        case GET_SINGLE_L2PM:
            return { ...state, single: payload };
        case ADD_L2PM:
            return { ...state, userL2PM: payload };
        case UPDATE_L2PM:
            return {
                ...state,
                userL2PM: payload
            };
        case DELETE_L2PM:
                return {
                    ...state,
                    userL2PM: state.userL2PM.user.filter(object => object.id !== payload)
                };
        default:
            return state;
    }
};

export default reducer;