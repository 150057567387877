import {ADD_CITY, DELETE_CITY, GET_CITIES, GET_CITY, UPDATE_CITY,GET_CITY_COUNTRIES,GET_CITY_STATES} from "../../types";

const initialState = { list: [],countries:[],states:[],single: null };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_CITIES:
            return { ...state, list: payload };

        case GET_CITY_COUNTRIES:
            return { ...state, countries: payload };

        case GET_CITY_STATES:
            return { ...state, states: payload };

        case GET_CITY:
            return { ...state, single: payload || initialState.single };


        case DELETE_CITY:
            return {
                ...state,
                list: state.list.filter((item) => item.id !== payload.id),
            };

        default:
            return state;
    }
};

export default reducer;