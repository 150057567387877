import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardFooter, Row, Col, Container } from 'reactstrap';
import { AlertTriangle } from 'lucide-react';
import { API_URL } from "../../config";
import logodark from "../../assets/images/logo-dark.png";

const VerifiedCertificate = ({ courseData, userData }) => {
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <Container>
            <Card className="border-warning" style={{ maxWidth: '800px', margin: 'auto' }}>
                <CardHeader className="bg-white border-bottom-0">
                    <Row className="align-items-center">
                        <Col>
                            <img src={logodark} alt="Company Logo" style={{ width: 'auto', height: '30px' }} />
                        </Col>
                        <Col className="text-end">
                            <small className="fw-bold">{formatDate(new Date())}</small>
                            <br />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="text-center">
                    <h1 className="display-6 mb-4" style={{ color: '#013e7d' }}>Certificate of Recognition</h1>
                    <p className="mb-2">This certificate is awarded to</p>
                    <h2 className="display-5 mb-3" style={{ color: '#013e7d' }}>{userData.name}</h2>
                    <p className="mb-2">{userData.organization}</p>
                    <p className="mb-4">for successfully completing the course:</p>
                    <h3 className="h4 mb-4" style={{ color: '#013e7d' }}>{courseData.name}</h3>
                    <p className="mb-5" dangerouslySetInnerHTML={{ __html: courseData.description }} />

                </CardBody>
                <CardFooter className="bg-white text-center">
                    <small className="text-muted">
                        <AlertTriangle size={12} className="me-1" />
                        This is a digital representation. The actual certificate may vary.
                    </small>
                </CardFooter>
            </Card>
        </Container>
    );
};

const VerifyCertificate = () => {
    const [decodedData, setDecodedData] = useState(null);
    const [verificationResult, setVerificationResult] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const encodedData = searchParams.get('data');

        if (encodedData) {
            try {
                const decodedString = decodeURIComponent(encodedData);
                const parsedData = JSON.parse(decodedString);
                setDecodedData(parsedData);
            } catch (error) {
                console.error('Error decoding data:', error);
                setDecodedData({ error: 'Failed to decode data' });
            }
        }
    }, [location]);

    useEffect(() => {
        const verifyUserCourseData = async () => {
            if (decodedData && decodedData.certId) {
                try {
                    const response = await fetch(`${API_URL}/course/check/${decodedData.certId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer essential`
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const result = await response.json();
                    setVerificationResult(result);
                } catch (error) {
                    console.error('Error verifying user course:', error);
                    setVerificationResult({ error: 'Failed to verify course' });
                }
            }
        };

        verifyUserCourseData();
    }, [decodedData]);

    if (verificationResult?.error) {
        return <div>Error: {verificationResult.error}</div>;
    }

    if (!verificationResult) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {verificationResult.status === "Success" && verificationResult.data ? (
                <VerifiedCertificate
                    courseData={verificationResult.data.course}
                    userData={verificationResult.data.user}
                />
            ) : (
                <div>
                    <h2>Verification Failed</h2>
                    <p>{verificationResult.message}</p>
                </div>
            )}
        </div>
    );
};

export default VerifyCertificate;