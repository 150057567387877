import {
    ADD_COURSE_KEY,
    GET_COURSE_KEY_VALUES,
    UPDATE_COURSE_KEY,
    GET_PURCHASE_KEYS, GET_REQUESTED_PURCHASE_COURSE
} from "../../types";

const initialState = {purchaseKeys: {},values:[],requestedPurchases:[]};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_COURSE_KEY_VALUES:
            return {...state, values: payload};
        case GET_PURCHASE_KEYS:
            return {...state, purchaseKeys: payload};
        case GET_REQUESTED_PURCHASE_COURSE:
            return {...state, requestedPurchases: payload};
        case ADD_COURSE_KEY:
            return {
                ...state,
                purchaseKeys: [payload, ...state.list],
            };
        case UPDATE_COURSE_KEY:
            return {
                ...state,
                purchaseKeys: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };
        default:
            return state;
    }
};

export default reducer;
