import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import {DeleteOutlined, EditOutlined, PlusCircleFilled} from "@ant-design/icons";

import {addUserLinks, deleteUserLinks, getUserLinks, updateUserLinks} from "../../store/actions/user";
import ReactStrapDataTable from "../CustomDatatable/ReactStrapDataTable";
import {stringSorter} from "../../utils/functions";

const QuickLinks = () => {
    const dispatch = useDispatch();
    const quickLinks = useSelector((state) => state.users.userLinks);

    const [modalOpen, setModalOpen] = useState(false);
    const [editingLink, setEditingLink] = useState(null);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        dispatch(getUserLinks());
    }, [dispatch]);

    const handleEdit = (link) => {
        
        const object={
            title:link.name,
            link:link.link,
            id:parseInt(link.id)
        }

        setEditingLink(object);
        reset(object);
        setModalOpen(true);
    };

    const handleDelete = async (link) => {
        if (window.confirm("Are you sure you want to delete this quick link?")) {
            await dispatch(deleteUserLinks(link.id));
            dispatch(getUserLinks());
        }
    };

    const onFormSubmit = (data) => {
        let object={
            name:data.title,
            link:data.link,
        }

        if (editingLink) {
            object.id=editingLink.id
            dispatch(updateUserLinks(object));
        } else {
            dispatch(addUserLinks(object));
        }
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setEditingLink(null);
        reset();
    };

    const handleAddNew = () => {
        setEditingLink(null);
        reset({ title: '', link: '' });
        setModalOpen(true);
    };

    const columns = [
        {
            title: "Title",
            dataIndex: "name",
            key: "name",
            sorter: stringSorter("title"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Link",
            dataIndex: "link",
            key: "link",
            sorter: stringSorter("link"),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
                <>
                    <Button color="link" onClick={() => handleEdit(record)}>
                        <EditOutlined />
                    </Button>
                    <Button color="link" onClick={() => handleDelete(record)}>
                        <DeleteOutlined />
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div>
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">Manage External Links</h5>
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" onClick={handleAddNew}>
                                <PlusCircleFilled /> Add New
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ReactStrapDataTable
                        dataSource={quickLinks}
                        columns={columns}
                        rowKey="id"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={modalOpen} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>
                    {editingLink ? 'Edit Bulletin' : 'Add Bulletin'}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="title">Title</Label>
                            <Controller
                                name="title"
                                control={control}
                                rules={{ required: "Title is required" }}
                                render={({ field }) => <Input {...field} id="title" />}
                            />
                            {errors.title && <span className="text-danger">{errors.title.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="link">Link</Label>
                            <Controller
                                name="link"
                                control={control}
                                rules={{ required: "Link is required" }}
                                render={({ field }) => <Input {...field} id="link" />}
                            />
                            {errors.link && <span className="text-danger">{errors.link.message}</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {editingLink ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default QuickLinks;