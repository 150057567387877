import {
    ADD_COURSE,
    GET_COURSES,
    GET_COURSE,
    UPDATE_COURSE,
    GET_ASSIGNED_COURSES,
    GET_ENROLLED_COURSES,
    COURSES_ENROLLED_BY_USER,
    MASTER_COURSE_USERS, GET_COURSES_VALUES, GET_COURSE_MASTER, GET_COURSE_ORGANIZATIONS, COURSES_ENROLLED_BY_URUSER
} from "../../types";

const initialState = {list: [],organizations:[],coursesMaster:{},courseValues:{},single: null,assignedCourses: {},enrolledCourses:[],courseEnrolledByUsers:[],urRegistered:{},masterCourseUser:[]};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case MASTER_COURSE_USERS:
            return {...state, masterCourseUser: payload};
        case GET_COURSE_MASTER:
            return {...state, coursesMaster: payload};
        case COURSES_ENROLLED_BY_USER:
            return {...state, courseEnrolledByUsers: payload};
        case COURSES_ENROLLED_BY_URUSER:
            return {...state, urRegistered: payload};
        case GET_COURSES_VALUES:
            return {...state, courseValues: payload};
        case GET_COURSES:
            return {...state, list: payload};
        case GET_COURSE_ORGANIZATIONS:
            return {...state, organizations: payload};
        case GET_ENROLLED_COURSES:
            return {...state, enrolledCourses: payload};
        case GET_ASSIGNED_COURSES:
            return {...state, assignedCourses: payload};
        case GET_COURSE:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case ADD_COURSE:
            return {
                ...state,
                list: [payload, ...state.list],
            };

        case UPDATE_COURSE:

            return {
                ...state,
                list: state.list.map((item) =>
                    item.id === payload.id ? payload : item
                ),
            };
        default:
            return state;
    }
};

export default reducer;
