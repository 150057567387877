import {
    GET_IMPROVEMENT_OPPORTUNITY_VALUES,
    GET_SINGLE_USER_IMPROVEMENT_OPPORTUNITY,
    GET_USER_IMPROVEMENT_OPPORTUNITY,
    ADD_IMPROVEMENT_OPPORTUNITY,
    UPDATE_IMPROVEMENT_OPPORTUNITY,
    GENERATE_IMPROVEMENT_OPPORTUNITY,
    UPDATE_IMPROVEMENT_OPPORTUNITY_ACTION,
    DELETE_IMPROVEMENT_OPPORTUNITY
} from "../../types";

const initialState = {
    list: [],
    single: null,
    userImprovementOpportunity: {
        user: [],
        shared: [],
        assigned: [],
        sharedAccess: false,
        loading: false,
        error: null
    },
    loading: false,
    error: null
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_IMPROVEMENT_OPPORTUNITY_VALUES:
            if (payload && typeof payload === 'object' && 'loading' in payload) {
                return {
                    ...state,
                    loading: payload.loading,
                    error: payload.error || null
                };
            }
            return {
                ...state,
                list: payload,
                loading: false,
                error: null
            };

        case GET_USER_IMPROVEMENT_OPPORTUNITY:
            if (payload && typeof payload === 'object' && 'loading' in payload) {
                return {
                    ...state,
                    userImprovementOpportunity: {
                        ...state.userImprovementOpportunity,
                        loading: payload.loading,
                        error: payload.error || null
                    }
                };
            }
            return {
                ...state,
                userImprovementOpportunity: {
                    ...payload,
                    loading: false,
                    error: null
                }
            };

        case GET_SINGLE_USER_IMPROVEMENT_OPPORTUNITY:
            if (payload && typeof payload === 'object' && 'loading' in payload) {
                return {
                    ...state,
                    loading: payload.loading,
                    error: payload.error || null
                };
            }
            return {
                ...state,
                single: payload,
                loading: false,
                error: null
            };

        case ADD_IMPROVEMENT_OPPORTUNITY:
            if (payload && typeof payload === 'object' && 'loading' in payload) {
                return {
                    ...state,
                    loading: payload.loading,
                    error: payload.error || null
                };
            }
            return {
                ...state,
                userImprovementOpportunity: {
                    ...state.userImprovementOpportunity,
                    user: [payload, ...(state.userImprovementOpportunity.user || [])],
                    loading: false,
                    error: null
                }
            };

        case UPDATE_IMPROVEMENT_OPPORTUNITY:
            if (payload && typeof payload === 'object' && 'loading' in payload) {
                return {
                    ...state,
                    loading: payload.loading,
                    error: payload.error || null
                };
            }
            return {
                ...state,
                userImprovementOpportunity: {
                    ...state.userImprovementOpportunity,
                    user: state.userImprovementOpportunity.user.map(
                        opportunity => opportunity.id === payload.id ? payload : opportunity
                    ),
                    loading: false,
                    error: null
                },
                single: payload,
                loading: false
            };

        case GENERATE_IMPROVEMENT_OPPORTUNITY:
            if (payload && typeof payload === 'object' && 'loading' in payload) {
                return {
                    ...state,
                    loading: payload.loading,
                    error: payload.error || null
                };
            }
            return {
                ...state,
                userImprovementOpportunity: {
                    ...state.userImprovementOpportunity,
                    user: state.userImprovementOpportunity.user.map(
                        opportunity => opportunity.id === payload.id ? payload : opportunity
                    ),
                    loading: false,
                    error: null
                }
            };

        case DELETE_IMPROVEMENT_OPPORTUNITY:
            if (payload && typeof payload === 'object' && 'loading' in payload) {
                return {
                    ...state,
                    loading: payload.loading,
                    error: payload.error || null
                };
            }
            return {
                ...state,
                userImprovementOpportunity: {
                    ...state.userImprovementOpportunity,
                    user: state.userImprovementOpportunity.user.filter(
                        opportunity => opportunity.id !== payload
                    ),
                    loading: false,
                    error: null
                }
            };

        case UPDATE_IMPROVEMENT_OPPORTUNITY_ACTION:
            if (payload && typeof payload === 'object' && 'loading' in payload) {
                return {
                    ...state,
                    loading: payload.loading,
                    error: payload.error || null
                };
            }
            return {
                ...state,
                userImprovementOpportunity: {
                    ...state.userImprovementOpportunity,
                    user: state.userImprovementOpportunity.user.map(opportunity =>
                        opportunity.id === payload.improvementOpportunityId
                            ? {
                                ...opportunity,
                                actions: [...(opportunity.actions || []), payload.action]
                            }
                            : opportunity
                    ),
                    loading: false,
                    error: null
                },
                single: state.single && state.single.id === payload.improvementOpportunityId
                    ? {
                        ...state.single,
                        actions: [...(state.single.actions || []), payload.action]
                    }
                    : state.single
            };

        default:
            return state;
    }
};


export default reducer;