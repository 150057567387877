import {
    GO_SEE_PROGRAM_VALUES,
    ADD_GO_SEE_PROGRAM,
    UPDATE_GO_SEE_PROGRAM,
    GET_USER_SEE_PROGRAM,
    GET_SINGLE_GO_SEE_PROGRAM,GENERATE_GO_SEE_PROGRAM,DELETE_GO_SEE_PROGRAM
} from "../../../types";

const initialState = {
    list: [],
    single: null,
    userGSP: {},
    values: [],
    loading: true
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENERATE_GO_SEE_PROGRAM:
            return {
                ...state,
                userGSP: payload
            };
        case GO_SEE_PROGRAM_VALUES:
            return { ...state, values: payload };
        case GET_USER_SEE_PROGRAM:
            return { ...state, userGSP: payload, loading: false };
        case GET_SINGLE_GO_SEE_PROGRAM:
            return { ...state, single: payload };
        case ADD_GO_SEE_PROGRAM:
            return { ...state, userGSP: payload };
        case UPDATE_GO_SEE_PROGRAM:
            return {
                ...state,
                userGSP: payload
            };
        case DELETE_GO_SEE_PROGRAM:
                return {
                    ...state,
                    userGSP: state.userGSP.user.filter(object => object.id !== payload)
                };
       /* case GENERATE_HAZARD:
            return {
                ...state,
                userHazard: state.userHazard.map(hazard =>
                    hazard.id === payload.id ? payload : hazard
                )
            };
        case UPDATE_HAZARD_ACTION:
            return {
                ...state,
                userHazard: state.userHazard.map(hazard =>
                    hazard.id === payload.hazardId
                        ? {
                            ...hazard,
                            actions: hazard.actions.map(action =>
                                action.id === payload.updatedAction.id
                                    ? payload.updatedAction
                                    : action
                            )
                        }
                        : hazard
                )
            };*/
        default:
            return state;
    }
};

export default reducer;