import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {getNavigation} from "../../store/actions/navigation";
import GlobalComponent from "../../components/GlobalComponents";
import MetisMenu from "metismenujs";
import {Link} from "react-router-dom";

const Incident = () => {
    const dispatch = useDispatch();
    const navigation = useSelector((state) => state.navigation.list);
    const [selectedModule, setSelectedModule] = useState(null);
    const [selectedSubmenu, setSelectedSubmenu] = useState(null);
    const [openSubmenus, setOpenSubmenus] = useState({});

    const menuRef = useRef(null);

    useEffect(() => {
        dispatch(getNavigation());
    }, [dispatch]);

    useEffect(() => {
        const initMenu = () => {
            if (menuRef.current) {
                new MetisMenu("#side-menu");
            }
        };
        initMenu();
    }, [navigation]);

    const groupedNavigation = useMemo(() => {
        return navigation.reduce((acc, item) => {
            if (item.menu_name === "Incident" || item.menu_name === "Safety Breach") {
                if (!acc[item.submenu_name]) {
                    acc[item.submenu_name] = [];
                }
                acc[item.submenu_name].push(item.module_name);
            }
            return acc;
        }, {});
    }, [navigation]);

    const toggleSubmenu = (submenuName) => {
        setOpenSubmenus(prev => ({
            ...prev,
            [submenuName]: !prev[submenuName]
        }));
    };

    const handleModuleSelect = (submenuName, moduleName) => {
        setSelectedSubmenu(submenuName);
        setSelectedModule(moduleName);
    };

    useEffect(() => {
        if (Object.keys(groupedNavigation).length > 0) {
            const firstSubmenu = Object.keys(groupedNavigation)[0];
            const firstModule = groupedNavigation[firstSubmenu][0];
            setSelectedSubmenu(firstSubmenu);
            setSelectedModule(firstModule);
            setOpenSubmenus({ [firstSubmenu]: true });
        }
    }, [groupedNavigation]);

    const sidebarStyle = {
        position: 'fixed',
        top: '70px', // Adjust this value to match your header height
        left: 0,
        bottom: 0,
        width: '250px', // Adjust as needed
        overflowY: 'auto',
        zIndex: 1000,
        backgroundColor: '#ffffff',
        borderRight: '1px solid #e5e5e5',
    };

    const mainContentStyle = {
        marginLeft: '250px', // Should match the sidebar width
        padding: '20px',
    };

    return (
        <div>
            <div className="vertical-menu" style={sidebarStyle}>
                <div data-simplebar className="h-100">
                    <div id="sidebar-menu" ref={menuRef}>
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title">Incident</li>
                            {Object.entries(groupedNavigation).map(([submenuName, modules]) => (
                                <li key={submenuName} className={openSubmenus[submenuName] ? "mm-active" : ""}>
                                    <Link to="#" className="has-arrow waves-effect" onClick={() => toggleSubmenu(submenuName)}>
                                        <i className="mdi mdi-file-document-outline"></i>
                                        <span>{submenuName}</span>
                                    </Link>
                                    <ul className={`sub-menu ${openSubmenus[submenuName] ? "mm-show" : ""}`} aria-expanded={openSubmenus[submenuName]}>
                                        {modules.map((moduleName) => (
                                            <li key={moduleName} className={selectedModule === moduleName ? "mm-active" : ""}>
                                                <Link
                                                    to="#"
                                                    onClick={() => handleModuleSelect(submenuName, moduleName)}
                                                    className={selectedModule === moduleName ? "active" : ""}
                                                >
                                                    {moduleName}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="main-content">
                <div>
                    <Breadcrumb className="mb-3">
                        <BreadcrumbItem>M-SPHERE</BreadcrumbItem>
                        <BreadcrumbItem>Incident</BreadcrumbItem>
                        {selectedSubmenu && <BreadcrumbItem>{selectedSubmenu}</BreadcrumbItem>}
                        {selectedModule && <BreadcrumbItem active>{selectedModule}</BreadcrumbItem>}
                    </Breadcrumb>
                    {selectedModule && <GlobalComponent moduleName={selectedModule} />}
                </div>
            </div>
        </div>
    );
}

export default Incident;