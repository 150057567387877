import {ADD_BUSINESS_GROUP, GET_BUSINESS_GROUPS, GET_BUSINESS_GROUP, UPDATE_BUSINESS_GROUP} from "../../types";


const initialState = {list: {},countries:[],businessGroups:[], single: null};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_BUSINESS_GROUPS:
            return {...state, list: payload};

        case GET_BUSINESS_GROUP:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        case ADD_BUSINESS_GROUP:
            return {...state, list: payload};

        case UPDATE_BUSINESS_GROUP:
            return {...state, list: payload};

        default:
            return state;
    }
};

export default reducer;
