import {
    GENERATE_LOPC,
    LOPC_VALUES,
    GET_USER_LOPC,
    GET_SINGLE_LOPC,
} from "../../../types";

const initialState = { list: [], single: null, userLOPC: [],values:[],loading: true};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GENERATE_LOPC:
            return {
                ...state,
                userLOPC: payload
            };
        case LOPC_VALUES:
            return { ...state, values: payload };
        case GET_USER_LOPC:
            return { ...state, userLOPC: payload,loading: false};
        case GET_SINGLE_LOPC:
            return { ...state, single: payload };
        default:
            return state;
    }
};

export default reducer;