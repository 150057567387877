module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID:
      "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },

  CLIENT_URL:"https://hsse.meinhardtepcm.com",
  API_URL: "https://hsse.meinhardtepcm.com/hsseapi/api/",

  // API_URL: "http://172.16.5.64:8001/api",
  // CLIENT_URL:"http://172.16.5.64:3000"

  // API_URL: "http://172.16.5.89:8001/api",
  // CLIENT_URL:"http://172.16.5.54:3000"


  //API_URL: "http://172.16.5.62:8001/api",
  //API_URL: "http://172.16.5.56:8001/api",
  //API_URL: "http://172.20.10.14:8001/api",
  //API_URL:"http://192.168.1.12:8001/api"x`
};


