import {
    GET_PARENT_CABINET,
    GET_CHILD_CABINET_BY_PARENT,
    UPDATE_CABINET_HIERARCHY,
    GET_CABINET_VALUES,
    GET_FOLDER_HEIRARCHY,
    GET_FOLDER_CONTENT
} from "../../types";

const initialState = {
    parent: [],
    child: [],
    values: [],
    folder: [],
    content: []
};

const fileCabinetReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PARENT_CABINET:
            return {
                ...state,
                parent: Array.isArray(payload) ? payload : []
            };

        case GET_FOLDER_HEIRARCHY:
            return {
                ...state,
                folder: Array.isArray(payload) ? payload : []
            };

        case GET_FOLDER_CONTENT:
            return {
                ...state,
                content: Array.isArray(payload) ? payload : []
            };

        case GET_CABINET_VALUES:
            return {
                ...state,
                values: payload
            };

        case GET_CHILD_CABINET_BY_PARENT:
            return {
                ...state,
                child: payload
            };

        case UPDATE_CABINET_HIERARCHY:
            return {
                ...state,
                parent: [...payload]
            };

        default:
            return state;
    }
};

export default fileCabinetReducer;